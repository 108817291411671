import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Select,
  MenuItem,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import {
  useGenerateModernWorkoutProgramMutation,
  ModernProgramBuilderGenerateProgramRequest,
} from "shared/api";
import { Routes } from "shared/routers";
import { useFormik } from "formik";
import { OrganisationClientSelector } from "pages";

interface GenerateProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preselectedUserId?: number;
}

export type CreateAiWorkoutProgramFormValues = {
  userId: number | null;
  daysPerWeek: number;
  weeksInProgram: number;
};

const GenerateProgramModal: FC<GenerateProgramModalProps> = ({
  isOpenModal,
  handleCloseModal,
  preselectedUserId = null,
}) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [savingMessage, setSavingMessage] = useState("");

  const [generateProgram, { isLoading }] =
    useGenerateModernWorkoutProgramMutation();

  const { values, handleBlur, setFieldValue, errors, handleSubmit } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        userId: preselectedUserId,
        daysPerWeek: 4,
        weeksInProgram: 4,
      },
      onSubmit: async (params) => {
        if (!params.userId) {
          setErrorMessage("Please select a client");
          return;
        }

        if (!params.daysPerWeek) {
          setErrorMessage("Please select days per week");
          return;
        }

        if (!params.weeksInProgram) {
          setErrorMessage("Please select weeks in program");
          return;
        }

        const programToSave: ModernProgramBuilderGenerateProgramRequest = {
          userId: params.userId,
          daysPerWeek: params.daysPerWeek,
          weeksInProgram: params.weeksInProgram,
          organisationId: null,
        };

        setErrorMessage("");
        setSuccessMessage("");

        setSavingMessage(
          "Generating program. This may take a minute. Please do not close this window."
        );

        try {
          const response = await generateProgram(programToSave).unwrap();
          setSavingMessage("");

          setSuccessMessage(
            "Program generated successfully. You will be redirected to the program builder in a few seconds."
          );

          setTimeout(() => {
            navigate(
              `${Routes.modernWorkoutProgramBuilder.url}/${response.id}`
            );
          }, 3000);
        } catch (error) {
          setSavingMessage("");
          setErrorMessage("Failed to generate program");
        }
      },
    }
  );

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Generate program
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
          >
            This will generate a workout program for the client based on their
            current training type and their historical training data.
            <br />
            <br />
            You will be able to review the program and make any necessary
            changes prior to sending it to the client.
          </Typography>

          {!preselectedUserId && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 3,
                width: "100%",
              }}
            >
              <OrganisationClientSelector
                setSelectedUserId={(userId) => {
                  setFieldValue("userId", userId);
                }}
                selectedUserId={values.userId}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  mb: 1,
                }}
              >
                Days per week
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={values.daysPerWeek}
                onChange={(event) => {
                  setFieldValue("daysPerWeek", event.target.value);
                }}
                onBlur={handleBlur}
              >
                {Array.from({ length: 7 }, (_, i) => i + 1).map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  mb: 1,
                }}
              >
                Weeks in program
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={values.weeksInProgram}
                onChange={(event) => {
                  setFieldValue("weeksInProgram", event.target.value);
                }}
                onBlur={handleBlur}
              >
                {Array.from({ length: 8 }, (_, i) => i + 1).map((week) => (
                  <MenuItem key={week} value={week}>
                    {week}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              mt: 2,
            }}
            loading={isLoading}
            disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            Generate program
          </LoadingButton>
          {savingMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="info"
            >
              {savingMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { GenerateProgramModal };
