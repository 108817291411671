import { Box, useMediaQuery, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { FC, useEffect, useState } from "react";
import { Colors } from "shared/themes";
import {
  useGetUserNotesByOrganisationQuery,
  useGetUserNotesCountByOrganisationQuery,
  UserNote,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";
import { useTypedSelector } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { CreateUserNoteModal } from "components/Modals/CreateUserNoteModal";

const OrganisationUserNotesTable: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const { data: userNotesArrayLength } =
    useGetUserNotesCountByOrganisationQuery({});
  const [rowCountState, setRowCountState] = useState(userNotesArrayLength || 0);
  const [rows, setRows] = useState<UserNote[]>([]);

  const { data, isLoading } = useGetUserNotesByOrganisationQuery({
    count: pageSize,
    page,
    orderByDescending: true,
  });

  useEffect(() => {
    if (!data) return;
    setRows(data);
    setRowCountState((prevRowCountState) =>
      userNotesArrayLength !== undefined
        ? userNotesArrayLength
        : prevRowCountState
    );
  }, [data]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      userNotesArrayLength !== undefined
        ? userNotesArrayLength
        : prevRowCountState
    );
  }, [userNotesArrayLength, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: "dateTime",
      headerName: "Date",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {new Date(params.value as string).toLocaleDateString("en-Au", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Typography>
        );
      },
    },
    {
      field: "userFullName",
      headerName: "Client",
      flex: 1,
      sortable: false,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1.25,
      sortable: false,
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Link to={`${Routes.userNotes.url}/${id}/1`}>
            <Button
              color="primary"
              variant="text"
              sx={{ textTransform: "none" }}
            >
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          Treatment Notes
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <PrimaryButton
            value="New treatment note"
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleToggleModal}
            icon="add_icon"
          />
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "800px",
          bgcolor: `${Colors.gray[100]}`,
          borderRadius: "8px",
          padding: 2,
          mb: "16px",
        }}
      >
        {!isLoading ? (
          <Box sx={{ height: "700px", width: "100%" }}>
            <DataGrid
              headerHeight={56}
              rowHeight={52}
              pageSize={pageSize}
              onPageChange={(newPage) => setPage(newPage + 1)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              columns={columns}
              rows={rows}
              rowCount={rowCountState}
              paginationMode="server"
              sx={{
                "& .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
                "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                  bgcolor: `${Colors.gray[1700]}`,
                },
                "& .MuiDataGrid-menuIconButton": {
                  display: "none",
                },
              }}
            />

            <CreateUserNoteModal
              handleCloseModal={handleToggleModal}
              isOpenModal={isOpenModal}
            />
          </Box>
        ) : (
          <ProgramsSkeleton />
        )}
      </Box>
    </Box>
  );
};

export { OrganisationUserNotesTable };
