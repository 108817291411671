import { FC } from "react";
import { Box } from "@mui/system";
import {
  Modal,
  Typography,
  Icon,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface CompleteAndShareModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  onSubmit: (values: CompleteAndShareModalValues) => void;
  invoiceRequested: boolean;
  setInvoiceRequested: (value: boolean) => void;
  invoiceDueDate: string;
  handleInvoiceRequestedDateChange: (date: string | null) => void;
}

export type CompleteAndShareModalValues = {
  invoiceRequested: boolean;
  invoiceDueDate: string;
};

const CompleteAndShareModal: FC<CompleteAndShareModalProps> = ({
  isOpenModal,
  handleCloseModal,
  onSubmit,
  invoiceRequested,
  setInvoiceRequested,
  invoiceDueDate,
  handleInvoiceRequestedDateChange,
}) => {
  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Complete and share
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 2,
            }}
          >
            Would you like to send an invoice for this program?
          </Typography>
          <FormGroup
            row
            sx={{
              mt: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiceRequested}
                  onChange={() => setInvoiceRequested(!invoiceRequested)}
                />
              }
              label="Include Invoice"
            />
          </FormGroup>
          {invoiceRequested && (
            <Box
              sx={{
                mb: "16px",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  height: "5%",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  mb: "10px",
                  color: `${Colors.gray[2400]}`,
                }}
              >
                Invoice Due Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={invoiceDueDate}
                  onChange={(date) => handleInvoiceRequestedDateChange(date)}
                  renderInput={(params: any) => (
                    <TextField
                      sx={{
                        width: "70%",
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "10px",
              gap: "10px",
            }}
          >
            <LoadingButton
              onClick={() => onSubmit({ invoiceRequested, invoiceDueDate })}
              disabled={invoiceRequested && !invoiceDueDate}
              variant="outlined"
              color="primary"
              sx={{
                width: "45%",
                height: "42px",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Inter",
                textTransform: "none",
              }}
            >
              Complete
            </LoadingButton>
            <LoadingButton
              onClick={handleCloseModal}
              variant="contained"
              color="primary"
              sx={{
                width: "45%",
                height: "42px",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Inter",
                textTransform: "none",
              }}
            >
              Cancel
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { CompleteAndShareModal };
