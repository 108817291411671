import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  BookingGroup,
  Booking,
  BookingAttendee,
  CreateBookingRequest,
  BookingsRequestParams,
} from "./models";

export const bookingGroupsApi = createApi({
  baseQuery,
  reducerPath: "bookingGroupsApi",
  tagTypes: ["BookingGroup", "Booking", "BookingAttendee"],
  endpoints: (builder) => ({
    getBookingGroups: builder.query<BookingGroup[], void>({
      query: () => ({
        url: `/booking-groups`,
        method: "GET",
      }),
      providesTags: ["BookingGroup"],
    }),
    getBookingGroup: builder.query<BookingGroup, string>({
      query: (bookingGroupId) => ({
        url: `/booking-groups/${bookingGroupId}`,
        method: "GET",
      }),
      providesTags: ["BookingGroup"],
    }),
    getBookings: builder.query<Booking[], BookingsRequestParams>({
      query: (params) => ({
        url: `/booking-groups/bookings`,
        method: "GET",
        params,
      }),
      providesTags: ["Booking"],
    }),
    getBookingsCount: builder.query<number, void>({
      query: () => ({
        url: `/booking-groups/bookings`,
        method: "GET",
        params: {
          countOnly: true,
        },
      }),
    }),
    getBooking: builder.query<Booking, string>({
      query: (bookingId) => ({
        url: `/booking-groups/bookings/${bookingId}`,
        method: "GET",
      }),
      providesTags: ["Booking"],
    }),
    getBookingAttendees: builder.query<BookingAttendee[], string>({
      query: (bookingId) => ({
        url: `/booking-groups/bookings/${bookingId}/attendees`,
        method: "GET",
      }),
      providesTags: ["BookingAttendee"],
    }),
    createBookingGroup: builder.mutation<BookingGroup, Partial<BookingGroup>>({
      query: (body) => ({
        url: `/booking-groups`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["BookingGroup"],
    }),
    updateBookingGroup: builder.mutation<BookingGroup, Partial<BookingGroup>>({
      query: (body) => ({
        url: `/booking-groups/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["BookingGroup"],
    }),
    createBooking: builder.mutation<Booking[], CreateBookingRequest>({
      query: (body) => ({
        url: `/booking-groups/bookings`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Booking"],
    }),
    updateBooking: builder.mutation<Booking, Partial<Booking>>({
      query: (body) => ({
        url: `/booking-groups/bookings/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Booking"],
    }),
    permanentlyDeleteBooking: builder.mutation<
      void,
      { id: number; applyChangesToFutureBookings?: boolean }
    >({
      query: ({ id, applyChangesToFutureBookings }) => ({
        url: `/booking-groups/bookings/${id}/permanently`,
        method: "DELETE",
        params: { applyChangesToFutureBookings },
      }),
      invalidatesTags: ["Booking"],
    }),
    joinBookingAsAttendee: builder.mutation<
      BookingAttendee,
      { bookingId: number }
    >({
      query: ({ bookingId }) => ({
        url: `/booking-groups/bookings/${bookingId}/attendees/join-athlete`,
        method: "POST",
      }),
      invalidatesTags: ["BookingAttendee", "Booking"],
    }),
    removeAthleteFromBooking: builder.mutation<void, { bookingId: number }>({
      query: ({ bookingId }) => ({
        url: `/booking-groups/bookings/${bookingId}/attendees/remove-athlete`,
        method: "DELETE",
      }),
      invalidatesTags: ["BookingAttendee", "Booking"],
    }),
    deleteBookingGroup: builder.mutation<void, number>({
      query: (bookingGroupId) => ({
        url: `/booking-groups/${bookingGroupId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BookingGroup"],
    }),
  }),
});

export const {
  useGetBookingGroupsQuery,
  useGetBookingGroupQuery,
  useGetBookingsQuery,
  useGetBookingsCountQuery,
  useGetBookingQuery,
  useGetBookingAttendeesQuery,
  useCreateBookingGroupMutation,
  useUpdateBookingGroupMutation,
  useCreateBookingMutation,
  useUpdateBookingMutation,
  usePermanentlyDeleteBookingMutation,
  useJoinBookingAsAttendeeMutation,
  useRemoveAthleteFromBookingMutation,
  useDeleteBookingGroupMutation,
} = bookingGroupsApi;
