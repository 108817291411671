import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ActivityCard } from "./ActivityCard";

import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { TrendDataItem } from "../AdminDashboard/utils/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface StepsDetailCardProps {
  insights: {
    trendData: TrendDataItem[];
    bestDay: TrendDataItem;
    worstDay: TrendDataItem;
    todayStats: TrendDataItem;
    goalAchievementDays: number;
    totalDays: number;
    averageDailySteps: number;
  };
}

export const StepsDetailCard: React.FC<StepsDetailCardProps> = ({
  insights,
}) => {
  const todayStepsPerformance = determinePerformanceLevel(
    insights.todayStats?.steps ?? 0,
    insights.todayStats?.goal ?? 0
  );
  const bestDayPerformance = determinePerformanceLevel(
    insights.bestDay?.steps ?? 0,
    insights.bestDay?.goal ?? 0
  );
  const worstDayPerformance = determinePerformanceLevel(
    insights.worstDay?.steps ?? 0,
    insights.worstDay?.goal ?? 0
  );
  const averageDailyStepsPerformance = determinePerformanceLevel(
    insights.averageDailySteps ?? 0,
    insights.trendData?.[0]?.goal ?? 0
  );
  const stepGoalAchievementPerformance = determinePerformanceLevel(
    insights.goalAchievementDays ?? 0,
    insights.trendData?.length ?? 0
  );
  const caloriesBurnedPerformance = determinePerformanceLevel(
    insights.todayStats?.activeCalories ?? 0,
    insights.todayStats?.activeCaloriesGoal ?? 0
  );

  return (
    <Box sx={{ borderRadius: 2 }}>
      <Box sx={{ padding: "16px 24px" }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary", mt: 1 }}>
          Here&apos;s your activity summary over the last{" "}
          {insights.trendData.length} days,
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ padding: "0 24px" }}>
        <Grid item xs={6}>
          <ActivityCard
            title="Today"
            value={insights.todayStats?.steps}
            target={insights.todayStats?.goal}
            unit="steps"
            gradient={getGradientForScore(todayStepsPerformance)}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Calories burned today"
            value={insights?.todayStats?.activeCalories}
            target={insights?.todayStats?.activeCaloriesGoal}
            unit="kcal"
            gradient={getGradientForScore(caloriesBurnedPerformance)}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Best day"
            value={insights.bestDay?.steps}
            target={insights.bestDay?.goal}
            unit="steps"
            gradient={getGradientForScore(bestDayPerformance)}
          />
        </Grid>

        <Grid item xs={6}>
          <ActivityCard
            title="Worst day"
            value={insights.worstDay?.steps}
            target={insights.worstDay?.goal}
            unit="steps"
            gradient={getGradientForScore(worstDayPerformance)}
          />
        </Grid>

        <Grid item xs={6}>
          <ActivityCard
            title="Daily Average"
            value={Math.round(insights.averageDailySteps ?? 0)}
            target={insights.trendData?.[0]?.goal ?? 0}
            unit="steps"
            gradient={getGradientForScore(averageDailyStepsPerformance)}
          />
        </Grid>

        <Grid item xs={6}>
          <ActivityCard
            title="Achieved Step Goal"
            value={insights?.goalAchievementDays ?? 0}
            target={insights?.trendData?.length ?? 0}
            unit="days"
            gradient={getGradientForScore(stepGoalAchievementPerformance)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
