import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  Booking,
  useUpdateBookingMutation,
  ClientResponse,
  usePermanentlyDeleteBookingMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

interface UpdateBookingModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  booking: Booking | null;
  setBooking: React.Dispatch<React.SetStateAction<Booking | null>>;
  athleteUsers: ClientResponse[] | undefined;
  nonAthleteUsers: ClientResponse[] | undefined;
}

const UpdateBookingModal: FC<UpdateBookingModalProps> = ({
  isOpenModal,
  handleCloseModal,
  booking,
  setBooking,
  athleteUsers,
  nonAthleteUsers,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();
  const [permanentlyDeleteBooking, { isLoading: isDeleting }] =
    usePermanentlyDeleteBookingMutation();
  const [selectedCoaches, setSelectedCoaches] = useState<ClientResponse[]>([]);
  const [selectedAthletes, setSelectedAthletes] = useState<ClientResponse[]>(
    []
  );
  const [updateConfirmationOpen, setUpdateConfirmationOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [applyToFutureBookings, setApplyToFutureBookings] = useState(false);

  useEffect(() => {
    if (booking) {
      const coachUsersThatMatchCoachAttendees = nonAthleteUsers?.filter(
        (user) =>
          booking.coachAttendees?.some((attendee) => attendee.id === user.id)
      );

      if (coachUsersThatMatchCoachAttendees) {
        setSelectedCoaches(coachUsersThatMatchCoachAttendees);
      }

      const athleteUsersThatMatchAthleteAttendees = athleteUsers?.filter(
        (user) =>
          booking.athleteAttendees?.some((attendee) => attendee.id === user.id)
      );

      if (athleteUsersThatMatchAthleteAttendees) {
        setSelectedAthletes(athleteUsersThatMatchAthleteAttendees);
      }
    }
  }, [booking, athleteUsers, nonAthleteUsers]);

  // Reset applyToFutureBookings when modal opens
  useEffect(() => {
    if (isOpenModal) {
      setApplyToFutureBookings(false);
    }
  }, [isOpenModal]);

  const formik = useFormik({
    initialValues: {
      name: booking?.name ?? "",
      description: booking?.description ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      setUpdateConfirmationOpen(true);
    },
  });

  const handleConfirmedSubmit = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const updatedBooking = {
        ...booking,
        ...formik.values,
        bookingAthleteAttendeeUserIds: selectedAthletes.map(
          (athlete) => athlete.id
        ),
        bookingCoachAttendeeUserIds: selectedCoaches.map((coach) => coach.id),
        applyChangesToFutureBookings: applyToFutureBookings,
      };
      const savedBooking = await updateBooking(updatedBooking).unwrap();
      setSuccessMessage("Booking updated successfully");
      setBooking(savedBooking as Booking);
      setApplyToFutureBookings(false); // Reset after successful update
      handleCloseModal();
    } catch (error) {
      console.error("Error updating booking:", error);
      setErrorMessage("An error occurred while updating the booking");
    } finally {
      setUpdateConfirmationOpen(false);
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 2500);
    }
  };

  const handlePermanentlyDeleteBooking = async () => {
    try {
      await permanentlyDeleteBooking({
        id: booking?.id as number,
        applyChangesToFutureBookings: applyToFutureBookings,
      }).unwrap();
      setSuccessMessage("Booking deleted successfully");
      setBooking(null);
      setSelectedAthletes([]);
      setSelectedCoaches([]);
      setApplyToFutureBookings(false); // Reset after successful delete
    } catch (error) {
      setErrorMessage("An error occurred while deleting the booking");
    } finally {
      setDeleteConfirmationOpen(false);
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 2500);
    }
  };

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box sx={sharedStyles.containers.modal}>
          <Box sx={sharedStyles.containers.modalContent}>
            <Box
              sx={{
                width: "100%",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {booking?.name}
              </Typography>
              <Icon
                onClick={handleCloseModal}
                sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
              >
                close_icon
              </Icon>
            </Box>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate>
              <InputField
                fullWidth
                margin="normal"
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <InputField
                fullWidth
                margin="normal"
                label="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                multiline
                rows={2}
              />

              <Autocomplete
                multiple
                options={nonAthleteUsers || []}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={selectedCoaches}
                onChange={(event, newValue) => {
                  setSelectedCoaches(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select coaches"
                    placeholder="Coaches"
                  />
                )}
                sx={{ mt: 2 }}
              />

              <Autocomplete
                multiple
                options={athleteUsers || []}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={selectedAthletes}
                onChange={(event, newValue) => {
                  if (
                    !booking?.privateBooking &&
                    booking?.maxAttendees &&
                    newValue.length > booking.maxAttendees
                  ) {
                    setErrorMessage(
                      `You can select up to ${booking.maxAttendees} athletes.`
                    );
                  } else {
                    setSelectedAthletes(newValue);
                    setErrorMessage("");
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select athletes"
                    placeholder="Athletes"
                  />
                )}
                sx={{ mt: 2 }}
              />

              <PrimaryButton
                size="large"
                value={`Update ${booking?.name ?? ""}`}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                fullWidth
                type="submit"
              />

              <Button
                sx={{ mt: 2, textTransform: "none" }}
                color="error"
                variant="outlined"
                fullWidth
                onClick={() => setDeleteConfirmationOpen(true)}
              >
                Delete {booking?.name}
              </Button>

              {errorMessage && (
                <Alert
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                  variant="outlined"
                  severity="error"
                >
                  {errorMessage}
                </Alert>
              )}

              {successMessage && (
                <Alert
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                  variant="outlined"
                  severity="success"
                >
                  {successMessage}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Delete {booking?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to permanently delete this booking? This
            action cannot be undone.
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToFutureBookings}
                  onChange={(e) => setApplyToFutureBookings(e.target.checked)}
                />
              }
              label="Apply to all future bookings in this series?"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="button"
            onClick={() => setDeleteConfirmationOpen(false)}
            color="primary"
            sx={{ textTransform: "none" }}
            loading={isDeleting}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="button"
            onClick={handlePermanentlyDeleteBooking}
            color="error"
            variant="contained"
            sx={{ textTransform: "none" }}
            loading={isDeleting}
          >
            Delete {booking?.name}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Add Update Confirmation Dialog */}
      <Dialog
        open={updateConfirmationOpen}
        onClose={() => setUpdateConfirmationOpen(false)}
      >
        <DialogTitle>Update {booking?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to apply these changes to future bookings in this
            series?
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToFutureBookings}
                  onChange={(e) => setApplyToFutureBookings(e.target.checked)}
                />
              }
              label="Apply to all future bookings in this series?"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="button"
            onClick={() => setUpdateConfirmationOpen(false)}
            color="primary"
            sx={{ textTransform: "none" }}
            loading={isLoading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="button"
            onClick={handleConfirmedSubmit}
            color="primary"
            variant="contained"
            sx={{ textTransform: "none" }}
            loading={isLoading}
          >
            Update {booking?.name}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { UpdateBookingModal };
