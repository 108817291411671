import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Button,
  Snackbar,
  Icon,
} from "@mui/material";
import { Colors } from "shared/themes";

interface RestTimerProps {
  open: boolean;
  onClose: () => void;
  existingRestTime?: string;
}

const RestTimer: React.FC<RestTimerProps> = ({
  open,
  onClose,
  existingRestTime = "",
}) => {
  const parseRestTime = (timeStr: string): number => {
    const DEFAULT_SECONDS = 120; // Default to 2 minutes

    if (!timeStr) return DEFAULT_SECONDS;

    // Trim and simplify the input string
    const trimmed = timeStr
      .trim()
      .toLowerCase()
      .replace(/and|,/g, " ")
      .replace(/\s+/g, " ");

    // Parse "MM:SS" format
    const timeParts = trimmed.split(":");
    if (timeParts.length === 2) {
      const [minutes, seconds] = timeParts.map(
        (part) => parseInt(part, 10) || 0
      );
      const totalSeconds = (minutes || 0) * 60 + (seconds || 0);
      return !Number.isNaN(totalSeconds) && totalSeconds > 0
        ? totalSeconds
        : DEFAULT_SECONDS;
    }

    // Match number-unit pairs (minutes, seconds)
    const regex = /(\d+\.?\d*)\s*(m(?:in(?:utes?)?)?|s(?:ec(?:onds?)?)?)?/gi;
    const matches = Array.from(trimmed.matchAll(regex));

    const totalSeconds = matches.reduce((acc, match) => {
      const value = parseFloat(match[1]);
      const unit = (match[2] || "").toLowerCase();

      if (unit.startsWith("m")) return acc + value * 60; // Minutes
      if (unit.startsWith("s") || !unit) return acc + value; // Seconds or no unit
      return acc; // Handle unknown units gracefully
    }, 0);

    // Fallback if a valid match was found and parsed
    if (totalSeconds > 0) return totalSeconds;

    // If no valid units found, check if the entire string is a number
    const parsedNum = parseFloat(trimmed);
    return !Number.isNaN(parsedNum) && parsedNum > 0
      ? parsedNum
      : DEFAULT_SECONDS;
  };

  const [timeLeft, setTimeLeft] = useState<number>(
    parseRestTime(existingRestTime)
  ); // Default or parsed time
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [timerFinished, setTimerFinished] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [endTime, setEndTime] = useState<number | null>(null); // New state variable for end time

  useEffect(() => {
    const newTime = parseRestTime(existingRestTime);
    setTimeLeft(newTime);
    setTimerActive(false);
    setTimerFinished(false);
    setEndTime(null);
  }, [existingRestTime]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout | null = null;
    if (timerActive && endTime) {
      timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const newTimeLeft = (endTime - currentTime) / 1000; // in seconds
        if (newTimeLeft <= 0) {
          setTimeLeft(0);
          setTimerActive(false);
          setTimerFinished(true);
          setSnackbarOpen(true);
          setEndTime(null);
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);
    }
    return () => {
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [timerActive, endTime]);

  const handleMinus30 = () => {
    setTimeLeft((prevTime) => {
      const newTime = prevTime - 30;
      return newTime > 0 ? newTime : 0;
    });
    if (timerActive && endTime) {
      setEndTime((prevEndTime) => (prevEndTime ? prevEndTime - 30000 : null)); // Subtract 30 seconds
    }
  };

  const handlePlus30 = () => {
    setTimeLeft((prevTime) => prevTime + 30);
    if (timerActive && endTime) {
      setEndTime((prevEndTime) => (prevEndTime ? prevEndTime + 30000 : null)); // Add 30 seconds
    }
  };

  const handleStart = () => {
    setTimerActive(true);
    setTimerFinished(false);
    setEndTime(Date.now() + timeLeft * 1000); // Set the end time
  };

  const handlePause = () => {
    if (endTime) {
      const currentTime = Date.now();
      const newTimeLeft = (endTime - currentTime) / 1000;
      setTimeLeft(newTimeLeft > 0 ? newTimeLeft : 0);
    }
    setTimerActive(false);
    setEndTime(null);
  };

  const formatTime = (totalSeconds: number) => {
    const roundedSeconds = Math.ceil(totalSeconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const seconds = roundedSeconds % 60;
    const minutesStr = minutes.toString();
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds.toString();
    return `${minutesStr}:${secondsStr}`;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDialogClose = () => {
    onClose();
    setTimeLeft(parseRestTime(existingRestTime)); // Reset to default when dialog is closed
    setTimerActive(false);
    setTimerFinished(false);
    setEndTime(null);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="xs">
      <Box
        sx={{
          position: "relative",
          padding: 4,
          textAlign: "center",
          backgroundColor: Colors.gray[50],
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: `${Colors.gray[1200]}`,
          }}
          onClick={handleDialogClose}
        >
          <Icon>close</Icon>
        </IconButton>
        <Typography variant="h6" sx={{ marginBottom: 3 }}>
          Rest timer
        </Typography>
        <Box
          sx={{
            width: 200,
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          {timerFinished ? (
            <Typography variant="h5" color={Colors.red[500]}>
              Time is up
            </Typography>
          ) : (
            <Typography variant="h2" color={Colors.blue[700]}>
              {formatTime(timeLeft)}
            </Typography>
          )}
        </Box>
        {!timerFinished && (
          <>
            <Box>
              <Button
                variant="outlined"
                onClick={handleMinus30}
                sx={{
                  marginRight: 1,
                  textTransform: "none",
                }}
                color="primary"
              >
                -30s
              </Button>
              <Button
                variant="outlined"
                onClick={handlePlus30}
                sx={{
                  marginLeft: 1,
                  textTransform: "none",
                }}
                color="primary"
              >
                +30s
              </Button>
            </Box>
            {/* Start/Pause Button */}
            <Box sx={{ marginTop: 2.5 }}>
              {timerActive ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handlePause}
                  size="large"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Pause
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStart}
                  size="large"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Start
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        message="Time is up!"
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      />
    </Dialog>
  );
};

export default RestTimer;
