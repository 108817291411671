import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  Icon,
  TextField,
  useMediaQuery,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { Colors } from "shared/themes";
import {
  useGetNutritionPlansQuery,
  UserNutritionPlanResponse,
} from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar/IdenticonAvatar";
import { CreateNutritionPlanModal } from "components/Modals/CreateNutritionPlanModal";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { sharedStyles } from "shared/themes/shared/styles";
import SearchIcon from "@mui/icons-material/Search";
import { NutritionPlanItemAction } from "./NutritionPlanItemAction";

type UserProfileNutritionPlansProps = {
  userId: number;
};

const UserProfileNutritionPlans: FC<UserProfileNutritionPlansProps> = ({
  userId,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    data: nutritionPlans,
    isLoading,
    refetch: refetchNutritionPlans,
  } = useGetNutritionPlansQuery(
    {
      userId,
    },
    {
      skip: !userId,
    }
  );

  const [rows, setRows] = useState<UserNutritionPlanResponse[]>([]);

  useEffect(() => {
    refetchNutritionPlans();
  }, []);

  useEffect(() => {
    if (!nutritionPlans) return;
    setRows(nutritionPlans);
  }, [nutritionPlans]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      width: 60,
      sortable: false,
      renderCell: (params) => {
        const { userId, profileImageUrl } = params.row;
        return (
          <IdenticonAvatar
            seedValue={userId.toString()}
            profileImageUrl={profileImageUrl || ""}
          />
        );
      },
    },
    {
      field: "userId",
      headerName: "Athlete Name",
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { userName } = params.row;
        return (
          <Box
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: isMobile ? "0.875rem" : "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {userName}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Plan Name",
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: isMobile ? "0.875rem" : "14px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 1.5,
      hide: isMobile,
      sortable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "nutritionPlanStatus",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const statusStyles = {
          Undefined: {
            background: "#gray",
            color: "#666",
          },
          InProgress: {
            background: Colors.yellow[100],
            color: Colors.green[600],
          },
          NotStarted: {
            background: Colors.blue[1900],
            color: Colors.blue[1800],
          },
          Completed: {
            background: Colors.green[700],
            color: Colors.green[800],
          },
          Draft: {
            background: Colors.gray[1800],
            color: Colors.gray[2000],
          },
        }[params.value as string] || {
          background: "#gray",
          color: "#666",
        };

        const { color, background } = statusStyles;

        return (
          <Box
            sx={{
              width: "auto",
              height: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "140%,",
              textTransform: "uppercase",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "2.5px 8px",
              color,
              background,
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "6px",
                background: color,
                borderRadius: "50px",
                mr: "8px",
              }}
            />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 70,
      flex: 0,
      sortable: false,
      renderCell: (params) => (
        <NutritionPlanItemAction planId={params.row.id} />
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "800px",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: { xs: "8px", sm: "32px" },
        mb: "16px",
      }}
    >
      {!isLoading ? (
        <Box sx={{ height: "700px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              mb: 2,
              px: { xs: 1, sm: 0 },
              justifyContent: "flex-end",
              alignItems: { sm: "center" },
            }}
          >
            <div id="add-nutrition-plan">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: `${Colors.gray[100]}`,
                  textTransform: "none",
                }}
                onClick={() => setIsOpenModal(true)}
              >
                <Icon sx={{ marginRight: "8px" }}>add_icon</Icon>
                Create nutrition plan
              </Button>
            </div>
          </Box>

          <DataGrid
            headerHeight={56}
            rowHeight={isMobile ? 52 : 56}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={10}
            pagination
            rows={rows}
            columns={columns}
            rowCount={rows.length}
            paginationMode="client"
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                padding: isMobile ? "0 8px" : "0 16px",
              },
              "& .MuiDataGrid-virtualScroller": {
                overflowX: "auto !important",
                "&::-webkit-scrollbar": {
                  height: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: Colors.gray[500],
                  borderRadius: "4px",
                },
              },
            }}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}

      <CreateNutritionPlanModal
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        preselectedUserId={userId}
      />
    </Box>
  );
};

export { UserProfileNutritionPlans };
