import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { DayOfWeek, useCreateNutritionPlanMutation } from "shared/api";
import { Colors } from "shared/themes";
import { useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { OrganisationClientSelector } from "pages";

interface CreateNutritionPlanModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preselectedUserId?: number | null;
}

const CreateNutritionPlanSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  startDate: Yup.date().nullable(),
  endDate: Yup.date()
    .nullable()
    .min(Yup.ref("startDate"), "End date must be after start date"),
  userId: Yup.number().required("Athlete is required"),
});

const CreateNutritionPlanModal: FC<CreateNutritionPlanModalProps> = ({
  isOpenModal,
  handleCloseModal,
  preselectedUserId = null,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [createNutritionPlan, { isLoading }] = useCreateNutritionPlanMutation();

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      startDate: null,
      endDate: null,
      userId: preselectedUserId || "",
    },
    validationSchema: CreateNutritionPlanSchema,
    onSubmit: async (values) => {
      setErrorMessage("");

      try {
        const response = await createNutritionPlan({
          name: values.name,
          description: values.description,
          startDate: values.startDate
            ? dayjs(values.startDate).format()
            : undefined,
          endDate: values.endDate ? dayjs(values.endDate).format() : undefined,
          userId: Number(values.userId),
          nutritionPlanStatus: "Draft",
          meals: Array.from({ length: 2 }, (_, index) => ({
            day: DayOfWeek.Monday,
            name: `Meal ${index + 1}`,
            description: "",
            mealOrder: index + 1,
            items: [],
          })),
        }).unwrap();

        navigate(`${Routes.nutrition.url}/${response.id}`);
        handleCloseModal();
      } catch (error: any) {
        setErrorMessage(error?.data?.message || t("errors.server-unable"));
      }
    },
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Create Nutrition Plan
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>

          {!preselectedUserId && (
            <OrganisationClientSelector
              setSelectedUserId={(userId) => setFieldValue("userId", userId)}
              selectedUserId={Number(values.userId)}
            />
          )}

          <InputField
            type="text"
            value={values.name}
            label="Meal plan name"
            onBlur={handleBlur("name")}
            onChange={handleChange("name")}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name ? errors.name : ""}
          />

          <InputField
            type="text"
            value={values.description}
            label="Description"
            multiline
            rows={3}
            onBlur={handleBlur("description")}
            onChange={handleChange("description")}
            error={touched.description && !!errors.description}
            helperText={
              touched.description && errors.description
                ? errors.description
                : ""
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start date"
              value={values.startDate}
              onChange={(newValue) => {
                setFieldValue("startDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End date"
              value={values.endDate}
              onChange={(newValue) => {
                setFieldValue("endDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>

          <PrimaryButton
            size="large"
            type="submit"
            value="Create Plan"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            fullWidth
            onClick={() => handleSubmit()}
          />

          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateNutritionPlanModal };
