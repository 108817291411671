import { Box } from "@mui/system";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { CompletedWorkouts } from "./ui/CompletedWorkouts";

const AthleteProgramWorkouts: FC = () => {
  const params = useParams();
  const workoutProgramId = Number(params.id);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        paddingBottom: "60px",
        pt: 3,
      }}
    >
      <CompletedWorkouts existingWorkoutProgramId={workoutProgramId} />
    </Box>
  );
};

export { AthleteProgramWorkouts };
