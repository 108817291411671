export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export type NutritionPlanListParams = {
  count?: number;
  page?: number;
  userId?: number;
};

export type NutritionPlanCountParams = {
  userId?: number;
};

export type SearchFoodsParams = {
  search_expression: string;
};

export type NutritionPlanMealItem = {
  id: number;
  name: string;
  imageUrl: string;
  protein?: number;
  carbohydrates?: number;
  fats?: number;
  calories?: number;
  quantity?: number;
  servingSizeUnit?: string;
  serving: string;
  userNutritionPlanMealId: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdByUserId?: number;
  organisationId: number;
};

export type NutritionPlanMeal = {
  id: number;
  day: DayOfWeek;
  name: string;
  description: string;
  mealOrder: number;
  userNutritionPlanId: number;
  organisationId: number;
  userNutritionPlanMealItems: NutritionPlanMealItem[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdByUserId?: number;
};

export type NutritionPlan = {
  id: number;
  name: string;
  description: string;
  startDate?: string;
  endDate?: string;
  userId: number;
  organisationId: number;
  userNutritionPlanMeals: NutritionPlanMeal[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdByUserId?: number;
};

export type CreateNutritionPlanParams = {
  id?: number;
  name: string;
  description: string;
  startDate?: string;
  endDate?: string;
  userId: number;
  nutritionPlanStatus: string;
  meals: CreateNutritionPlanMeal[];
};

export type UpdateNutritionPlanParams = {
  id: number;
  name?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
};

export type CreateNutritionPlanMealParams = {
  day: DayOfWeek;
  name: string;
  description: string;
  mealOrder: number;
  userNutritionPlanId: number;
};

export type CreateNutritionPlanMealItemParams = {
  name: string;
  imageUrl?: string;
  protein?: number;
  carbohydrates?: number;
  fats?: number;
  calories?: number;
  quantity?: number;
  servingSizeUnit: string;
  serving: string;
  userNutritionPlanMealId: number;
};

export type Serving = {
  servingId: string;
  servingDescription: string;
  servingUrl: string;
  metricServingAmount: string;
  metricServingUnit: string;
  numberOfUnits: string;
  measurementDescription: string;
  calories: string;
  carbohydrate: string;
  protein: string;
  fat: string;
  saturatedFat: string;
  polyunsaturatedFat: string;
  monounsaturatedFat: string;
  cholesterol: string;
  sodium: string;
  potassium: string;
  fiber: string;
  sugar: string;
  vitaminA: string;
  vitaminC: string;
  calcium: string;
  iron: string;
};

export type ServingContainer = {
  servings: Serving[];
};

export type Preference = {
  id: string;
  name: string;
  value: string;
};

export type Allergen = {
  id: string;
  name: string;
  value: string;
};

export type FoodAttributes = {
  allergens: {
    allergen: Allergen[];
  };
  preferences: {
    preference: Preference[];
  };
};

export type FoodImage = {
  imageUrl: string;
  imageType: string;
};

export type FoodSubCategories = {
  foodSubCategory: string[];
};

export type Food = {
  foodId: string;
  foodName: string;
  foodType: string;
  foodSubCategories: FoodSubCategories;
  foodUrl: string;
  foodImages: FoodImage[];
  foodAttributes: FoodAttributes;
  servingContainer: ServingContainer;
};

export type UserNutritionPlanMealItemResponse = {
  id: number;
  name: string;
  imageUrl: string;
  protein?: number;
  carbohydrates?: number;
  fats?: number;
  calories?: number;
  quantity?: number;
  servingSizeUnit: string;
  serving: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdByUserId?: number;
  organisationId: number;
};

export type UserNutritionPlanMealResponse = {
  id: number;
  day: DayOfWeek;
  name: string;
  description: string;
  mealOrder: number;
  userNutritionPlanMealItems: UserNutritionPlanMealItemResponse[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdByUserId?: number;
  organisationId: number;
};

export type UserNutritionPlanResponse = {
  id: number;
  name: string;
  description: string;
  startDate?: string;
  endDate?: string;
  userId: number;
  organisationId: number;
  userNutritionPlanMeals?: UserNutritionPlanMealResponse[];
  nutritionPlanStatus: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  createdByUserId?: number;
  // only for list
  userName?: string;
  profileImageUrl?: string;
};

export type CreateNutritionPlanMealItem = {
  name: string;
  imageUrl: string;
  protein?: number;
  carbohydrates?: number;
  fats?: number;
  calories?: number;
  quantity?: number;
  servingSizeUnit: string;
  serving: string;
};

export type CreateNutritionPlanMeal = {
  day: DayOfWeek;
  name: string;
  description: string;
  mealOrder: number;
  items: CreateNutritionPlanMealItem[];
};
