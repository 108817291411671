import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  TextField,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  CreateBookingRequest,
  useCreateBookingMutation,
  BookingGroup,
  ClientResponse,
} from "shared/api";
import { Colors } from "shared/themes";
import { useFormik } from "formik";
import * as Yup from "yup";

interface CreateBookingModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  bookingGroups: BookingGroup[] | undefined;
  nonAthleteUsers: ClientResponse[] | undefined;
  athleteUsers: ClientResponse[] | undefined;
}

enum DaysOfWeek {
  Undefined = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
}

const dayNames = [
  { name: "Mon", value: DaysOfWeek.Monday },
  { name: "Tue", value: DaysOfWeek.Tuesday },
  { name: "Wed", value: DaysOfWeek.Wednesday },
  { name: "Thu", value: DaysOfWeek.Thursday },
  { name: "Fri", value: DaysOfWeek.Friday },
  { name: "Sat", value: DaysOfWeek.Saturday },
  { name: "Sun", value: DaysOfWeek.Sunday },
];

const CreateBookingModalSchema = Yup.object().shape({
  startTime: Yup.string().required(),
  endTime: Yup.string().required(),
  bookingGroupId: Yup.string().required(),
  privateBooking: Yup.boolean(),
});

const CreateBookingModal: FC<CreateBookingModalProps> = ({
  isOpenModal,
  handleCloseModal,
  bookingGroups,
  nonAthleteUsers,
  athleteUsers,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [selectedDays, setSelectedDays] = useState<DaysOfWeek>(
    DaysOfWeek.Undefined
  );

  const [isRecurring, setIsRecurring] = useState(false);

  const [privateBooking, setIsPrivateBooking] = useState(false);

  const [selectedCoaches, setSelectedCoaches] = useState<ClientResponse[]>([]);

  const [selectedAthletes, setSelectedAthletes] = useState<ClientResponse[]>(
    []
  );

  const [createBooking, { isLoading }] = useCreateBookingMutation();

  const onSubmit = async (params: CreateBookingRequest) => {
    setErrorMessage("");
    setSuccessMessage("");

    if (selectedDays === 0 && isRecurring) {
      setErrorMessage("Please select at least one day of the week");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    values.daysOfWeek = selectedDays;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    values.privateBooking = privateBooking;

    try {
      if (selectedCoaches && selectedCoaches.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        values.bookingCoachAttendeeUserIds = selectedCoaches.map(
          (coach) => coach.id
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        values.bookingAthleteAttendeeUserIds = selectedAthletes.map(
          (athlete) => athlete.id
        );
      }

      await createBooking(params).unwrap();
      setSuccessMessage("Booking created successfully");
      setSelectedDays(DaysOfWeek.Undefined);
      setIsRecurring(false);
      setIsPrivateBooking(false);
      setSelectedCoaches([]);
      setSelectedAthletes([]);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      maxAttendees: null,
      bookingGroupId: null,
      imageUrl: "",
      location: "",
      startTime: "",
      endTime: "",
      timezone: "",
      oneOffBookingDate: null,
      privateBooking: false,
      recurringFrequency: "",
      recurringStartDate: "",
      recurringEndDate: "",
      bookingAthleteAttendeeUserIds: [] as number[],
      bookingCoachAttendeeUserIds: [] as number[],
      daysOfWeek: 0,
    },
    validationSchema: CreateBookingModalSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const checkStartAndEndTimeIsValid = () => {
    setErrorMessage("");

    if (values.startTime && values.endTime) {
      const startTime = new Date(`01/01/2000 ${values.startTime}`);
      const endTime = new Date(`01/01/2000 ${values.endTime}`);

      if (startTime >= endTime) {
        setErrorMessage("End time must be after start time");
        return false;
      }
    }

    setErrorMessage("");
    return true;
  };

  const disableSubmitButton = () => {
    if (isLoading || !values.bookingGroupId) {
      return true;
    }

    if (
      isRecurring &&
      !selectedDays &&
      !values.recurringFrequency &&
      !values.recurringStartDate &&
      !values.recurringEndDate
    ) {
      return true;
    }

    if (!isRecurring && !values.oneOffBookingDate) {
      return true;
    }

    return false;
  };

  const handleDayToggle = (day: DaysOfWeek) => {
    /* eslint-disable no-bitwise */
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays & day ? prevSelectedDays & ~day : prevSelectedDays | day
    );
    /* eslint-enable no-bitwise */
  };

  useEffect(() => {
    checkStartAndEndTimeIsValid();
  }, [values.startTime, values.endTime]);

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Create new booking
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
                color: `${Colors.gray[1400]}`,
              }}
            >
              Event type
            </Typography>

            <Select
              label="Event type"
              value={values.bookingGroupId}
              onChange={(e) => setFieldValue("bookingGroupId", e.target.value)}
              error={!!errors.bookingGroupId}
              fullWidth
            >
              {bookingGroups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  mt: 2,
                  mb: 1,
                  color: `${Colors.gray[1400]}`,
                }}
              >
                Start time
              </Typography>
              <input
                type="time"
                value={values.startTime}
                onChange={(e) => setFieldValue("startTime", e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  mt: 2,
                  mb: 1,
                  color: `${Colors.gray[1400]}`,
                }}
              >
                End time
              </Typography>
              <input
                type="time"
                value={values.endTime}
                onChange={(e) => setFieldValue("endTime", e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <label htmlFor="privateBooking" style={{ cursor: "pointer" }}>
              <input
                id="privateBooking"
                type="checkbox"
                checked={privateBooking}
                onChange={() => setIsPrivateBooking(!privateBooking)}
                style={{ marginLeft: "5px" }}
              />
              Private booking
            </label>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <label htmlFor="recurring" style={{ cursor: "pointer" }}>
              <input
                id="recurring"
                type="checkbox"
                checked={isRecurring}
                onChange={() => setIsRecurring(!isRecurring)}
                style={{ marginLeft: "5px" }}
              />
              Recurring
            </label>
          </Box>
          <Divider sx={{ mt: 2 }} />
          {isRecurring ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                {dayNames.map((day) => (
                  <div key={day.value} style={{ textAlign: "center" }}>
                    <input
                      id={`day-${day.value}`}
                      type="checkbox"
                      checked={!!(selectedDays & day.value)} // eslint-disable-line no-bitwise
                      onChange={() => handleDayToggle(day.value)}
                      style={{ marginBottom: "5px" }}
                    />
                    <label
                      htmlFor={`day-${day.value}`}
                      style={{ fontSize: "14px", cursor: "pointer" }}
                    >
                      {day.name}
                    </label>
                  </div>
                ))}
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    mt: 2,
                    mb: 1,
                    color: `${Colors.gray[1400]}`,
                  }}
                >
                  Frequency
                </Typography>
                <Select
                  label="Frequency"
                  value={values.recurringFrequency}
                  onChange={(e) =>
                    setFieldValue("recurringFrequency", e.target.value)
                  }
                  fullWidth
                >
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="fortnightly">Fortnightly</MenuItem>
                </Select>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      mt: 2,
                      mb: 1,
                      color: `${Colors.gray[1400]}`,
                    }}
                  >
                    Start date
                  </Typography>
                  <input
                    type="date"
                    value={values.recurringStartDate}
                    onChange={(e) =>
                      setFieldValue("recurringStartDate", e.target.value)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      mt: 2,
                      mb: 1,
                      color: `${Colors.gray[1400]}`,
                    }}
                  >
                    End date
                  </Typography>
                  <input
                    type="date"
                    value={values.recurringEndDate}
                    onChange={(e) =>
                      setFieldValue("recurringEndDate", e.target.value)
                    }
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  mt: 2,
                  mb: 1,
                  color: `${Colors.gray[1400]}`,
                }}
              >
                Booking date
              </Typography>
              <input
                type="date"
                value={values.oneOffBookingDate || ""}
                onChange={(e) =>
                  setFieldValue("oneOffBookingDate", e.target.value)
                }
              />
            </Box>
          )}
          <Divider sx={{ mt: 2 }} />
          <Box>
            <Autocomplete
              multiple
              options={nonAthleteUsers || []}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              value={selectedCoaches}
              onChange={(event, newValue) => {
                setSelectedCoaches(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select coaches"
                  placeholder="Coaches"
                />
              )}
              sx={{ mt: 2 }}
            />
            <Autocomplete
              multiple
              options={athleteUsers || []}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              value={selectedAthletes}
              onChange={(event, newValue) => {
                setSelectedAthletes(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select athletes"
                  placeholder="Athletes"
                />
              )}
              sx={{ mt: 2 }}
            />
          </Box>
          <PrimaryButton
            size="large"
            type="submit"
            value="Create new booking"
            loading={isLoading}
            disabled={disableSubmitButton()}
            variant="contained"
            fullWidth
            onClick={() => handleSubmit()}
          />
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateBookingModal };
