import React from "react";
import ReactDOM from "react-dom";
import posthog from "posthog-js";
import { isDev } from "./shared/helpers";
import App from "./App";
import { LocalizationService } from "./shared/services";
import "./index.css";

LocalizationService.initLocalization();

if (!isDev) {
  posthog.init(process.env.REACT_APP_POST_HOG_APP_ID!, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
      recordBody: true,
      recordHeaders: true,
    },
  });
}

// Unregister the legacy service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    console.log("Unregistering service worker");
    registration.unregister();
  });
}

// eslint-disable-next-line
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
