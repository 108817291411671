import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import { useFormik } from "formik";

interface AddUserNoteModalProps {
  isOpenModal: boolean;
  isLoading: boolean;
  handleCloseModal: () => void;
  onSubmit: (params: AddUserNoteParams) => void;
}

export type AddUserNoteParams = {
  clinicalAbbreviations: boolean;
  includePreviousHistory: boolean;
};

const AddUserNoteModal: FC<AddUserNoteModalProps> = ({
  isOpenModal,
  isLoading,
  handleCloseModal,
  onSubmit: onParentSubmit,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { values, handleBlur, setFieldValue, errors, handleSubmit } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        useAbbreviations: true,
        includePreviousHistory: false,
      },
      onSubmit: (params) => {
        onParentSubmit({
          clinicalAbbreviations: params.useAbbreviations,
          includePreviousHistory: params.includePreviousHistory,
        });
      },
    }
  );

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Generate SOAP
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
          >
            This will generate a clinical note summary for the client based on
            the transcript provided.
            <br />
            <br />
            You will be able to review the summary and make any necessary
            changes prior to sending it to the client.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.useAbbreviations}
                onChange={(e) =>
                  setFieldValue("useAbbreviations", e.target.checked)
                }
                name="useAbbreviations"
                color="primary"
              />
            }
            label="Clinical Abbreviations"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              color: `${Colors.gray[1400]}`,
              mt: 2,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.includePreviousHistory}
                onChange={(e) =>
                  setFieldValue("includePreviousHistory", e.target.checked)
                }
                name="includePreviousHistory"
                color="primary"
              />
            }
            label="Include Previous History"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              color: `${Colors.gray[1400]}`,
              mt: 2,
            }}
          />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              color: `${Colors.gray[1400]}`,
              mt: 2,
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.seslhd.health.nsw.gov.au/sites/default/files/groups/Executive_Services/Clinical%20Abbreviations%20List%20-%20SESLHDPR%20282.pdf"
            >
              Clinical Abbreviations
            </a>{" "}
            are a way to shorten the note by using shorthand terms. For example,
            Current History can be abbreviated to CHx.
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              mt: 2,
            }}
            onClick={() => handleSubmit()}
            loading={isLoading}
          >
            <Icon>auto_awesome</Icon> Generate SOAP
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { AddUserNoteModal };
