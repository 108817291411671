import posthog from "posthog-js";
import { isDev } from "shared/helpers";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";

export const useAnalytics = () => {
  const currentUser = useTypedSelector(userSelectors.all);

  const startSession = () => {
    if (!isDev) {
      posthog.identify(`${currentUser.id}`, {
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: `${currentUser.email}`,
      });
    }
  };

  return {
    startSession,
  };
};
