export enum UserRole {
  Admin = "Admin",
  Athlete = "Athlete",
  Coach = "Coach",
}

export enum UserCategory {
  Online = "Online",
  InPerson = "InPerson",
  Hybrid = "Hybrid",
  NonCoaching = "NonCoaching",
  All = "",
}

export interface LoginResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userRole: UserRole;
  created: string;
  updated: string;
  isVerified: boolean;
  swapExercisesEnabled: boolean;
  jwtToken: string;
  refreshToken: string;
  organisationId: number;
  profileImageUrl: string;
  organisationType: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ResetPasswordRequest {
  token: string;
  password: string;
  confirmPassword: string;
}
