import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
  Alert,
  Collapse,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useGetNutritionPlansQuery } from "shared/api";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import {
  ActionButton,
  WorkoutPageSkeleton,
} from "pages/Dashboard/AthleteDashboard/ui";

const AthleteNutrition: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userId = useTypedSelector(userSelectors.userId);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const { data: nutritionPlans, isLoading } = useGetNutritionPlansQuery(
    { userId: userId || undefined },
    { skip: !userId }
  );

  if (isLoading) {
    return <WorkoutPageSkeleton />;
  }

  const currentPlans =
    nutritionPlans?.filter((plan) => {
      return (
        plan.nutritionPlanStatus === "InProgress" ||
        plan.nutritionPlanStatus === "NotStarted"
      );
    }) || [];

  const pastPlans =
    nutritionPlans?.filter((plan) => {
      return plan.nutritionPlanStatus === "Completed";
    }) || [];

  if (!nutritionPlans?.length) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography
          sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)", mb: 1 }}
        >
          Nutrition plans
        </Typography>
        <Alert
          variant="outlined"
          severity="info"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography variant="body2">
            Speak to your coach to be assigned a nutrition plan
          </Typography>
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", pt: 3 }}>
      <Box
        sx={{
          flexGrow: 1,
          padding: "0px 24px",
        }}
      >
        <Typography
          sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)", mb: 1 }}
        >
          Nutrition plans
        </Typography>

        {currentPlans.length > 0 && (
          <>
            <ActionButton
              isActive={activeIndex === 0}
              title="Current plans"
              onPress={() => setActiveIndex(activeIndex === 0 ? null : 0)}
            />
            <Collapse in={activeIndex === 0} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                {currentPlans.map((plan) => (
                  <Grid item xs={12} sm={6} md={4} key={plan.id}>
                    <Card
                      sx={{
                        height: "100%",
                        "&:hover": {
                          boxShadow: 3,
                        },
                      }}
                    >
                      <CardActionArea
                        onClick={() =>
                          navigate(`${Routes.athleteNutrition.url}/${plan.id}`)
                        }
                        sx={{ height: "100%" }}
                      >
                        <CardContent>
                          <Typography
                            variant="h6"
                            sx={{
                              mb: 1,
                              fontWeight: 600,
                              color: Colors.gray[900],
                            }}
                          >
                            {plan.name}
                          </Typography>
                          {plan.description && (
                            <Typography
                              variant="body2"
                              color={Colors.gray[600]}
                              sx={{ mb: 1 }}
                            >
                              {plan.description}
                            </Typography>
                          )}
                          <Typography variant="body2" color={Colors.gray[600]}>
                            {plan.startDate &&
                              dayjs(plan.startDate).format("MMM D, YYYY")}
                            {plan.endDate &&
                              ` - ${dayjs(plan.endDate).format("MMM D, YYYY")}`}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </>
        )}
        <Box sx={{ mt: 1 }} />

        {pastPlans.length > 0 && (
          <>
            <ActionButton
              isActive={activeIndex === 1}
              title="Past plans"
              onPress={() => setActiveIndex(activeIndex === 1 ? null : 1)}
            />
            <Collapse in={activeIndex === 1} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                {pastPlans.map((plan) => (
                  <Grid item xs={12} sm={6} md={4} key={plan.id}>
                    <Card
                      sx={{
                        height: "100%",
                        "&:hover": {
                          boxShadow: 3,
                        },
                      }}
                    >
                      <CardActionArea
                        onClick={() =>
                          navigate(`${Routes.athleteNutrition.url}/${plan.id}`)
                        }
                        sx={{ height: "100%" }}
                      >
                        <CardContent>
                          <Typography
                            variant="h6"
                            sx={{
                              mb: 1,
                              fontWeight: 600,
                              color: Colors.gray[900],
                            }}
                          >
                            {plan.name}
                          </Typography>
                          {plan.description && (
                            <Typography
                              variant="body2"
                              color={Colors.gray[600]}
                              sx={{ mb: 1 }}
                            >
                              {plan.description}
                            </Typography>
                          )}
                          <Typography variant="body2" color={Colors.gray[600]}>
                            {plan.startDate &&
                              dayjs(plan.startDate).format("MMM D, YYYY")}
                            {plan.endDate &&
                              ` - ${dayjs(plan.endDate).format("MMM D, YYYY")}`}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </>
        )}
      </Box>
    </Box>
  );
};

export { AthleteNutrition };
