import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { FC } from "react";
import { UserCategory } from "shared/api";

interface UserCategoryRadioGroupProps {
  value: string;
  setValue: (value: string) => void;
}

const categories = [
  { value: UserCategory.Hybrid, label: "Hybrid" },
  { value: UserCategory.InPerson, label: "In-Person" },
  { value: UserCategory.Online, label: "Online" },
  { value: UserCategory.NonCoaching, label: "Non-Coaching" },
  { value: UserCategory.All, label: "All" },
];

export const UserCategoryRadioGroup: FC<UserCategoryRadioGroupProps> = ({
  value,
  setValue,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  if (isSmallScreen) {
    return (
      <FormControl
        variant="standard"
        sx={{
          minWidth: 120,
        }}
      >
        <InputLabel>Category</InputLabel>
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Category"
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{
          "& .MuiRadio-root": {
            padding: "4px",
          },
          "& .MuiFormControlLabel-label": {
            fontSize: "14px",
          },
        }}
      >
        {categories.map((category) => (
          <FormControlLabel
            key={category.value}
            value={category.value}
            control={<Radio size="small" />}
            label={category.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
