import { FC, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { getGraphicSvg } from "shared/themes";
import { useGetBookingGroupsQuery, BookingGroup } from "shared/api";
import LoadingSkeleton from "components/Skeleton/LoadingSkeleton";
import { UpdateEventTypeModal } from "./UpdateEventTypeModal";

interface BookingEventTypesContainerProps {
  bookingGroups: BookingGroup[] | undefined;
  isLoadingBookingGroups: boolean;
}

const BookingEventTypesContainer: FC<BookingEventTypesContainerProps> = ({
  bookingGroups,
  isLoadingBookingGroups,
}) => {
  const eventsSvg = getGraphicSvg("events");

  const [pageSize, setPageSize] = useState(10);

  const [page, setPage] = useState(1);

  const [isUpdateEventTypeModalOpen, setIsUpdateEventTypeModalOpen] =
    useState(false);

  const handleCloseUpdateEventTypeModal = () => {
    setIsUpdateEventTypeModalOpen(false);
  };

  const [selectedBookingGroup, setSelectedBookingGroup] =
    useState<BookingGroup | null>(null);

  const handleUpdateEventType = (group: BookingGroup) => {
    setSelectedBookingGroup(group);
    setIsUpdateEventTypeModalOpen(true);
  };

  if (isLoadingBookingGroups) {
    return <LoadingSkeleton />;
  }

  return (
    <Box>
      {bookingGroups && bookingGroups.length > 0 ? (
        <Grid container spacing={3}>
          {bookingGroups.map((group) => (
            <Grid item xs={12} sm={6} md={4} key={group.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={group.imageUrl || ""}
                  alt={group.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {group.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {group.description}
                  </Typography>
                </CardContent>
                <Button onClick={() => handleUpdateEventType(group)}>
                  View
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box textAlign="center" mt={5}>
          <Box
            component="img"
            src={eventsSvg}
            alt="Events no data"
            sx={{ height: "125px", mb: 4 }}
          />
          <Typography variant="h6" color="textSecondary">
            There are no event types. Please add one.
          </Typography>
        </Box>
      )}
      <UpdateEventTypeModal
        isOpenModal={isUpdateEventTypeModalOpen}
        handleCloseModal={handleCloseUpdateEventTypeModal}
        bookingGroup={selectedBookingGroup}
      />
    </Box>
  );
};

export { BookingEventTypesContainer };
