import { FC, useEffect, useState } from "react";
import { Button, Icon, Typography, Tab, useMediaQuery } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { ProgramsContainer } from "./ui/ProgramsContainer";
import { ProgramTemplatesContainer } from "./ui/ProgramTemplatesContainer";
import { AddBaseWorkoutProgramModal } from "../../ModernProgramBuilder/AddBaseWorkoutProgramModal";
import { GenerateProgramModal } from "../../ModernProgramBuilder/GenerateProgramModal";

interface ProgramsProps {
  getUserIdForWorkoutCreation: (userId: number) => void;
  setCreateWorkoutUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

const AdminPrograms: FC<ProgramsProps> = ({
  getUserIdForWorkoutCreation,
  setCreateWorkoutUserId,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const [openBaseProgramModal, setOpenBaseProgramModal] = useState(false);

  const [isTemplateProgram, setIsTemplateProgram] = useState(false);

  const [openGenerateProgramModal, setOpenGenerateProgramModal] =
    useState(false);

  const handleCloseGenerateProgramModal = () => {
    setOpenGenerateProgramModal(false);
  };

  const handleCloseBaseProgramModal = () => {
    setOpenBaseProgramModal(false);
  };

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setCreateWorkoutUserId(null);
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mb: { xs: 2, sm: 4 },
        }}
      >
        <Box
          sx={{
            ...sharedStyles.containers.sidebar.heading,
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
            alignItems: { xs: "stretch", sm: "center" },
          }}
        >
          {!isMobile && (
            <Typography
              sx={{
                ...sharedStyles.headings.sidebar,
                mb: { xs: 1, sm: 0 },
              }}
            >
              Programs
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 1, sm: 2 },
              width: "100%",
              maxWidth: { sm: "fit-content" },
            }}
          >
            <div id="add-template-program">
              <Button
                variant="outlined"
                color="primary"
                fullWidth={isMobile}
                size={isMobile ? "small" : "medium"}
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  minHeight: { xs: "32px", sm: "36px" },
                }}
                onClick={() => {
                  setIsTemplateProgram(true);
                  setOpenBaseProgramModal(true);
                }}
              >
                <Icon sx={{ mr: 1, fontSize: isMobile ? "18px" : "24px" }}>
                  add_icon
                </Icon>
                Create template
              </Button>
            </div>
            <div id="generate-program">
              <Button
                variant="outlined"
                color="primary"
                fullWidth={isMobile}
                size={isMobile ? "small" : "medium"}
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  minHeight: { xs: "32px", sm: "36px" },
                }}
                onClick={() => {
                  setIsTemplateProgram(false);
                  setOpenGenerateProgramModal(true);
                }}
              >
                <Icon sx={{ mr: 1, fontSize: isMobile ? "18px" : "24px" }}>
                  auto_awesome
                </Icon>
                Generate program
              </Button>
            </div>
            <div id="add-workout-program">
              <Button
                variant="contained"
                color="primary"
                fullWidth={isMobile}
                size={isMobile ? "small" : "medium"}
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: `${Colors.gray[100]}`,
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  minHeight: { xs: "32px", sm: "36px" },
                }}
                onClick={() => {
                  setIsTemplateProgram(false);
                  setOpenBaseProgramModal(true);
                }}
              >
                <Icon sx={{ mr: 1, fontSize: isMobile ? "18px" : "24px" }}>
                  add_icon
                </Icon>
                Create program
              </Button>
            </div>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          mt: 4,
        }}
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="Tabs"
              variant={isMobile ? "fullWidth" : "standard"}
            >
              <Tab label="Programs" value="1" sx={{ textTransform: "none" }} />
              <Tab label="Templates" value="2" sx={{ textTransform: "none" }} />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: { xs: 0, sm: 3 } }}>
            <ProgramsContainer
              getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ p: { xs: 0, sm: 3 } }}>
            <ProgramTemplatesContainer />
          </TabPanel>
        </TabContext>
      </Box>

      <AddBaseWorkoutProgramModal
        isOpenModal={openBaseProgramModal}
        handleCloseModal={handleCloseBaseProgramModal}
        isTemplateProgram={isTemplateProgram}
      />
      <GenerateProgramModal
        isOpenModal={openGenerateProgramModal}
        handleCloseModal={handleCloseGenerateProgramModal}
      />
    </Box>
  );
};

export { AdminPrograms };
