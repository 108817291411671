import { FC, useState } from "react";
import { Box, Typography, Snackbar, Alert } from "@mui/material";
import { BookingsRequestParams, useGetBookingsQuery } from "shared/api";
import { getGraphicSvg } from "shared/themes";
import { BookingCard } from "./BookingCard";

interface AthleteBookingScheduleProps {
  closeSidebar: () => void;
}

const AthleteBookingSchedule: FC<AthleteBookingScheduleProps> = ({
  closeSidebar,
}) => {
  const calendarSvg = getGraphicSvg("calendar");
  const eventsSvg = getGraphicSvg("events");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(5);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    data: usersUpcomingBookings,
    isLoading: isLoadingUsersUpcomingBookings,
  } = useGetBookingsQuery({
    page,
    count,
    myUpcomingBookings: true,
  } as BookingsRequestParams);

  const {
    data: bookingsThatTheUserIsNotAttending,
    isLoading: isLoadingBookingsThatTheUserIsNotAttending,
  } = useGetBookingsQuery({
    page,
    count,
    filterOutBookingsThatUserIsAttending: true,
  } as BookingsRequestParams);

  const handleActionSuccess = (message: string, isError = false) => {
    if (isError) {
      setErrorMessage(message);
    } else {
      setSuccessMessage(message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        paddingBottom: "60px",
        padding: "20px 16px",
      }}
    >
      {/* User's Upcoming Bookings */}
      <Box>
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          Upcoming bookings
        </Typography>
        {isLoadingUsersUpcomingBookings && <Typography>Loading...</Typography>}
        {!isLoadingUsersUpcomingBookings &&
          usersUpcomingBookings &&
          usersUpcomingBookings.length > 0 &&
          usersUpcomingBookings.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              onActionComplete={handleActionSuccess}
              showLeaveButton
            />
          ))}
        {!isLoadingUsersUpcomingBookings &&
          (!usersUpcomingBookings || usersUpcomingBookings.length === 0) && (
            <Box textAlign="center" mt={5}>
              <Box
                component="img"
                src={calendarSvg}
                alt="Calendar no data"
                sx={{ height: "100px", mb: 4 }}
              />
              <Typography>There are no bookings in your schedule.</Typography>
            </Box>
          )}
      </Box>

      {/* Timetable Bookings */}
      <Box sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          Timetable
        </Typography>
        {isLoadingBookingsThatTheUserIsNotAttending && (
          <Typography>Loading...</Typography>
        )}
        {!isLoadingBookingsThatTheUserIsNotAttending &&
          bookingsThatTheUserIsNotAttending &&
          bookingsThatTheUserIsNotAttending.length > 0 &&
          bookingsThatTheUserIsNotAttending.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              onActionComplete={handleActionSuccess}
              showLeaveButton={false}
            />
          ))}
        {!isLoadingBookingsThatTheUserIsNotAttending &&
          (!bookingsThatTheUserIsNotAttending ||
            bookingsThatTheUserIsNotAttending.length === 0) && (
            <Box textAlign="center" mt={5}>
              <Box
                component="img"
                src={eventsSvg}
                alt="Calendar no data"
                sx={{ height: "100px", mb: 4 }}
              />
              <Typography>
                There are no bookings available to book in the timetable.
              </Typography>
            </Box>
          )}
      </Box>

      {/* Success Message Snackbar */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Message Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export { AthleteBookingSchedule };
