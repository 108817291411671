import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  CreateBookingGroupRequest,
  useCreateBookingGroupMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { useFormik } from "formik";
import * as Yup from "yup";

interface CreateEventTypeModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
}

const CreateBookingGroupSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
  imageUrl: Yup.string(),
  groupColour: Yup.string(),
});

const CreateEventTypeModal: FC<CreateEventTypeModalProps> = ({
  isOpenModal,
  handleCloseModal,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [createBookingGroup, { isLoading }] = useCreateBookingGroupMutation();

  const [showPricingInfo, setShowPricingInfo] = useState(false);

  const onSubmit = async (params: CreateBookingGroupRequest) => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      await createBookingGroup(params).unwrap();
      setSuccessMessage("Event type created successfully");
      setShowPricingInfo(false);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      location: "",
      imageUrl: "",
      durationInMinutes: null,
      groupColour: "",
      maxAttendees: null,
      maxRecurringBookingsPerAttendee: null,
      daysOfWeek: 0,
      startTime: "",
      endTime: "",
      timeZone: "",
      isRecurring: false,
      productId: "",
      priceId: "",
      paymentRequired: false,
      price: null,
    },
    validationSchema: CreateBookingGroupSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            width: "50%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Create new event type
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <Box sx={{ flex: "1 1 100%" }}>
              <InputField
                type="text"
                name="name"
                label="Name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.name}
                helperText={errors.name ? errors.name : ""}
              />
            </Box>
            <Box sx={{ flex: "1 1 100%" }}>
              <InputField
                type="text"
                name="description"
                label="Description"
                value={values.description}
                placeholder="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.description}
                multiline
                rows={4}
                helperText={errors.description ? errors.description : ""}
              />
            </Box>
            <Box sx={{ flex: "1 1 30%" }}>
              <InputField
                type="number"
                name="durationInMinutes"
                label="Duration (minutes)"
                value={values.durationInMinutes}
                placeholder="Duration in minutes"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.durationInMinutes}
                helperText={
                  errors.durationInMinutes ? errors.durationInMinutes : ""
                }
              />
            </Box>
            <Box sx={{ flex: "1 1 30%" }}>
              <InputField
                type="number"
                name="maxAttendees"
                label="Capacity"
                value={values.maxAttendees}
                placeholder="Capacity"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.maxAttendees}
                helperText={errors.maxAttendees ? errors.maxAttendees : ""}
              />
            </Box>
            <Box sx={{ flex: "1 1 30%" }}>
              <InputField
                type="number"
                name="maxRecurringBookingsPerAttendee"
                label="Max recurring bookings per athlete"
                placeholder="Max recurring bookings per athlete"
                value={values.maxRecurringBookingsPerAttendee}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.maxRecurringBookingsPerAttendee}
                helperText={
                  errors.maxRecurringBookingsPerAttendee
                    ? errors.maxRecurringBookingsPerAttendee
                    : ""
                }
              />
            </Box>
            <Box sx={{ flex: "1 1 30%" }}>
              <InputField
                type="text"
                name="imageUrl"
                label="Image URL"
                value={values.imageUrl}
                placeholder="Image URL"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.imageUrl}
                helperText={errors.imageUrl ? errors.imageUrl : ""}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 2,
              marginTop: "10px",
            }}
          >
            <label htmlFor="recurring" style={{ cursor: "pointer" }}>
              <input
                id="recurring"
                type="checkbox"
                checked={showPricingInfo}
                onChange={() => setShowPricingInfo(!showPricingInfo)}
                style={{ marginLeft: "5px" }}
              />
              Pricing info
            </label>
          </Box>

          {showPricingInfo && (
            <Box
              sx={{
                mt: 2,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
              }}
            >
              <InputField
                type="text"
                name="productId"
                label="Product ID"
                value={values.productId}
                placeholder="Product ID"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.productId}
                helperText={errors.productId ? errors.productId : ""}
              />
              <InputField
                type="text"
                name="priceId"
                label="Price ID"
                value={values.priceId}
                placeholder="Price ID"
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.priceId}
                helperText={errors.priceId ? errors.priceId : ""}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  gridColumn: "span 2",
                }}
              >
                <label htmlFor="paymentRequired" style={{ cursor: "pointer" }}>
                  <input
                    id="paymentRequired"
                    type="checkbox"
                    checked={values.paymentRequired}
                    onChange={() =>
                      setFieldValue("paymentRequired", !values.paymentRequired)
                    }
                    style={{ marginLeft: "5px" }}
                  />
                  Payment required
                </label>
              </Box>
            </Box>
          )}

          <PrimaryButton
            size="large"
            type="submit"
            value="Create event type"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            fullWidth
            onClick={() => handleSubmit()}
          />
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateEventTypeModal };
