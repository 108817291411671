import { Typography } from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import { CreateNutritionPlanModal } from "components/Modals/CreateNutritionPlanModal";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { FC, useState } from "react";
import { useTypedSelector } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { NutritionPlansContainer } from "./NutritionPlansContainer";

const NutritionPlans: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={sharedStyles.containers.sidebar.heading}>
          <Typography sx={sharedStyles.headings.sidebar}>Nutrition</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PrimaryButton
              value="Add Plan"
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleToggleModal}
              icon="add_icon"
            />
          </Box>
        </Box>
      </Box>

      <NutritionPlansContainer />

      <CreateNutritionPlanModal
        handleCloseModal={handleToggleModal}
        isOpenModal={isOpenModal}
      />
    </Box>
  );
};

export { NutritionPlans };
