import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { FC, useEffect, useState } from "react";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import {
  useGetNutritionPlansQuery,
  useGetNutritionPlansCountQuery,
  UserNutritionPlanResponse,
} from "shared/api";
import { NutritionPlanItemAction } from "./NutritionPlanItemAction";

const NutritionPlansContainer: FC = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");

  const { data: nutritionPlansLength } = useGetNutritionPlansCountQuery();

  const { data: nutritionPlans, isLoading } = useGetNutritionPlansQuery({
    count: pageSize,
    page,
  });

  const [rows, setRows] = useState<UserNutritionPlanResponse[]>([]);
  const [rowCountState, setRowCountState] = useState(0);

  useEffect(() => {
    if (!nutritionPlans) return;
    if (searchValue) {
      const filteredData = nutritionPlans.filter((item) => {
        return (
          item?.userName?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.name?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setRows(filteredData);
      setRowCountState(filteredData.length);
    } else {
      setRows(nutritionPlans);
      setRowCountState((prevRowCountState) =>
        nutritionPlansLength !== undefined
          ? nutritionPlansLength
          : prevRowCountState
      );
    }
  }, [searchValue, nutritionPlans, nutritionPlansLength]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      nutritionPlansLength !== undefined
        ? nutritionPlansLength
        : prevRowCountState
    );
  }, [nutritionPlansLength, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      width: 60,
      sortable: false,
      renderCell: (params) => {
        const { userId, profileImageUrl } = params.row;
        return (
          <IdenticonAvatar
            sizeValue={40}
            seedValue={userId.toString()}
            profileImageUrl={profileImageUrl || ""}
          />
        );
      },
    },
    {
      field: "userId",
      headerName: "Athlete Name",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { userName } = params.row;
        return (
          <Box
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
            }}
          >
            {userName}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Plan Name",
      flex: 1.25,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 1,
      sortable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "nutritionPlanStatus",
      headerName: "Status",
      flex: 0.75,
      sortable: false,
      renderCell: (params) => {
        const statusStyles = {
          Undefined: {
            background: "#gray",
            color: "#666",
          },
          InProgress: {
            background: Colors.yellow[100],
            color: Colors.green[600],
          },
          NotStarted: {
            background: Colors.blue[1900],
            color: Colors.blue[1800],
          },
          Completed: {
            background: Colors.green[700],
            color: Colors.green[800],
          },
          Draft: {
            background: Colors.gray[1800],
            color: Colors.gray[2000],
          },
        }[params.value as string] || {
          background: "#gray",
          color: "#666",
        };

        const { color, background } = statusStyles;

        return (
          <Box
            sx={{
              width: "auto",
              height: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "140%,",
              textTransform: "uppercase",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "2.5px 8px",
              color,
              background,
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "6px",
                background: color,
                borderRadius: "50px",
                mr: "8px",
              }}
            />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        return <NutritionPlanItemAction planId={params.row.id} />;
      },
    },
  ];

  return (
    <Box sx={sharedStyles.containers.dataGrid.wrapper}>
      <Box
        sx={{
          ...sharedStyles.containers.dataGrid.secondaryWrapper,
          justifyContent: "flex-end",
        }}
      >
        <TextField
          label="Search by athlete or plan name..."
          variant="standard"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          disabled={isLoading}
        />
      </Box>

      {!isLoading ? (
        <Box sx={{ height: "700px" }}>
          <DataGrid
            headerHeight={56}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            paginationMode="server"
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};

export { NutritionPlansContainer };
