import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { HealthSummary, ProfileToken } from "./models";

export const healthScoresApi = createApi({
  baseQuery,
  reducerPath: "healthScoresApi",
  tagTypes: ["HealthScores", "ProfileToken"],
  endpoints: (builder) => ({
    getHealthScoresForUser: builder.query<
      HealthSummary,
      { type: string; userId: number }
    >({
      query: ({ type, userId }) => `health-scores/${type}/${userId}`,
      providesTags: ["HealthScores"],
    }),
    getProfileTokenForUser: builder.query<ProfileToken, {}>({
      query: () => `health-scores/token`,
      providesTags: ["ProfileToken"],
    }),
    activateProfileToken: builder.mutation<void, void>({
      query: () => ({
        url: "health-scores/activate",
        method: "POST",
      }),
      invalidatesTags: ["HealthScores", "ProfileToken"],
    }),
  }),
});

export const {
  useGetHealthScoresForUserQuery,
  useGetProfileTokenForUserQuery,
  useActivateProfileTokenMutation,
} = healthScoresApi;
