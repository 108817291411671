export const formatDuration = (minutes: number) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hrs}h ${mins}m`;
};

export const getGradientForScore = (title: string) => {
  switch (title.toLowerCase()) {
    case "good":
      return "linear-gradient(90deg, #2196f3, #00e676, #76ff03)";
    case "average":
      return "linear-gradient(90deg, #ff9900, #ffcc00)";
    case "poor":
      return "linear-gradient(90deg, #ff3300, #ff6600)";
    default:
      return "linear-gradient(90deg, #ff0066, #ff66cc, #ff33cc, #9933ff, #6633ff, #33ccff)";
  }
};

export const formatPercentage = (value: number): string => {
  return `${Math.round(value)}%`;
};

export enum PerformanceLevel {
  POOR = "POOR",
  AVERAGE = "AVERAGE",
  GOOD = "GOOD",
}

export const determinePerformanceLevel = (
  value: number,
  target: number
): PerformanceLevel => {
  const ratio = value / target;
  if (ratio < 0.3) return PerformanceLevel.POOR;
  if (ratio < 0.7) return PerformanceLevel.AVERAGE;
  return PerformanceLevel.GOOD;
};
