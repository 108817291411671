import React, { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useGetHealthScoresForUserQuery } from "shared/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  getActivityInsights,
  getReadinessMetrics,
  getSleepMetrics,
} from "./utils/healthMetricsUtils";
import {
  determinePerformanceLevel,
  getGradientForScore,
} from "../AthleteDashboard/formatters";
import { ActivityCard } from "../AthleteDashboard/ActivityCard";
import { ReadinessCard } from "../AthleteDashboard/ReadinessCard";
import { SleepCard } from "../AthleteDashboard/SleepCard";

dayjs.extend(utc);
dayjs.extend(timezone);

type UserProfileHealthMetricsProps = {
  userId: number;
};

export const UserProfileHealthMetrics: React.FC<
  UserProfileHealthMetricsProps
> = ({ userId }) => {
  const { data: activityHealthData, isLoading: activityHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "activity",
        userId,
      },
      {
        skip: !userId,
      }
    );

  const { data: sleepHealthData, isLoading: sleepHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "sleep",
        userId,
      },
      {
        skip: !userId,
      }
    );

  const { data: readinessHealthData, isLoading: readinessHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "readiness",
        userId,
      },
      {
        skip: !userId,
      }
    );
  const [sahhaAuthorised, setSahhaAuthorised] = useState(false);

  useEffect(() => {
    const checkSahhaAuth = async () => {
      setSahhaAuthorised(
        !!activityHealthData?.isAuthorised &&
          !!readinessHealthData?.isAuthorised &&
          !!sleepHealthData?.isAuthorised
      );
    };

    checkSahhaAuth();
  }, [activityHealthData, readinessHealthData, sleepHealthData]);

  const activityInsights = getActivityInsights(
    activityHealthData?.healthScores
  );
  const sleepInsights = getSleepMetrics(sleepHealthData?.healthScores);

  const readinessInsights = getReadinessMetrics(
    readinessHealthData?.healthScores
  );

  const isApiLoading =
    sleepHealthDataLoading ||
    readinessHealthDataLoading ||
    activityHealthDataLoading;

  const sleepDurationPerformance = determinePerformanceLevel(
    sleepInsights.sleepDuration?.value ?? 0,
    sleepInsights.sleepDuration?.goal ?? 0
  );

  const caloriesBurnedPerformance = determinePerformanceLevel(
    activityInsights.todayStats.activeCalories,
    activityInsights.todayStats.activeCaloriesGoal
  );

  const todayStepsPerformance = determinePerformanceLevel(
    activityInsights.todayStats?.steps ?? 0,
    activityInsights.todayStats?.goal ?? 0
  );
  const bestDayPerformance = determinePerformanceLevel(
    activityInsights.bestDay?.steps ?? 0,
    activityInsights.bestDay?.goal ?? 0
  );
  const worstDayPerformance = determinePerformanceLevel(
    activityInsights.worstDay?.steps ?? 0,
    activityInsights.worstDay?.goal ?? 0
  );
  const averageDailyStepsPerformance = determinePerformanceLevel(
    activityInsights.averageDailySteps ?? 0,
    activityInsights.trendData?.[0]?.goal ?? 0
  );
  const stepGoalAchievementPerformance = determinePerformanceLevel(
    activityInsights.goalAchievementDays ?? 0,
    activityInsights.trendData?.length ?? 0
  );

  const overallReadinessScorePerformance = determinePerformanceLevel(
    readinessInsights.overallScore,
    1
  );

  const heartRateVariabilityPerformance = determinePerformanceLevel(
    readinessInsights.heartRateVariability?.value ?? 0,
    readinessInsights.heartRateVariability?.goal ?? 0
  );

  const mentalRecoveryPerformance = determinePerformanceLevel(
    readinessInsights.mentalRecovery?.value ?? 0,
    readinessInsights.mentalRecovery?.goal ?? 0
  );

  const physicalRecoveryPerformance = determinePerformanceLevel(
    readinessInsights.physicalRecovery?.value ?? 0,
    readinessInsights.physicalRecovery?.goal ?? 0
  );

  const overallSleepScorePerformance = determinePerformanceLevel(
    sleepInsights.overallScore,
    1
  );
  const sleepRegularityPerformance = determinePerformanceLevel(
    sleepInsights.sleepRegularity?.score ?? 0,
    1
  );
  const sleepContinuityPerformance = determinePerformanceLevel(
    sleepInsights.sleepContinuity?.score ?? 0,
    10
  );

  const getTimeUntilNextRefresh = () => {
    const now = dayjs();
    const refreshTimes = [
      dayjs().utc().startOf("day"),
      dayjs().utc().startOf("day").add(6, "hour"),
      dayjs().utc().startOf("day").add(12, "hour"),
      dayjs().utc().startOf("day").add(18, "hour"),
    ];

    // Find the next refresh time
    const nextRefresh =
      refreshTimes.find((time) => time.isAfter(now)) ||
      refreshTimes[0].add(1, "day");

    // Calculate the difference
    const diff = nextRefresh.diff(now);

    // Format the difference
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };

  return (
    <Box>
      {isApiLoading && (
        <>
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
        </>
      )}
      {sahhaAuthorised && (
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            Data is updated every 6 hours. The next update will occur in{" "}
            {getTimeUntilNextRefresh()}.
          </Typography>
        </Grid>
      )}
      {sahhaAuthorised && !isApiLoading && (
        <>
          <Box sx={{ padding: "16px 0px" }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", mt: 1 }}
            >
              Activity summary over the last {activityInsights.trendData.length}{" "}
              days,
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ActivityCard
                title="Today"
                value={activityInsights.todayStats?.steps}
                target={activityInsights.todayStats?.goal}
                unit="steps"
                gradient={getGradientForScore(todayStepsPerformance)}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Calories burned today"
                value={activityInsights?.todayStats?.activeCalories}
                target={activityInsights?.todayStats?.activeCaloriesGoal}
                unit="kcal"
                gradient={getGradientForScore(caloriesBurnedPerformance)}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Best day"
                value={activityInsights.bestDay?.steps}
                target={activityInsights.bestDay?.goal}
                unit="steps"
                gradient={getGradientForScore(bestDayPerformance)}
              />
            </Grid>

            <Grid item xs={6}>
              <ActivityCard
                title="Worst day"
                value={activityInsights.worstDay?.steps}
                target={activityInsights.worstDay?.goal}
                unit="steps"
                gradient={getGradientForScore(worstDayPerformance)}
              />
            </Grid>

            <Grid item xs={6}>
              <ActivityCard
                title="Daily Average"
                value={Math.round(activityInsights.averageDailySteps ?? 0)}
                target={activityInsights.trendData?.[0]?.goal ?? 0}
                unit="steps"
                gradient={getGradientForScore(averageDailyStepsPerformance)}
              />
            </Grid>

            <Grid item xs={6}>
              <ActivityCard
                title="Achieved Step Goal"
                value={activityInsights?.goalAchievementDays ?? 0}
                target={activityInsights?.trendData?.length ?? 0}
                unit="days"
                gradient={getGradientForScore(stepGoalAchievementPerformance)}
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: "16px 0px" }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", mt: 1 }}
            >
              Daily sleep summary:
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SleepCard
                sleepDuration={sleepInsights?.sleepDuration?.value ?? 0}
                sleepDurationGoal={sleepInsights?.sleepDuration?.goal ?? 0}
                gradient={getGradientForScore(sleepDurationPerformance)}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Overall Sleep Score"
                value={sleepInsights.overallScore}
                target={1}
                gradient={getGradientForScore(overallSleepScorePerformance)}
                overrideText={sleepInsights.overallState}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Sleep Regularity"
                value={sleepInsights.sleepRegularity?.value ?? 0}
                target={sleepInsights.sleepRegularity?.goal ?? 0}
                gradient={getGradientForScore(sleepRegularityPerformance)}
                overrideText={sleepInsights.sleepRegularity?.state ?? ""}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Sleep Continuity"
                value={sleepInsights.sleepContinuity?.value ?? 0}
                target={sleepInsights.sleepContinuity?.goal ?? 0}
                gradient={getGradientForScore(sleepContinuityPerformance)}
                overrideText={sleepInsights.sleepContinuity?.state ?? ""}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Sleep Debt"
                value={sleepInsights.sleepDebt?.value ?? 0}
                target={sleepInsights.sleepDebt?.goal ?? 0}
                unit={sleepInsights.sleepDebt?.unit ?? ""}
                gradient={getGradientForScore("poor")}
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: "16px 0px" }}>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", mt: 1 }}
            >
              Daily readiness summary:
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ActivityCard
                title="Overall Readiness Score"
                value={readinessInsights.overallScore}
                target={1}
                unit=""
                gradient={getGradientForScore(overallReadinessScorePerformance)}
                overrideText={readinessInsights.overallState}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadinessCard
                averageRestingHeartRate={
                  readinessInsights.averageRestingHeartRate ?? 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="HR Variability"
                value={readinessInsights.heartRateVariability?.value ?? 0}
                target={readinessInsights.heartRateVariability?.goal ?? 0}
                gradient={getGradientForScore(heartRateVariabilityPerformance)}
                overrideText={
                  readinessInsights.heartRateVariability?.state ?? ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Sleep Debt"
                value={readinessInsights.sleepDebt?.value ?? 0}
                target={readinessInsights.sleepDebt?.goal ?? 0}
                unit={readinessInsights.sleepDebt?.unit ?? ""}
                gradient={getGradientForScore("poor")}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Mental Recovery"
                value={readinessInsights.mentalRecovery?.value ?? 0}
                target={readinessInsights.mentalRecovery?.goal ?? 0}
                unit={readinessInsights.mentalRecovery?.unit ?? ""}
                gradient={getGradientForScore(mentalRecoveryPerformance)}
              />
            </Grid>
            <Grid item xs={6}>
              <ActivityCard
                title="Physical Recovery"
                value={readinessInsights.physicalRecovery?.value ?? 0}
                target={readinessInsights.physicalRecovery?.goal ?? 0}
                unit={readinessInsights.physicalRecovery?.unit ?? ""}
                gradient={getGradientForScore(physicalRecoveryPerformance)}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
