import React, { FC, useState } from "react";
import { Icon, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "../../../../shared/themes";
import { ClientWhoRequireANewProgram } from "../../../../shared/api";
import { IdenticonAvatar } from "../../../../shared/ui/IdenticonAvatar/IdenticonAvatar";
import { Routes } from "../../../../shared/routers";

interface YouScheduleItemProps {
  item: ClientWhoRequireANewProgram;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const YouScheduleItem: FC<YouScheduleItemProps> = ({
  item,
  getUserIdForWorkoutCreation,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    getUserIdForWorkoutCreation(item.id);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "48px",
          display: "flex",
          alignItem: "center",
          justifyContent: "space-between",
          mb: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <IdenticonAvatar
            seedValue={item.id.toString()}
            profileImageUrl={item.profileImageUrl}
          />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                mt: "8px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {item.firstName} {item.lastName}
            </Typography>
            {item.percentageCompleted !== null && (
              <Typography
                sx={{
                  mt: "4px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {item.percentageCompleted}% Completed
              </Typography>
            )}
          </Box>
        </Box>
        <Icon
          onClick={handleClick}
          color="primary"
          sx={{
            mt: "8px",
            cursor: "pointer",
          }}
        >
          control_point
        </Icon>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{
          mt: "10px",
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #EEEEEE",
          },
          "& .MuiList-root": { padding: "0px" },
        }}
      >
        <Link style={{ textDecoration: "none" }} to={`${Routes.programs.url}`}>
          <MenuItem
            onClick={handleCloseMenu}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              border: "1px solid #EEEEEE",
              color: `${Colors.blue[1300]}`,
            }}
          >
            {t("your-schedule.create-new-program")}
          </MenuItem>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={`${Routes.profile.url}/${item.id}`}
        >
          <MenuItem
            onClick={handleCloseMenu}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              border: "1px solid #EEEEEE",
              color: `${Colors.blue[1300]}`,
            }}
          >
            {t("your-schedule.view-profile")}
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export { YouScheduleItem };
