import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Typography,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import { useTypedDispatch } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { UserFeedbackSummary } from "shared/api";
import { trainingInsightsApi } from "shared/api/trainingInsights/trainingInsights";

interface ClientFeedbackSummaryProps {
  selectedUserId: number | undefined;
}

const ClientFeedbackSummary: FC<ClientFeedbackSummaryProps> = ({
  selectedUserId,
}) => {
  const { t } = useTranslation();

  const dispatch = useTypedDispatch();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  const [userFeedbackSummary, setUserFeedbackSummary] =
    useState<UserFeedbackSummary>();

  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const getLatestUserFeedbackSummary =
    trainingInsightsApi.endpoints.getLatestUserFeedbackSummary.initiate(
      selectedUserId!!
    );

  async function callGetLatestUserFeedbackSummary() {
    try {
      setNoDataAvailable(false);
      setErrorMessage("");
      const response = await dispatch(getLatestUserFeedbackSummary);
      setUserFeedbackSummary(response.data ?? undefined);

      if (!response.data) {
        setNoDataAvailable(true);
      }
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUserId !== null) {
      setIsLoading(true);
      setErrorMessage("");
    }
    if (selectedUserId) {
      callGetLatestUserFeedbackSummary();
    }
  }, [selectedUserId]);

  return (
    // eslint-disable-next-line
    <>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ height: "131px", width: "100%", mb: "12px" }}
        />
      ) : (
        // eslint-disable-next-line
        <>
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                width: "100%",
                mb: "12px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <CardHeader
                title={
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                      }}
                    >
                      Client feedback summary
                    </Typography>
                    {userFeedbackSummary && (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        Generated{" "}
                        {dayjs(userFeedbackSummary?.createdDateTime).format(
                          "DD MMM YYYY"
                        )}
                      </Typography>
                    )}
                  </>
                }
                sx={{
                  borderBottom: "1px solid #E5E5E5",
                  padding: "16px 20px",
                }}
              />
              <CardContent
                sx={{
                  padding: "0 20px",
                  mt: 1,
                }}
              >
                {userFeedbackSummary ? (
                  <Typography sx={sharedStyles.body.timeline.cardBody}>
                    {userFeedbackSummary?.summary}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    There is no feedback summary available for this client yet.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
};

export { ClientFeedbackSummary };
