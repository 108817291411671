export const Images = {
  Logo: require("../../assets/logo.png"),
  CurrentSession: require("../../assets/current-session.jpg"),
  RemainingSession: require("../../assets/remaining-session.jpg"),
  WorkoutProgram: require("../../assets/workout-program.jpg"),
};

export const WorkoutImages: { [key: string]: string } = {
  Dumbbells: require("../../assets/workoutImages/dumbbells.jpg"),
  Treadmills: require("../../assets/workoutImages/treadmills.jpg"),
  Ropes: require("../../assets/workoutImages/ropes.jpg"),
  Military: require("../../assets/workoutImages/military.jpg"),
  Cardio: require("../../assets/workoutImages/cardio.jpg"),
  Runner: require("../../assets/workoutImages/runner.jpg"),
};

export const getWorkoutImageForKey = (key: number): string => {
  const keys = Object.keys(WorkoutImages);
  const index = key % keys.length;
  return WorkoutImages[keys[index]];
};

export const getGraphicSvg = (key: string): string => {
  // eslint-disable-next-line import/no-dynamic-require
  return require(`../../assets/graphics/${key}.svg`);
};
