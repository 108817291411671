import { FC, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Booking,
  useJoinBookingAsAttendeeMutation,
  useRemoveAthleteFromBookingMutation,
} from "shared/api";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { ExpandLessOutlined } from "@mui/icons-material";

interface BookingCardProps {
  booking: Booking;
  onActionComplete: (message: string, isError?: boolean) => void;
  showLeaveButton?: boolean;
}

const BookingCard: FC<BookingCardProps> = ({
  booking,
  onActionComplete,
  showLeaveButton,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [joinBookingAsAttendee, { isLoading: isJoiningBooking }] =
    useJoinBookingAsAttendeeMutation();
  const [removeAthleteFromBooking, { isLoading: isLeavingBooking }] =
    useRemoveAthleteFromBookingMutation();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleJoinBooking = async () => {
    try {
      await joinBookingAsAttendee({ bookingId: booking.id }).unwrap();
      onActionComplete(`Successfully joined ${booking.name}.`);
    } catch (error) {
      onActionComplete(`Failed to join ${booking.name}.`, true);
    }
  };

  const handleLeaveBooking = async () => {
    try {
      await removeAthleteFromBooking({ bookingId: booking.id }).unwrap();
      onActionComplete(`Successfully left ${booking.name}.`);
    } catch (error) {
      onActionComplete(`Failed to leave ${booking.name}.`, true);
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={handleExpandClick}
      >
        {/* Booking Details */}
        <Box display="flex" alignItems="center">
          <Avatar sx={{ bgcolor: "#7e57c2", marginRight: 2 }}>
            {booking.name.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {booking.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {dayjs(booking.startDateTime).format("ddd, MMM D [at] h:mm A")} -{" "}
              {dayjs(booking.endDateTime).format("h:mm A")}
            </Typography>
          </Box>
        </Box>

        {/* Expand Icon */}
        <IconButton>
          {expanded ? <ExpandLessOutlined /> : <ExpandMoreIcon />}
        </IconButton>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2" paragraph>
            {booking.description}
          </Typography>
          {booking.coachAttendees && booking.coachAttendees.length > 0 && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                Coaches:{" "}
              </Typography>
              {booking.coachAttendees.map((coach) => (
                <IdenticonAvatar
                  key={coach.id}
                  seedValue={coach.id.toString()}
                  sizeValue={24}
                  profileImageUrl={coach.profileImageUrl}
                />
              ))}
            </Box>
          )}
          <Typography variant="body2" color="text.secondary" paragraph>
            Status:{" "}
            {booking.maxAttendees - (booking.athleteAttendees?.length || 0)}{" "}
            spots left
          </Typography>
          {showLeaveButton ? (
            <LoadingButton
              loading={isLeavingBooking}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ textTransform: "none" }}
              onClick={handleLeaveBooking}
            >
              Leave {booking.name}
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={isJoiningBooking}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ textTransform: "none" }}
              onClick={handleJoinBooking}
            >
              Join {booking.name}
            </LoadingButton>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export { BookingCard };
