/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  useAddGoalMutation,
  useGetGoalsForUserQuery,
} from "shared/api/goals/goals";
import { Goal } from "shared/api/goals/models";
import { UserProfileGoalTextEditor } from "./UserProfileGoalTextEditor";

dayjs.extend(utc);

type GoalModalProps = {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preSelectedUserId: number;
};

const GoalModal: FC<GoalModalProps> = ({
  isOpenModal,
  handleCloseModal,
  preSelectedUserId,
}) => {
  const { t } = useTranslation();

  // Fetch notes
  const {
    data: goalsData,
    isLoading,
    error,
    refetch,
  } = useGetGoalsForUserQuery(
    {
      count: 10, // Fetch all notes
      page: 1,
      userId: preSelectedUserId,
      orderByDescending: true,
    },
    {
      skip: preSelectedUserId == null || !isOpenModal,
    }
  );

  const [addGoal, { isLoading: isLoadingAdd }] = useAddGoalMutation();

  const [selectedGoalId, setSelectedGoalId] = useState<number | null>();

  const handleSelectGoal = (goalId: number) => {
    if (selectedGoalId === goalId) {
      return;
    }
    setSelectedGoalId((prevSelectedId) =>
      prevSelectedId === goalId ? null : goalId
    );
  };

  const handleNewGoal = async () => {
    setSelectedGoalId(null);
    // Optionally, open the editor in create mode

    const newGoal = await addGoal({
      title: `New Goal`,
      dateTime: dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      userId: preSelectedUserId,
    }).unwrap();

    if (newGoal) {
      const updatedGoals = await refetch().unwrap();
      const createdGoal = updatedGoals.find((n) => n.id === newGoal.id);
      setSelectedGoalId(createdGoal?.id || newGoal?.id);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "70vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          outline: "none",
        }}
      >
        {/* Side Menu */}
        <Box
          sx={{
            width: "30%",
            borderRight: "1px solid #ccc",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{t("Goals")}</Typography>
            <Button
              variant="text"
              onClick={handleNewGoal}
              startIcon={<AddIcon />}
            >
              New Goal
            </Button>
          </Box>
          <Divider />
          {isLoading ? (
            <Typography sx={{ p: 2 }}>{t("Loading...")}</Typography>
          ) : error ? (
            <Typography sx={{ p: 2 }}>{t("Error loading goals.")}</Typography>
          ) : goalsData && goalsData.length > 0 ? (
            <List>
              {goalsData.map((goal: Goal) => (
                <ListItem
                  button
                  key={goal.id}
                  selected={goal.id === selectedGoalId}
                  onClick={() => handleSelectGoal(goal?.id)}
                >
                  <ListItemText
                    primary={goal.title}
                    secondary={dayjs(goal.dateTime).format("D MMM YYYY")}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ p: 2 }}>
              Add a new goal to get started.
            </Typography>
          )}
        </Box>

        {/* Main Content Area */}
        <Box
          sx={{
            width: "70%",
            p: 2,
            overflowY: "auto",
          }}
        >
          {selectedGoalId ? (
            // Display the selected note using UserNoteTextEditor
            <UserProfileGoalTextEditor
              key={selectedGoalId} // Added key prop
              userGoalId={selectedGoalId}
            />
          ) : (
            <Typography variant="body1">
              {t("Select a goal to view details or create a new one.")}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default GoalModal;
