import { FC } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { statusItemProps } from "../constants/constants";

interface StatusItemProps {
  status: string;
}

const StatusItem: FC<StatusItemProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "auto",
        height: "20px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "140%,",
        textTransform: "uppercase",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2.5px 8px",
        color: statusItemProps[status].color,
        background: statusItemProps[status].background,
      }}
    >
      <Box
        sx={{
          width: "6px",
          height: "6px",
          background: statusItemProps[status].color,
          borderRadius: "50px",
          mr: "8px",
        }}
      />
      {t(statusItemProps[status].title)}
    </Box>
  );
};

export { StatusItem };
