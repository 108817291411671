import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  BookingGroup,
  useUpdateBookingGroupMutation,
  useDeleteBookingGroupMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

interface UpdateEventTypeModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  bookingGroup: BookingGroup | null;
}

const UpdateEventTypeModal: FC<UpdateEventTypeModalProps> = ({
  isOpenModal,
  handleCloseModal,
  bookingGroup,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPricingInfo, setShowPricingInfo] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [updateBookingGroup, { isLoading }] = useUpdateBookingGroupMutation();

  const [deleteBookingGroup, { isLoading: isDeletingBookingGroup }] =
    useDeleteBookingGroupMutation();

  const handleDeleteBookingGroup = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      await deleteBookingGroup(bookingGroup?.id ?? 0).unwrap();
      setSuccessMessage("Booking group deleted successfully");
      handleCloseModal(); // Close the modal after deletion
    } catch (error) {
      setErrorMessage("An error occurred while deleting the booking group");
    } finally {
      setOpenConfirmDelete(false); // Close the confirmation dialog
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 2500);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: bookingGroup?.name || "",
      description: bookingGroup?.description || "",
      durationInMinutes: bookingGroup?.durationInMinutes || 0,
      maxAttendees: bookingGroup?.maxAttendees || 0,
      maxRecurringBookingsPerAttendee:
        bookingGroup?.maxRecurringBookingsPerAttendee || 0,
      productId: bookingGroup?.productId || "",
      priceId: bookingGroup?.priceId || "",
      paymentRequired: bookingGroup?.paymentRequired || false,
      imageUrl: bookingGroup?.imageUrl || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      setErrorMessage("");
      setSuccessMessage("");

      try {
        await updateBookingGroup({ ...bookingGroup, ...values }).unwrap();
        setSuccessMessage("Booking group updated successfully");
      } catch (error) {
        setErrorMessage("An error occurred while updating the booking group");
      } finally {
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 2500);
      }
    },
  });

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box sx={sharedStyles.containers.modal}>
          <Box
            sx={{
              ...sharedStyles.containers.modalContent,
              width: "50%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {bookingGroup?.name}
              </Typography>
              <Icon
                onClick={handleCloseModal}
                sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
              >
                close_icon
              </Icon>
            </Box>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                <Box sx={{ flex: "1 1 100%" }}>
                  <InputField
                    type="text"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.name}
                    multiline
                    rows={4}
                    helperText={formik.errors.name ? formik.errors.name : ""}
                  />
                </Box>
                <Box sx={{ flex: "1 1 100%" }}>
                  <InputField
                    type="text"
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    placeholder="Description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.description}
                    multiline
                    rows={4}
                    helperText={
                      formik.errors.description ? formik.errors.description : ""
                    }
                  />
                </Box>
                <Box sx={{ flex: "1 1 30%" }}>
                  <InputField
                    type="number"
                    name="durationInMinutes"
                    label="Duration (minutes)"
                    value={formik.values.durationInMinutes}
                    placeholder="Duration in minutes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.durationInMinutes}
                    helperText={
                      formik.errors.durationInMinutes
                        ? formik.errors.durationInMinutes
                        : ""
                    }
                  />
                </Box>
                <Box sx={{ flex: "1 1 30%" }}>
                  <InputField
                    type="number"
                    name="maxAttendees"
                    label="Capacity"
                    value={formik.values.maxAttendees}
                    placeholder="Capacity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.maxAttendees}
                    helperText={
                      formik.errors.maxAttendees
                        ? formik.errors.maxAttendees
                        : ""
                    }
                  />
                </Box>
                <Box sx={{ flex: "1 1 30%" }}>
                  <InputField
                    type="number"
                    name="maxRecurringBookingsPerAttendee"
                    label="Max recurring bookings per athlete"
                    placeholder="Max recurring bookings per athlete"
                    value={formik.values.maxRecurringBookingsPerAttendee}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.maxRecurringBookingsPerAttendee}
                    helperText={
                      formik.errors.maxRecurringBookingsPerAttendee
                        ? formik.errors.maxRecurringBookingsPerAttendee
                        : ""
                    }
                  />
                </Box>
                <Box sx={{ flex: "1 1 30%" }}>
                  <InputField
                    type="text"
                    name="imageUrl"
                    label="Image URL"
                    value={formik.values.imageUrl}
                    placeholder="Image URL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.imageUrl}
                    helperText={
                      formik.errors.imageUrl ? formik.errors.imageUrl : ""
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 2,
                  marginTop: "10px",
                }}
              >
                <label htmlFor="recurring" style={{ cursor: "pointer" }}>
                  <input
                    id="recurring"
                    type="checkbox"
                    checked={showPricingInfo}
                    onChange={() => setShowPricingInfo(!showPricingInfo)}
                    style={{ marginLeft: "5px" }}
                  />
                  Pricing info
                </label>
              </Box>

              {showPricingInfo && (
                <Box
                  sx={{
                    mt: 2,
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 2,
                  }}
                >
                  <InputField
                    type="text"
                    name="productId"
                    label="Product ID"
                    value={formik.values.productId}
                    placeholder="Product ID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.productId}
                    helperText={
                      formik.errors.productId ? formik.errors.productId : ""
                    }
                  />
                  <InputField
                    type="text"
                    name="priceId"
                    label="Price ID"
                    value={formik.values.priceId}
                    placeholder="Price ID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.priceId}
                    helperText={
                      formik.errors.priceId ? formik.errors.priceId : ""
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      gridColumn: "span 2",
                    }}
                  >
                    <label
                      htmlFor="paymentRequired"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        id="paymentRequired"
                        type="checkbox"
                        checked={formik.values.paymentRequired}
                        onChange={formik.handleChange}
                        style={{ marginLeft: "5px" }}
                      />
                      Payment required
                    </label>
                  </Box>
                </Box>
              )}

              {/* Update Button */}
              <PrimaryButton
                size="large"
                value={`Update ${bookingGroup?.name ?? ""}`}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                fullWidth
                type="submit"
              />

              {/* Delete Button */}
              <LoadingButton
                loading={isDeletingBookingGroup}
                size="large"
                disabled={isDeletingBookingGroup}
                variant="outlined"
                fullWidth
                onClick={() => setOpenConfirmDelete(true)}
                sx={{ mt: 2, textTransform: "none" }}
              >
                {`Delete ${bookingGroup?.name ?? ""}`}
              </LoadingButton>

              {/* Success and Error Messages */}
              {errorMessage && (
                <Alert
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                  variant="outlined"
                  severity="error"
                >
                  {errorMessage}
                </Alert>
              )}

              {successMessage && (
                <Alert
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                  variant="outlined"
                  severity="success"
                >
                  {successMessage}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      >
        <DialogTitle>Delete {bookingGroup?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete the booking group ${bookingGroup?.name}? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => setOpenConfirmDelete(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            onClick={handleDeleteBookingGroup}
            color="error"
            disabled={isDeletingBookingGroup}
          >
            {isDeletingBookingGroup ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { UpdateEventTypeModal };
