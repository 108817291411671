/* eslint-disable no-nested-ternary */
import {
  Box,
  Collapse,
  Typography,
  Grid,
  Button,
  Skeleton,
  IconButton,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import {
  useGetWorkoutsQuery,
  useGetHealthScoresForUserQuery,
  useGetProfileTokenForUserQuery,
  useActivateProfileTokenMutation,
  UserRole,
} from "shared/api";
import { getGraphicSvg, Images, WorkoutImages } from "shared/themes";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import PersonIcon from "@mui/icons-material/Person";
import { Routes } from "shared/routers";
import { useNavigate } from "react-router-dom";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import SahhaService from "SahhaService";
import { LoadingButton } from "@mui/lab";
import { Device } from "@capacitor/device";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  getActivityInsights,
  getSleepMetrics,
  getReadinessMetrics,
} from "pages/Dashboard/AdminDashboard/utils/healthMetricsUtils";
import { ActionButton, SessionCard } from "./ui";
import { ActivityCard } from "./ActivityCard";
import { StepsDetailCard } from "./StepsDetailCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { SleepDetailsCard } from "./SleepDetailsCard";
import { ReadinessDetailsCard } from "./ReadinessDetailsCard";
import { SleepCard } from "./SleepCard";
import { ReadinessCard } from "./ReadinessCard";

dayjs.extend(utc);
dayjs.extend(timezone);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MOBILE_APP_MESSAGE =
  "Health data cannot be retrieved. Authorisation is required to access this feature.";
const WEB_APP_MESSAGE =
  "To access health data insights, this feature must be enabled through the mobile app.";

const AthleteDashboard: FC = () => {
  const user = useTypedSelector(userSelectors.all);
  const { data, isLoading } = useGetWorkoutsQuery();
  const [isMobileApp, setIsMobileApp] = useState(false);

  const { data: activityHealthData, isLoading: activityHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "activity",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
      }
    );
  const { data: readinessHealthData, isLoading: readinessHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "readiness",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
      }
    );

  const { data: sleepHealthData, isLoading: sleepHealthDataLoading } =
    useGetHealthScoresForUserQuery(
      {
        type: "sleep",
        userId: user?.id!,
      },
      {
        skip: !user?.id,
      }
    );

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);
      return;
    }
    setIsMobileApp(false);
  };

  const { data: profileData, isLoading: profileDataLoading } =
    useGetProfileTokenForUserQuery(
      {},
      { skip: !user?.id || user?.userRole !== UserRole.Athlete }
    );
  const [activateProfileToken, { isLoading: activateProfileTokenLoading }] =
    useActivateProfileTokenMutation();

  const activityInsights = getActivityInsights(
    activityHealthData?.healthScores
  );
  const sleepInsights = getSleepMetrics(sleepHealthData?.healthScores);
  const readinessInsights = getReadinessMetrics(
    readinessHealthData?.healthScores
  );

  const currentWorkout = data?.currentWorkout;
  const nextWorkout = data?.remainingWorkouts?.[0];
  const [activeIndex, setActiveIndex] = useState<number | null>(() => {
    if (currentWorkout) return 0;
    if (nextWorkout) return 1;
    return null;
  });

  const [showStepsDetail, setShowStepsDetail] = useState(false);
  const [showSleepDetail, setShowSleepDetail] = useState(false);
  const [showReadinessDetail, setShowReadinessDetail] = useState(false);

  const isDashboardNotActive =
    showStepsDetail || showSleepDetail || showReadinessDetail;

  const todayStepsPerformance = determinePerformanceLevel(
    activityInsights.todayStats.steps,
    activityInsights.todayStats.goal
  );

  const sleepDurationPerformance = determinePerformanceLevel(
    sleepInsights.sleepDuration?.value ?? 0,
    sleepInsights.sleepDuration?.goal ?? 0
  );

  const caloriesBurnedPerformance = determinePerformanceLevel(
    activityInsights.todayStats.activeCalories,
    activityInsights.todayStats.activeCaloriesGoal
  );

  useEffect(() => {
    if (currentWorkout) setActiveIndex(0);
    else if (nextWorkout) setActiveIndex(1);
    else setActiveIndex(null);
  }, [currentWorkout, nextWorkout]);

  const getSahhaAuthenticationStatus = async () => {
    const sahhaService = SahhaService.getInstance();
    const authStatus = await sahhaService.getSahhaAuthenticationStatus();
    return authStatus;
  };

  const [sahhaAuthorised, setSahhaAuthorised] = useState(false);

  useEffect(() => {
    const checkSahhaAuth = async () => {
      const authStatus = await getSahhaAuthenticationStatus();
      setSahhaAuthorised(
        !!activityHealthData?.isAuthorised &&
          !!readinessHealthData?.isAuthorised &&
          !!sleepHealthData?.isAuthorised &&
          !!authStatus
      );
    };

    checkSahhaAuth();
    checkDeviceIsMobileApp();
  }, [activityHealthData, readinessHealthData, sleepHealthData]);

  const handleShowActivityDetails = () => {
    if (!sahhaAuthorised) {
      return;
    }
    setShowStepsDetail(true);
  };

  const handleShowSleepDetails = () => {
    if (!sahhaAuthorised) {
      return;
    }
    setShowSleepDetail(true);
  };

  const handleShowReadinessDetails = () => {
    if (!sahhaAuthorised) {
      return;
    }
    setShowReadinessDetail(true);
  };

  const isApiLoading =
    sleepHealthDataLoading ||
    readinessHealthDataLoading ||
    activityHealthDataLoading;

  const profileUrl = `${Routes.profile.url}/${user.id}`;
  const navigate = useNavigate();

  const handleNavigateToProfileUrl = () => {
    navigate(profileUrl);
  };

  const handleSahhaLogin = async () => {
    try {
      // First activate the profile token
      await activateProfileToken().unwrap();
    } catch (error) {
      console.error("Failed to activate Sahha login:", error);
    }
  };

  useEffect(() => {
    // Then initialize Sahha with the tokens from the activated profile
    if (profileData?.profileToken && profileData?.refreshToken) {
      SahhaService.getInstance().initSahha(
        profileData.profileToken,
        profileData.refreshToken
      );
    }
  }, [profileData]);

  const getTimeUntilNextRefresh = () => {
    const now = dayjs();
    const refreshTimes = [
      dayjs().utc().startOf("day"),
      dayjs().utc().startOf("day").add(6, "hour"),
      dayjs().utc().startOf("day").add(12, "hour"),
      dayjs().utc().startOf("day").add(18, "hour"),
    ];

    // Find the next refresh time
    const nextRefresh =
      refreshTimes.find((time) => time.isAfter(now)) ||
      refreshTimes[0].add(1, "day");

    // Calculate the difference
    const diff = nextRefresh.diff(now);

    // Format the difference
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };

  if (user?.userRole !== UserRole.Athlete) {
    return <Skeleton variant="rectangular" height={118} />;
  }

  const isAuthoriseButtonDisabled = !isMobileApp || activateProfileTokenLoading;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        paddingBottom: "60px",
        pt: 3,
      }}
    >
      {!isDashboardNotActive && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "16px 24px",
            marginBottom: 2,
          }}
        >
          {user?.firstName && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {user?.firstName
                    ? `Hi ${user?.firstName}`
                    : "Glad you're here"}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.secondary", mt: 1 }}
                >
                  Here&apos;s your daily summary,
                </Typography>
              </Box>
            </Box>
          )}
          <IconButton
            aria-label="profile"
            onClick={handleNavigateToProfileUrl}
            sx={{
              flexShrink: 0,
              padding: 0,
            }}
          >
            {user?.profileImageUrl ? (
              <IdenticonAvatar
                sizeValue={40}
                seedValue={user.id?.toString()}
                profileImageUrl={user?.profileImageUrl || ""}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: "background.paper",
                  borderRadius: "60%",
                  padding: 1,
                }}
              >
                <PersonIcon fontSize="large" />
              </Box>
            )}
          </IconButton>
        </Box>
      )}

      {isDashboardNotActive && (
        <Button
          sx={{
            textTransform: "none",
            textDecoration: "none",
            marginRight: "auto",
            marginLeft: "24px",
          }}
          color="primary"
          variant="text"
          type="button"
          onClick={() => {
            setShowStepsDetail(false);
            setShowSleepDetail(false);
            setShowReadinessDetail(false);
          }}
        >
          Back to home
        </Button>
      )}
      {!showStepsDetail && !showSleepDetail && !showReadinessDetail && (
        <>
          {sahhaAuthorised && !isApiLoading && (
            <>
              <Grid container spacing={2} sx={{ padding: "0 24px" }}>
                <Grid item xs={6}>
                  <Box onClick={handleShowActivityDetails}>
                    <ActivityCard
                      title="Steps"
                      value={activityInsights?.todayStats?.steps ?? 0}
                      target={activityInsights?.todayStats?.goal ?? 0}
                      gradient={getGradientForScore(todayStepsPerformance)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box onClick={handleShowActivityDetails}>
                    <ActivityCard
                      title="Calories burned"
                      value={activityInsights?.todayStats?.activeCalories ?? 0}
                      target={
                        activityInsights?.todayStats?.activeCaloriesGoal ?? 0
                      }
                      unit="kcal"
                      gradient={getGradientForScore(caloriesBurnedPerformance)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ padding: "16px 24px 0px 24px" }}
              >
                {!sleepHealthDataLoading &&
                  (sleepHealthData?.healthScores?.length ?? 0) > 0 && (
                    <Grid item xs={6}>
                      <Box onClick={handleShowSleepDetails}>
                        <SleepCard
                          sleepDuration={
                            sleepInsights?.sleepDuration?.value ?? 0
                          }
                          sleepDurationGoal={
                            sleepInsights?.sleepDuration?.goal ?? 0
                          }
                          gradient={getGradientForScore(
                            sleepDurationPerformance
                          )}
                        />
                      </Box>
                    </Grid>
                  )}
                {!readinessHealthDataLoading &&
                  (readinessHealthData?.healthScores?.length ?? 0) > 0 && (
                    <Grid item xs={6}>
                      <Box onClick={handleShowReadinessDetails}>
                        <ReadinessCard
                          averageRestingHeartRate={
                            readinessInsights.averageRestingHeartRate ?? 0
                          }
                        />
                      </Box>
                    </Grid>
                  )}

                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    Data is updated every 6 hours. The next update will occur in{" "}
                    {getTimeUntilNextRefresh()}.
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {isApiLoading && (
            <Grid item xs={12}>
              <Skeleton variant="rectangular" height={118} />
            </Grid>
          )}

          {!sahhaAuthorised && !isApiLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.6)",
                mb: 1,
              }}
            >
              <Box
                component="img"
                src={getGraphicSvg("thinking")}
                alt="Thinking"
                sx={{ height: "110px", mb: 4, mt: 4 }}
              />
              <Typography variant="body2" sx={{ pl: 2, pr: 2 }}>
                {isMobileApp ? MOBILE_APP_MESSAGE : WEB_APP_MESSAGE}
              </Typography>
              <LoadingButton
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  // left align
                  justifySelf: "flex-start",
                  mt: 2,
                }}
                color="primary"
                variant="contained"
                type="button"
                onClick={handleSahhaLogin}
                disabled={isAuthoriseButtonDisabled}
                loading={activateProfileTokenLoading}
              >
                Authorise
              </LoadingButton>
            </Box>
          )}
          <Box sx={{ padding: "16px 24px" }}>
            {isLoading ? (
              <Box />
            ) : !currentWorkout && !nextWorkout ? (
              <Box sx={{ mt: 2, textAlign: "center" }}>
                No workout program selected. Contact your coach to be assigned
                one.
              </Box>
            ) : (
              <Box sx={{ mt: 2 }}>
                {currentWorkout && (
                  <>
                    <ActionButton
                      isActive={activeIndex === 0}
                      title="Current workout"
                      onPress={() =>
                        setActiveIndex(activeIndex === 0 ? null : 0)
                      }
                    />
                    <Collapse
                      in={activeIndex === 0}
                      timeout="auto"
                      unmountOnExit
                    >
                      <SessionCard
                        item={currentWorkout}
                        id={currentWorkout.id}
                        image={WorkoutImages.Dumbbells}
                        dayNumber={currentWorkout.dayNumber}
                        weekNumber={currentWorkout.weekNumber}
                        workoutStatus={currentWorkout.workoutStatus}
                      />
                    </Collapse>
                  </>
                )}

                {nextWorkout && (
                  <>
                    <ActionButton
                      isActive={activeIndex === 1}
                      title="Next workout"
                      onPress={() =>
                        setActiveIndex(activeIndex === 1 ? null : 1)
                      }
                    />
                    <Collapse
                      in={activeIndex === 1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <SessionCard
                        item={nextWorkout}
                        id={nextWorkout.id}
                        image={Images.CurrentSession}
                        dayNumber={nextWorkout.dayNumber}
                        weekNumber={nextWorkout.weekNumber}
                        workoutStatus={nextWorkout.workoutStatus}
                      />
                    </Collapse>
                  </>
                )}
              </Box>
            )}
          </Box>
        </>
      )}

      {showStepsDetail && <StepsDetailCard insights={activityInsights} />}
      {showSleepDetail && <SleepDetailsCard sleepInsights={sleepInsights} />}
      {showReadinessDetail && (
        <ReadinessDetailsCard readinessInsights={readinessInsights} />
      )}
    </Box>
  );
};

export { AthleteDashboard };
