import { FC, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Drawer,
  Icon,
  IconButton,
  Skeleton,
} from "@mui/material";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import {
  CreateTimeLineItemParams,
  TimelineItem,
  TimelineItemType,
  useCreateTimelineItemMutation,
  useUploadMediaMutation,
} from "shared/api";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { InputField } from "components/Form/InputField";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { TimelineViewItemIcon } from "../TimelineViewIcon";
import { TimelineViewReply } from "../TimelineViewReply";
import { TimelineViewInputSchema } from "../AdminTimeline/types";
import { AthleteDropzone } from "./AthleteDropzone";

interface AthleteTimelineViewInputProps {
  timelineId: number;
  onAddItem: () => void;
  isReply?: boolean;
  replyTo?: TimelineItem;
}

const AthleteTimelineViewInput: FC<AthleteTimelineViewInputProps> = ({
  timelineId,
  onAddItem,
  isReply,
  replyTo,
}) => {
  const { t } = useTranslation();
  const currentUserId = useTypedSelector(userSelectors.userId);
  const currentUserName = useTypedSelector(userSelectors.fullName);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [createTimelineItem, { isLoading }] = useCreateTimelineItemMutation();
  const [uploadMedia, { isLoading: isFileUploading }] =
    useUploadMediaMutation();
  const parentIdToUse = replyTo?.timelineItemParentId
    ? replyTo?.timelineItemParentId
    : replyTo?.id;
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      timelineItemType: TimelineItemType.Message,
      title: `${currentUserName} left a message`,
      content: "",
      userId: currentUserId ?? 0,
      timelineId,
      timelineItemParentId: parentIdToUse ?? undefined,
      file: undefined,
    },
    validationSchema: TimelineViewInputSchema,
    onSubmit: (params) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSubmit(params);
    },
  });

  const onSubmit = async (params: CreateTimeLineItemParams) => {
    setErrorMessage("");
    try {
      const { file: uploadFile, ...rest } = params;
      if (uploadFile && !isReply) {
        await uploadMedia({
          uploadFile,
          timelineId: rest?.timelineId,
        }).unwrap();
      } else {
        await createTimelineItem(rest).unwrap();
      }
      setSuccessMessage(t("timeline.item-success-added"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setOpen(true);
      resetForm();
      setShowDropzone(false);
      onAddItem();
    }
  };

  const handleImageClick = () => {
    setFieldValue("file", undefined);
    setShowDropzone(!showDropzone);
  };

  const uploadFile = (file: File) => {
    setFieldValue("file", file);
  };

  if (!currentUserName || !currentUserId)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  const isButtonDisabled =
    isLoading || isFileUploading || (!values.content && !values.file);

  const isButtonLoading = isLoading || isFileUploading;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
        padding: "10px",
        borderTop: `1px solid ${Colors.gray[300]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={sharedStyles.containers.timelineCard.clientInput}>
          {!isReply && (
            <IconButton
              aria-label="add media"
              size="small"
              onClick={(e) => {
                handleImageClick();
                e.stopPropagation();
              }}
            >
              <OutlinedIcon
                iconName="image"
                style={{
                  color: `${showDropzone ? Colors.blue[1200] : "black"}`,
                }}
              />
            </IconButton>
          )}
        </Box>
        <Card
          sx={{
            width: "100%",
            position: "relative",
            border: "1px solid #EEEEEE",
            borderRadius: "8px",
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            {isReply && (
              <TimelineViewReply
                title={replyTo?.title}
                content={replyTo?.content}
              />
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "70px",
                border: "1px solid #EEEEEE",
                borderRadius: "8px",
                background: `${Colors.gray[200]}`,
              }}
            >
              {showDropzone && !isReply ? (
                <AthleteDropzone
                  onDrop={uploadFile}
                  onSubmit={handleSubmit}
                  disabled={isButtonDisabled}
                  loading={isButtonLoading}
                />
              ) : (
                <>
                  <InputField
                    type="text"
                    fullWidth
                    value={values.content}
                    onBlur={handleBlur("content")}
                    onChange={handleChange("content")}
                    error={!!errors.content}
                    placeholder={t("timeline.input-placeholder")}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                  />
                  <LoadingButton
                    type="submit"
                    variant="text"
                    onClick={() => handleSubmit()}
                    size="small"
                    loading={isButtonLoading}
                    disabled={isButtonDisabled}
                  >
                    <Icon>send</Icon>
                  </LoadingButton>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
      {errorMessage && (
        <SnackbarAlert
          isOpen={open}
          onClose={handleClose}
          severity="error"
          message={errorMessage}
        />
      )}
      {successMessage && (
        <SnackbarAlert
          isOpen={open}
          onClose={handleClose}
          severity="success"
          message={successMessage}
        />
      )}
    </Box>
  );
};

export { AthleteTimelineViewInput };
