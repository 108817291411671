// UserProfileGoalTextEditor.tsx

import { FC, useCallback, useRef, useState, useEffect } from "react";
import hash from "hash.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Box } from "@mui/system";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  CircularProgress,
  Skeleton,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Goal,
  UpdateGoalParams,
  useGetGoalByIdQuery,
  useUpdateGoalMutation,
} from "shared/api"; // Updated imports
import { MenuBar } from "./UserNotesMenuBar";
import { UserGoalFormValues } from "./UserGoalFormValues";

type UserProfileGoalTextEditorProps = {
  userGoalId: number; // Updated prop name
};

const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const UserProfileGoalTextEditor: FC<UserProfileGoalTextEditorProps> = ({
  userGoalId, // Updated prop
}) => {
  dayjs.extend(utc);

  // Fetch the goal data using the provided ID
  const { data: goal, isLoading, error } = useGetGoalByIdQuery(userGoalId); // Updated variable names

  // Initialize local states
  const [goalDate, setGoalDate] = useState<string>(""); // Updated state names
  const [completedDate, setCompletedDate] = useState<string>(""); // Updated state names
  const [titleToChange, setTitleToChange] = useState<string>("");
  const [debouncedTitle, setDebouncedTitle] = useState<string>("");

  const handleDateChange = (date: string | null) => {
    setGoalDate(dayjs.utc(date).format("YYYY-MM-DDTHH:mm:ss[Z]")); // Updated state
  };

  const handleCompletedDateChange = (date: string | null) => {
    setCompletedDate(dayjs.utc(date).format("YYYY-MM-DDTHH:mm:ss[Z]")); // Updated
  };

  const handleTitleChange = (title: string) => {
    setTitleToChange(title);
  };

  const previousContentHash = useRef<string>("");

  const generateHash = (content: string) => {
    return hash.sha256().update(content).digest("hex");
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Initialize the editor only after goal data is available
  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: goal?.description || "", // Updated variable
  });

  const [updateGoalMutation] = useUpdateGoalMutation(); // Updated mutation hook

  const onSubmit = async (editorInstance: any) => {
    if (!goal || !goal.id) return; // Updated variable

    setIsUpdating(true);
    setIsSuccess(false);
    try {
      const updatedGoal: UpdateGoalParams = {
        // Updated type
        id: goal.id,
        dateTime: goalDate || goal.dateTime,
        completedAt: completedDate || goal.completedAt,
        title: debouncedTitle || goal.title,
        description: editorInstance.getHTML(),
      };

      await updateGoalMutation(updatedGoal).unwrap(); // Updated mutation
      setIsSuccess(true);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setIsUpdating(false);
      setTimeout(() => setIsSuccess(false), 2000);
      setTimeout(() => setErrorMessage(""), 2000);
    }
  };

  // Debounced function to handle editor updates
  const handleEditorUpdate = useCallback(
    debounce(() => {
      if (editor && goal) {
        // Updated variable
        const contentHTML = editor.getHTML();
        const currentHash = generateHash(contentHTML);
        if (previousContentHash.current !== currentHash) {
          previousContentHash.current = currentHash;
          onSubmit(editor);
        }
      }
    }, 350),
    [editor, goalDate, completedDate, debouncedTitle]
  );

  // Attach the update handler to the editor
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (editor) {
      editor.on("update", handleEditorUpdate);
      return () => {
        editor.off("update", handleEditorUpdate);
      };
    }
  }, [editor, handleEditorUpdate]);

  // Synchronize local state with fetched goal data
  useEffect(() => {
    if (goal) {
      // Updated variable
      setGoalDate(
        goal.dateTime || dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]") // Updated state
      );
      setCompletedDate(goal.completedAt || ""); // Updated state
      setTitleToChange(goal.title || ""); // Updated state
      setDebouncedTitle(goal.title || ""); // Updated state
      if (editor) {
        editor.commands.setContent(goal.description || ""); // Updated variable
        previousContentHash.current = generateHash(goal.description || ""); // Updated variable
      }
    }
  }, [goal, editor]);

  // Debounce title updates
  useEffect(() => {
    const handler = setTimeout(() => {
      if (titleToChange !== debouncedTitle) {
        setDebouncedTitle(titleToChange);
      }
    }, 350);

    return () => {
      clearTimeout(handler);
    };
  }, [titleToChange, debouncedTitle]);

  // Submit title changes
  useEffect(() => {
    if (debouncedTitle && goal && editor) {
      // Updated variable
      if (debouncedTitle !== goal.title) {
        // Updated variable
        onSubmit(editor);
      }
    }
  }, [debouncedTitle]);

  // Submit date changes
  useEffect(() => {
    if (goalDate && goal && editor) {
      // Updated variable
      if (goalDate !== goal.dateTime) {
        // Updated variable
        onSubmit(editor);
      }
    }
  }, [goalDate]);

  useEffect(() => {
    if (completedDate && goal && editor) {
      // Updated variable
      if (completedDate !== goal.completedAt) {
        // Updated variable
        onSubmit(editor);
      }
    }
  }, [completedDate]);

  // Status indicator
  const [statusIndicator, setStatusIndicator] = useState<JSX.Element | null>(
    null
  );

  useEffect(() => {
    if (isUpdating) {
      setStatusIndicator(<CircularProgress size={24} sx={{ ml: 2 }} />);
    } else if (isSuccess) {
      setStatusIndicator(<CheckCircleOutline color="success" sx={{ ml: 2 }} />);
    } else {
      setStatusIndicator(null);
    }
  }, [isUpdating, isSuccess]);

  if (isLoading || !editor) {
    // Updated variable
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "900px", width: "100%", mb: "12px" }}
      />
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        Error loading goal. {/* Updated text */}
      </Typography>
    );
  }

  return (
    <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 4 }}>
      <UserGoalFormValues // Updated component name
        titleToChange={titleToChange}
        goalDate={goalDate}
        goalCompletedDate={completedDate}
        handleDateChange={handleDateChange}
        handleTitleChange={handleTitleChange}
        handleCompletedDateChange={handleCompletedDateChange}
      />
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <MenuBar editor={editor} /> {/* Ensure MenuBar is updated for goals */}
      </Box>
      <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            flexGrow: 1,
            borderRadius: 1,
            "& .ProseMirror": {
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              padding: 2,
              height: "calc(100vh - 500px)",
              overflow: "auto",
            },
          }}
        >
          <Box
            sx={{
              mb: 2,
              height: 24,
              display: "flex",
              alignItems: "center",
            }}
          >
            {statusIndicator}

            {errorMessage && (
              <Typography sx={{ mt: 2, color: "red" }}>
                Error: {errorMessage}
              </Typography>
            )}
          </Box>
          <EditorContent editor={editor} />
        </Box>
      </Box>
    </Box>
  );
};
