// src/shared/api/userApi.ts

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  // User API models
  ClientResponse,
  ClientListParams,
  UserResponse,
  InviteClientParams,
  UserProfileItemResponse,
  UpdateClientParams,
  PaymentDetailsParams,
  SubscriptionResponse,
  SubscriptionDetailsParams,
  DeleteSubscriptionParams,
  SubscriptionItemResponse,
  AddSubscriptionParams,
  InviteUserParams,
  UploadProfileImageParams,
} from "./models";
import {
  CreateTimeLineItemParams,
  Timeline,
  TimelineItem,
  TimelineItemsListParams,
  TimelineParams,
  UploadMediaParams,
} from "../timeline/models";
import { WorkoutSession } from "../athlete/model";

export const userApi = createApi({
  baseQuery,
  reducerPath: "userApi",
  tagTypes: [
    "User",
    "Users",
    "UsersCount",
    "Subscriptions",
    "Timelines",
    "TimelineItems",
    "Media",
  ],
  endpoints: (builder) => ({
    // Existing User API Endpoints
    getUser: builder.mutation<UserResponse, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["User"],
    }),
    getUserByIdForUserNotes: builder.query<UserResponse, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
    }),
    getUsersCount: builder.query<number, ClientListParams>({
      query: (params) => ({
        url: `/users`,
        method: "GET",
        params,
      }),
      providesTags: ["UsersCount"],
    }),
    getUsers: builder.query<ClientResponse[], ClientListParams>({
      query: (params) => ({
        url: "/users",
        method: "GET",
        params,
      }),
      providesTags: ["Users"],
    }),
    getAllUsers: builder.query<ClientResponse[], ClientListParams>({
      query: (params) => ({
        url: "/users",
        method: "GET",
        params,
      }),
      providesTags: ["Users"],
    }),
    inviteClient: builder.mutation<void, InviteClientParams>({
      query: ({
        firstName,
        lastName,
        email,
        userRole,
        userCategory,
        liftingProfileType,
        assignedFitnessProfessionalId,
      }) => ({
        url: "/users",
        method: "POST",
        body: {
          firstName,
          lastName,
          email,
          userRole,
          userCategory,
          liftingProfileType,
          assignedFitnessProfessionalId,
        },
      }),
      invalidatesTags: ["Users", "UsersCount"],
    }),
    inviteUser: builder.mutation<void, InviteUserParams>({
      query: ({
        firstName,
        lastName,
        email,
        userRole,
        organisationId,
        liftingProfileType,
      }) => ({
        url: "/users",
        method: "POST",
        body: {
          firstName,
          lastName,
          email,
          userRole,
          organisationId,
          liftingProfileType,
        },
      }),
    }),
    uploadWorkoutProgram: builder.mutation<void, FormData>({
      query: (data) => ({
        url: "/workoutprogramparser/parse",
        method: "POST",
        body: data,
      }),
    }),
    getUserProfileItem: builder.query<UserProfileItemResponse, number>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    getUsersForUsersPage: builder.query<UserProfileItemResponse[], number>({
      query: () => ({
        url: `/users`,
        method: "GET",
      }),
    }),
    updateUserProfile: builder.mutation<void, UpdateClientParams>({
      query: ({
        firstName,
        lastName,
        userRole,
        dateOfBirth,
        phoneNumber,
        userStatus,
        userCategory,
        assignedFitnessProfessionalId,
        userLiftingProfileType,
        myFitnessPalUsername,
        swapExercisesEnabled,
        billingFrequency,
        billingStartDate,
        billingEndDate,
        id,
      }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: {
          firstName,
          lastName,
          userRole,
          dateOfBirth,
          phoneNumber,
          userStatus,
          userCategory,
          assignedFitnessProfessionalId,
          userLiftingProfileType,
          myFitnessPalUsername,
          swapExercisesEnabled,
          billingFrequency,
          billingStartDate,
          billingEndDate,
        },
        invalidatesTags: ["User"],
      }),
    }),
    addPaymentDetails: builder.mutation<void, PaymentDetailsParams>({
      query: ({ UserId, OrganisationId, CardToken }) => ({
        url: "/billing/payment-methods/cards",
        method: "POST",
        body: {
          UserId,
          OrganisationId,
          CardToken,
        },
      }),
    }),
    getUserSubscriptions: builder.query<
      SubscriptionResponse[],
      SubscriptionDetailsParams
    >({
      query: ({ UserId, OrganisationId }) => ({
        url: `/billing/subscriptions/${UserId}/${OrganisationId}`,
        method: "GET",
      }),
      providesTags: ["Subscriptions"],
    }),
    deleteUserSubscription: builder.mutation<void, DeleteSubscriptionParams>({
      query: ({ UserId, OrganisationId, SubscriptionId, ...rest }) => ({
        url: `/billing/subscriptions/${UserId}/${OrganisationId}/${SubscriptionId}`,
        method: "DELETE",
        body: rest,
      }),
      invalidatesTags: ["Subscriptions"],
    }),
    getUserSubscriptionPlan: builder.query<SubscriptionItemResponse[], number>({
      query: () => ({
        url: `/billing/products`,
        method: "GET",
      }),
    }),
    addSubscriptionPlan: builder.mutation<void, AddSubscriptionParams>({
      query: ({ UserId, OrganisationId, EndDate, PriceIds, StartDate }) => ({
        url: "/billing/subscriptions",
        method: "POST",
        body: {
          UserId,
          OrganisationId,
          EndDate,
          PriceIds,
          StartDate,
        },
      }),
      invalidatesTags: ["Subscriptions"],
    }),
    uploadProfileImage: builder.mutation<void, UploadProfileImageParams>({
      query: (params) => {
        const formData = new FormData();
        formData.append("file", params.file);
        formData.append("userId", params.userId.toString());

        return {
          url: "/users/upload",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["User", "Users"],
    }),
    clearAllUnreadNotifications: builder.mutation<void, void>({
      query: () => ({
        url: `/timeline-items/clear`,
        method: "POST",
      }),
      invalidatesTags: ["Users", "Timelines", "TimelineItems"],
    }),

    // Merged Timelines API Endpoints
    getTimelinesCount: builder.query<number, void>({
      query: () => ({
        url: `/timelines`,
        method: "GET",
        params: { countOnly: true },
      }),
      providesTags: ["Timelines"],
    }),
    getTimelines: builder.query<Timeline[], TimelineParams>({
      query: (params) => ({
        url: "/timelines",
        method: "GET",
        params,
      }),
      providesTags: ["Timelines"],
    }),
    getAllTimelines: builder.query<Timeline[], void>({
      query: () => ({
        url: "/timelines",
        method: "GET",
      }),
      providesTags: ["Timelines"],
    }),
    getTimelineById: builder.query<Timeline, number>({
      query: (timelineId) => ({
        url: `/timelines/${timelineId}`,
        method: "GET",
      }),
      providesTags: ["Timelines"],
    }),
    getTimelineItems: builder.query<TimelineItem[], TimelineItemsListParams>({
      query: (params) => ({
        url: `/timeline-items`,
        method: "GET",
        params,
      }),
      providesTags: ["TimelineItems"],
    }),
    getTimelineItemsCount: builder.query<number, TimelineItemsListParams>({
      query: (params) => ({
        url: `/timeline-items`,
        method: "GET",
        params: { ...params, countOnly: true },
      }),
      providesTags: ["TimelineItems"],
    }),
    createTimelineItem: builder.mutation<void, CreateTimeLineItemParams>({
      query: (body) => ({
        url: `/timeline-items`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TimelineItems"],
    }),
    uploadMedia: builder.mutation<void, UploadMediaParams>({
      query: (params) => {
        const formData = new FormData();
        formData.append("file", params.uploadFile);

        return {
          url: "/media/upload",
          method: "POST",
          body: formData,
          params: {
            timelineId: params.timelineId ?? undefined,
          },
        };
      },
      invalidatesTags: ["Media", "TimelineItems"],
    }),
    getWorkoutForTimelineItem: builder.query<WorkoutSession, number>({
      query: (timelineItemId) => ({
        url: `/timeline-items/${timelineItemId}/workout`,
        method: "GET",
      }),
    }),
    likeTimelineItem: builder.mutation<void, { timelineItemId: number }>({
      query: (body) => ({
        url: `/timeline-items/like`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TimelineItems"],
    }),
  }),
});

export const {
  // User API hooks
  useGetUserMutation,
  useGetUsersQuery,
  useInviteClientMutation,
  useGetUserProfileItemQuery,
  useGetUsersForUsersPageQuery,
  useUpdateUserProfileMutation,
  useAddPaymentDetailsMutation,
  useGetUserSubscriptionsQuery,
  useDeleteUserSubscriptionMutation,
  useGetUserSubscriptionPlanQuery,
  useAddSubscriptionPlanMutation,
  useGetUsersCountQuery,
  useGetAllUsersQuery,
  useUploadWorkoutProgramMutation,
  useInviteUserMutation,
  useUploadProfileImageMutation,
  useGetUserByIdForUserNotesQuery,

  // Timelines API hooks
  useClearAllUnreadNotificationsMutation,
  useGetTimelinesCountQuery,
  useGetTimelinesQuery,
  useGetAllTimelinesQuery,
  useGetTimelineByIdQuery,
  useGetTimelineItemsQuery,
  useGetTimelineItemsCountQuery,
  useCreateTimelineItemMutation,
  useLikeTimelineItemMutation,
  useUploadMediaMutation,
  useGetWorkoutForTimelineItemQuery,

  // Lazy hooks
  useLazyGetUsersQuery,
} = userApi;
