import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ActivityCard } from "./ActivityCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { SleepCard } from "./SleepCard";

interface SleepDetailsCardProps {
  sleepInsights: {
    overallScore: number;
    overallState: string;
    sleepDuration: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    sleepRegularity: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    sleepContinuity: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    sleepDebt: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    circadianAlignment: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    mentalRecovery: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    physicalRecovery: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
  };
}

export const SleepDetailsCard: React.FC<SleepDetailsCardProps> = ({
  sleepInsights,
}) => {
  const overallScorePerformance = determinePerformanceLevel(
    sleepInsights.overallScore,
    1
  );
  const sleepDurationPerformance = determinePerformanceLevel(
    sleepInsights.sleepDuration?.value ?? 0,
    sleepInsights.sleepDuration?.goal ?? 0
  );
  const sleepRegularityPerformance = determinePerformanceLevel(
    sleepInsights.sleepRegularity?.score ?? 0,
    1
  );
  const sleepContinuityPerformance = determinePerformanceLevel(
    sleepInsights.sleepContinuity?.score ?? 0,
    10
  );
  return (
    <Box sx={{ borderRadius: 2 }}>
      <Box sx={{ padding: "16px 24px" }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary", mt: 1 }}>
          Here&apos;s your daily sleep summary:
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ padding: "0 24px" }}>
        <Grid item xs={6}>
          <SleepCard
            sleepDuration={sleepInsights?.sleepDuration?.value ?? 0}
            sleepDurationGoal={sleepInsights?.sleepDuration?.goal ?? 0}
            gradient={getGradientForScore(sleepDurationPerformance)}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Overall Sleep Score"
            value={sleepInsights.overallScore}
            target={1}
            gradient={getGradientForScore(overallScorePerformance)}
            overrideText={sleepInsights.overallState}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Sleep Regularity"
            value={sleepInsights.sleepRegularity?.value ?? 0}
            target={sleepInsights.sleepRegularity?.goal ?? 0}
            gradient={getGradientForScore(sleepRegularityPerformance)}
            overrideText={sleepInsights.sleepRegularity?.state ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Sleep Continuity"
            value={sleepInsights.sleepContinuity?.value ?? 0}
            target={sleepInsights.sleepContinuity?.goal ?? 0}
            gradient={getGradientForScore(sleepContinuityPerformance)}
            overrideText={sleepInsights.sleepContinuity?.state ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Sleep Debt"
            value={sleepInsights.sleepDebt?.value ?? 0}
            target={sleepInsights.sleepDebt?.goal ?? 0}
            unit={sleepInsights.sleepDebt?.unit ?? ""}
            gradient={getGradientForScore("poor")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
