// UserProfileNoteTextEditor.tsx

import { FC, useCallback, useRef, useState, useEffect } from "react";
import hash from "hash.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Box } from "@mui/system";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  CircularProgress,
  Skeleton,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Note,
  UpdateNoteParams,
  useGetNoteByIdQuery,
  useUpdateNoteMutation,
} from "shared/api";
import { MenuBar } from "./UserNotesMenuBar";
import { UserNoteFormValues } from "./UserNoteFormValues";

type UserProfileNoteTextEditorProps = {
  userNoteId: number;
};

const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const UserProfileNoteTextEditor: FC<UserProfileNoteTextEditorProps> = ({
  userNoteId,
}) => {
  dayjs.extend(utc);

  // Fetch the note data using the provided ID
  const { data: note, isLoading, error } = useGetNoteByIdQuery(userNoteId);

  // Initialize local states
  const [noteDate, setNoteDate] = useState<string>("");
  const [titleToChange, setTitleToChange] = useState<string>("");
  const [debouncedTitle, setDebouncedTitle] = useState<string>("");

  const handleDateChange = (date: string | null) => {
    setNoteDate(dayjs.utc(date).format("YYYY-MM-DDTHH:mm:ss[Z]"));
  };

  const handleTitleChange = (title: string) => {
    setTitleToChange(title);
  };

  const previousContentHash = useRef<string>("");

  const generateHash = (content: string) => {
    return hash.sha256().update(content).digest("hex");
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Initialize the editor only after note data is available
  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: note?.content || "",
  });

  const [updateNoteMutation] = useUpdateNoteMutation();

  const onSubmit = async (editorInstance: any) => {
    if (!note || !note.id) return;

    setIsUpdating(true);
    setIsSuccess(false);
    try {
      const updatedNote: UpdateNoteParams = {
        id: note.id,
        dateTime: noteDate || note.dateTime,
        title: debouncedTitle || note.title,
        content: editorInstance.getHTML(),
        noteType: note.noteType,
      };

      await updateNoteMutation(updatedNote).unwrap();
      setIsSuccess(true);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setIsUpdating(false);
      setTimeout(() => setIsSuccess(false), 2000);
      setTimeout(() => setErrorMessage(""), 2000);
    }
  };

  // Debounced function to handle editor updates
  const handleEditorUpdate = useCallback(
    debounce(() => {
      if (editor && note) {
        const contentHTML = editor.getHTML();
        const currentHash = generateHash(contentHTML);
        if (previousContentHash.current !== currentHash) {
          previousContentHash.current = currentHash;
          onSubmit(editor);
        }
      }
    }, 350),
    [editor, noteDate, debouncedTitle]
  );

  // Attach the update handler to the editor
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (editor) {
      editor.on("update", handleEditorUpdate);
      return () => {
        editor.off("update", handleEditorUpdate);
      };
    }
  }, [editor, handleEditorUpdate]);

  // Synchronize local state with fetched note data
  useEffect(() => {
    if (note) {
      setNoteDate(
        note.dateTime || dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      );
      setTitleToChange(note.title || "");
      setDebouncedTitle(note.title || "");
      if (editor) {
        editor.commands.setContent(note.content || "");
        previousContentHash.current = generateHash(note.content || "");
      }
    }
  }, [note, editor]);

  // Debounce title updates
  useEffect(() => {
    const handler = setTimeout(() => {
      if (titleToChange !== debouncedTitle) {
        setDebouncedTitle(titleToChange);
      }
    }, 350);

    return () => {
      clearTimeout(handler);
    };
  }, [titleToChange, debouncedTitle]);

  // Submit title changes
  useEffect(() => {
    if (debouncedTitle && note && editor) {
      if (debouncedTitle !== note.title) {
        onSubmit(editor);
      }
    }
  }, [debouncedTitle]);

  // Submit date changes
  useEffect(() => {
    if (noteDate && note && editor) {
      if (noteDate !== note.dateTime) {
        onSubmit(editor);
      }
    }
  }, [noteDate]);

  // Status indicator
  const [statusIndicator, setStatusIndicator] = useState<JSX.Element | null>(
    null
  );

  useEffect(() => {
    if (isUpdating) {
      setStatusIndicator(<CircularProgress size={24} sx={{ ml: 2 }} />);
    } else if (isSuccess) {
      setStatusIndicator(<CheckCircleOutline color="success" sx={{ ml: 2 }} />);
    } else {
      setStatusIndicator(null);
    }
  }, [isUpdating, isSuccess]);

  if (isLoading || !editor) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "900px", width: "100%", mb: "12px" }}
      />
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        Error loading note.
      </Typography>
    );
  }

  return (
    <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 4 }}>
      <UserNoteFormValues
        titleToChange={titleToChange}
        noteDate={noteDate}
        handleDateChange={handleDateChange}
        handleTitleChange={handleTitleChange}
      />
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <MenuBar editor={editor} />
      </Box>
      <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            flexGrow: 1,
            borderRadius: 1,
            "& .ProseMirror": {
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              padding: 2,
              height: "calc(100vh - 500px)",
              overflow: "auto",
            },
          }}
        >
          <Box
            sx={{
              mb: 2,
              height: 24,
              display: "flex",
              alignItems: "center",
            }}
          >
            {statusIndicator}

            {errorMessage && (
              <Typography sx={{ mt: 2, color: "red" }}>
                Error: {errorMessage}
              </Typography>
            )}
          </Box>
          <EditorContent editor={editor} />
        </Box>
      </Box>
    </Box>
  );
};
