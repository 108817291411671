import { FC, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { MoreInfoButton } from "pages/Programs/AdminPrograms/ui/MoreInfoButton";
import { useGetNutritionPlanByIdQuery } from "shared/api";
import { ChangeStatusModal } from "./ui/ChangeStatusModal";

interface NutritionPlanItemActionProps {
  planId: number;
}

const NutritionPlanItemAction: FC<NutritionPlanItemActionProps> = ({
  planId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const open = Boolean(anchorEl);

  const { data: nutritionPlan } = useGetNutritionPlanByIdQuery(planId, {
    skip: !open,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = () => {
    setOpenChangeStatusModal(true);
    handleClose();
  };

  return (
    <>
      <MoreInfoButton handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #EEEEEE",
          },
          "& .MuiList-root": { padding: "0px" },
        }}
      >
        <Link to={`${Routes.nutrition.url}/${planId}`}>
          <MenuItem
            onClick={handleClose}
            sx={{
              border: "1px solid #EEEEEE",
              color: Colors.gray[1900],
            }}
          >
            View plan
          </MenuItem>
        </Link>
        <MenuItem
          onClick={handleChangeStatus}
          sx={{
            border: "1px solid #EEEEEE",
            color: Colors.gray[1900],
          }}
        >
          Change status
        </MenuItem>
      </Menu>

      <ChangeStatusModal
        isOpenModal={openChangeStatusModal}
        handleCloseModal={() => setOpenChangeStatusModal(false)}
        nutritionPlan={nutritionPlan}
      />
    </>
  );
};

export { NutritionPlanItemAction };
