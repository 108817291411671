import { AnyType } from "../../../../shared/helpers";
import { Colors } from "../../../../shared/themes";

export const subscriptionItemProps: AnyType = {
  active: {
    background: `${Colors.green[700]}`,
    color: `${Colors.gray[800]}`,
    iconColor: `${Colors.green[300]}`,
    title: "subscriptions.active",
    icon: "update_icon",
  },
  inactive: {
    background: `${Colors.red[200]}`,
    color: `${Colors.gray[100]}`,
    iconColor: `${Colors.gray[100]}`,
    title: "subscriptions.inactive",
    icon: "restore_icon",
  },
};

export const closeModalTime = 4000;

export enum CancelSubscriptions {
  IMMEDIATELY = "immediately",
  CURRENT_PERIOD = "currentPeriod",
  CUSTOM_DATE = "customDate",
}

export const UserProfileTabValues = {
  Overview: "1",
  Programs: "2",
  TreatmentNotes: "3",
  HealthMetrics: "4",
  TrainingInsights: "5",
  Nutrition: "6",
  Settings: "7",
};
