import React, { FC, useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Typography,
  useMediaQuery,
  IconButton,
  FormControlLabel,
  Switch,
  Drawer,
  TextField,
  List,
  ListItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { useTypedSelector } from "shared/stores";
import { useParams, useNavigate } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import dayjs from "dayjs";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  useGetNutritionPlanByIdQuery,
  useUpdateNutritionPlanMutation,
  UserNutritionPlanMealResponse,
  UserNutritionPlanMealItemResponse,
  useGetUserProfileItemQuery,
  CreateNutritionPlanParams,
  DayOfWeek,
  useSearchFoodsQuery,
  Food,
  Serving,
} from "shared/api";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { produce } from "immer";
import { Routes } from "shared/routers";
import { UserProfileTabValues } from "pages/Dashboard";
import {
  StyledTableContainer,
  StyledHeaderRow,
  StyledHeaderCell,
  StyledRow,
  StyledCell,
  StyledInput,
  UserInfoCard,
  UserDetails,
  PlanInfo,
  MacroSummaryCard,
  MacroStatusDot,
  MacroTable,
  MealContainer,
  MealHeader,
  EditableTypography,
  MACRO_COLORS,
  columnWidths,
} from "./styles";

export const MacroChart = ({
  meals,
}: {
  meals: UserNutritionPlanMealResponse[];
}) => {
  const chartData = {
    series: [
      {
        name: "Protein",
        data: meals.map((meal) =>
          meal.userNutritionPlanMealItems.reduce(
            (acc, item) => acc + (item.protein || 0),
            0
          )
        ),
      },
      {
        name: "Carbs",
        data: meals.map((meal) =>
          meal.userNutritionPlanMealItems.reduce(
            (acc, item) => acc + (item.carbohydrates || 0),
            0
          )
        ),
      },
      {
        name: "Fats",
        data: meals.map((meal) =>
          meal.userNutritionPlanMealItems.reduce(
            (acc, item) => acc + (item.fats || 0),
            0
          )
        ),
      },
    ],
  };

  const chartOptions: ApexOptions = {
    chart: {
      id: "macro-nutrients",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter(val: string | number | number[]) {
        if (typeof val === "number") {
          return `${val.toFixed(1)}g`;
        }
        return `${val}g`;
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 7,
        borderRadiusApplication: "end",
        columnWidth: "70%",
      },
    },
    grid: {
      show: true,
      borderColor: "#E5E5EF",
      strokeDashArray: 7,
    },
    colors: [MACRO_COLORS.protein, MACRO_COLORS.carbs, MACRO_COLORS.fats],
    xaxis: {
      categories: meals.map((meal) => meal.name),
      title: {
        text: "Grams",
      },
    },
    yaxis: {
      title: {
        text: "Meals",
      },
    },
    legend: {
      position: "top" as const,
      horizontalAlign: "right" as const,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val.toFixed(1)}g`;
        },
      },
    },
  };

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Chart
        options={chartOptions}
        series={chartData.series}
        type="bar"
        width="100%"
        height="300"
      />
    </Box>
  );
};

interface NutritionPlanSettingsSidebarProps {
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  onSelectServing: (
    food: Food,
    serving: Serving,
    mealId: number,
    itemIndex: number
  ) => void;
  meals: UserNutritionPlanMealResponse[];
  initialSearchTerm?: string;
  initialMealId?: number;
  initialItemIndex?: number;
}

const NutritionPlanSettingsSidebar: FC<NutritionPlanSettingsSidebarProps> = ({
  isDrawerOpen,
  handleCloseDrawer,
  onSelectServing,
  meals,
  initialSearchTerm = "",
  initialMealId,
  initialItemIndex,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMealId, setSelectedMealId] = useState<number | null>(null);
  const [shouldSearch, setShouldSearch] = useState(false);

  useEffect(() => {
    if (isDrawerOpen) {
      if (initialSearchTerm && initialMealId) {
        setSearchTerm(initialSearchTerm);
        setSelectedMealId(initialMealId);
        setShouldSearch(true);
      } else if (meals.length > 0 && !selectedMealId) {
        setSelectedMealId(meals[0].id);
      }
    }
  }, [isDrawerOpen, initialSearchTerm, initialMealId, meals]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShouldSearch(true);
  };

  const { data: searchResults, isLoading: isSearching } = useSearchFoodsQuery(
    { search_expression: searchTerm },
    { skip: !shouldSearch || !searchTerm }
  );

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box
        sx={{
          mt: 8,
          width: "35vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            borderBottom: `1px solid ${Colors.gray[200]}`,
            padding: "16px 24px",
            zIndex: 1,
          }}
        >
          <Typography variant="h6" sx={{ mb: 3 }}>
            Search Food
          </Typography>

          <form onSubmit={handleSearchSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search foods..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShouldSearch(false);
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ color: Colors.gray[400], mr: 1 }} />
                ),
              }}
            />
          </form>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: "16px 24px",
          }}
        >
          {isSearching && (
            <Typography sx={{ mt: 2, color: Colors.gray[600] }}>
              Searching...
            </Typography>
          )}

          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {searchResults?.map((food) => (
              <React.Fragment key={food.foodId}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    flexDirection: "column",
                    borderBottom: `1px solid ${Colors.gray[200]}`,
                    pb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%", mb: 1 }}>
                    {food.foodImages?.[0]?.imageUrl && (
                      <Box
                        component="img"
                        src={food.foodImages[0].imageUrl}
                        alt={food.foodName}
                        sx={{
                          width: 50,
                          height: 50,
                          objectFit: "cover",
                          borderRadius: 1,
                          mr: 2,
                        }}
                      />
                    )}
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 600, color: Colors.gray[900] }}
                    >
                      {food.foodName}
                    </Typography>
                  </Box>

                  <Box sx={{ width: "100%", overflowX: "auto" }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell>Serving</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Calories</TableCell>
                          <TableCell align="right">Protein</TableCell>
                          <TableCell align="right">Carbs</TableCell>
                          <TableCell align="right">Fat</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {food.servingContainer?.servings?.map((serving) => (
                          <TableRow
                            key={serving.servingId}
                            hover
                            sx={{ "&:last-child td": { border: 0 } }}
                          >
                            <TableCell>
                              <Tooltip
                                title={
                                  selectedMealId
                                    ? `Add to ${
                                        meals.find(
                                          (m) => m.id === selectedMealId
                                        )?.name
                                      }`
                                    : "Select a meal first"
                                }
                              >
                                <span>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      if (
                                        selectedMealId &&
                                        initialItemIndex !== undefined
                                      ) {
                                        onSelectServing(
                                          food,
                                          serving,
                                          selectedMealId,
                                          initialItemIndex
                                        );
                                        handleCloseDrawer();
                                      }
                                    }}
                                    sx={{ color: Colors.blue[500] }}
                                    disabled={!selectedMealId}
                                  >
                                    <AddIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell>{serving.servingDescription}</TableCell>
                            <TableCell align="right">
                              {serving.metricServingAmount}
                              {serving.metricServingUnit}
                            </TableCell>
                            <TableCell align="right">
                              {serving.calories}
                            </TableCell>
                            <TableCell align="right">
                              {serving.protein}g
                            </TableCell>
                            <TableCell align="right">
                              {serving.carbohydrate}g
                            </TableCell>
                            <TableCell align="right">{serving.fat}g</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

const NutritionPlanView: FC = () => {
  const params = useParams();
  const planId = parseInt(params.nutritionPlanId!, 10);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const { data: nutritionPlan, isLoading: isLoadingPlan } =
    useGetNutritionPlanByIdQuery(planId, {
      skip: !planId,
    });

  const { data: userProfile, isLoading: isLoadingUser } =
    useGetUserProfileItemQuery(
      nutritionPlan?.userId ? Number(nutritionPlan.userId) : 0,
      {
        skip: !nutritionPlan?.userId,
      }
    );

  const [updateNutritionPlan] = useUpdateNutritionPlanMutation();

  const [meals, setMeals] = useState<UserNutritionPlanMealResponse[]>([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showMacroSummary, setShowMacroSummary] = useState<boolean>(true);

  const newMealIdCounter = useRef(-1);
  const newItemIdCounter = useRef(-1);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [initialSearchState, setInitialSearchState] = useState<{
    searchTerm: string;
    mealId: number;
    itemIndex: number;
  } | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (nutritionPlan?.userNutritionPlanMeals) {
      setMeals(nutritionPlan.userNutritionPlanMeals);
    }
  }, [nutritionPlan]);

  const handleAddMeal = () => {
    setMeals(
      produce((draft) => {
        const newMeal: UserNutritionPlanMealResponse = {
          // eslint-disable-next-line no-plusplus
          id: newMealIdCounter.current--, // Assign unique ID
          day: DayOfWeek.Monday,
          name: `Meal ${draft.length + 1}`,
          description: "",
          mealOrder: draft.length + 1,
          userNutritionPlanMealItems: [],
          organisationId: nutritionPlan?.organisationId || 0,
        };
        draft.push(newMeal);
      })
    );
  };

  const handleAddItem = (mealId: number) => {
    setMeals(
      produce((draft) => {
        const meal = draft.find((m) => m.id === mealId);
        if (meal) {
          const newItem: UserNutritionPlanMealItemResponse = {
            // eslint-disable-next-line no-plusplus
            id: newItemIdCounter.current--, // Assign unique ID
            name: "",
            imageUrl: "",
            protein: 0,
            carbohydrates: 0,
            fats: 0,
            calories: 0,
            quantity: 0,
            servingSizeUnit: "Grams",
            serving: "",
            organisationId: nutritionPlan?.organisationId || 0,
          };
          meal.userNutritionPlanMealItems.push(newItem);
        }
      })
    );
  };

  const handleDeleteItem = (mealId: number, itemId: number) => {
    setMeals(
      produce((draft) => {
        const meal = draft.find((m) => m.id === mealId);
        if (meal) {
          meal.userNutritionPlanMealItems =
            meal.userNutritionPlanMealItems.filter(
              (item) => item.id !== itemId
            );
        }
      })
    );
  };

  const handleInputChange = (
    mealId: number,
    itemId: number,
    field: keyof UserNutritionPlanMealItemResponse,
    value: string | number
  ) => {
    setMeals(
      produce((draft) => {
        const meal = draft.find((m) => m.id === mealId);
        if (meal) {
          const item = meal.userNutritionPlanMealItems.find(
            (i) => i.id === itemId
          );
          if (item) {
            if (
              [
                "calories",
                "protein",
                "carbohydrates",
                "fats",
                "quantity",
              ].includes(field) &&
              typeof value === "string"
            ) {
              (item[field] as number) = value === "" ? 0 : parseFloat(value);
            } else {
              (item[field] as string) = value.toString();
            }
          }
        }
      })
    );
  };

  const calculateMealTotals = (meal: UserNutritionPlanMealResponse) => {
    return meal.userNutritionPlanMealItems.reduce(
      (acc, item) => ({
        calories: acc.calories + (item.calories || 0),
        protein: acc.protein + (item.protein || 0),
        carbs: acc.carbs + (item.carbohydrates || 0),
        fats: acc.fats + (item.fats || 0),
      }),
      { calories: 0, protein: 0, carbs: 0, fats: 0 }
    );
  };

  const calculateDailyTotals = () => {
    return meals.reduce(
      (acc, meal) => {
        const mealTotals = calculateMealTotals(meal);

        return {
          calories: acc.calories + mealTotals.calories,
          protein: acc.protein + mealTotals.protein,
          carbs: acc.carbs + mealTotals.carbs,
          fats: acc.fats + mealTotals.fats,
        };
      },
      { calories: 0, protein: 0, carbs: 0, fats: 0 }
    );
  };

  const handleSaveChanges = async (status: string) => {
    try {
      if (!nutritionPlan) return;

      const payload: CreateNutritionPlanParams = {
        id: nutritionPlan.id,
        name: nutritionPlan.name,
        description: nutritionPlan.description || "",
        startDate: nutritionPlan.startDate,
        endDate: nutritionPlan.endDate,
        userId: nutritionPlan.userId,
        nutritionPlanStatus: status,
        meals: meals.map((meal) => ({
          day: meal.day,
          name: meal.name,
          description: meal.description,
          mealOrder: meal.mealOrder,
          items: meal.userNutritionPlanMealItems.map((item) => ({
            name: item.name,
            imageUrl: item.imageUrl,
            protein: item.protein,
            carbohydrates: item.carbohydrates,
            fats: item.fats,
            calories: item.calories,
            quantity: item.quantity,
            servingSizeUnit: item.servingSizeUnit,
            serving: item.serving,
          })),
        })),
      };

      await updateNutritionPlan(payload).unwrap();
      setSuccessMessage("Nutrition plan updated successfully");
    } catch (error) {
      setErrorMessage("Failed to update nutrition plan");
      console.error("Error updating nutrition plan:", error);
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 4000);
    }
  };

  const handleMoveMeal = (index: number, direction: "up" | "down") => {
    setMeals(
      produce((draft) => {
        if (direction === "up" && index > 0) {
          // Create new meal objects with swapped orders
          const mealA = draft[index];
          const mealB = draft[index - 1];

          // Swap the meal orders
          const tempOrder = mealA.mealOrder;
          mealA.mealOrder = mealB.mealOrder;
          mealB.mealOrder = tempOrder;

          // Swap the meals in the array
          draft[index] = mealB;
          draft[index - 1] = mealA;
        } else if (direction === "down" && index < draft.length - 1) {
          // Create new meal objects with swapped orders
          const mealA = draft[index];
          const mealB = draft[index + 1];

          // Swap the meal orders
          const tempOrder = mealA.mealOrder;
          mealA.mealOrder = mealB.mealOrder;
          mealB.mealOrder = tempOrder;

          // Swap the meals in the array
          draft[index] = mealB;
          draft[index + 1] = mealA;
        }
      })
    );
  };

  const handleMealNameChange = (mealId: number, newName: string) => {
    setMeals(
      produce((draft) => {
        const meal = draft.find((m) => m.id === mealId);
        if (meal) {
          meal.name = newName;
        }
      })
    );
  };

  const handleDeleteMeal = (mealId: number) => {
    setMeals(
      produce((draft) => {
        const index = draft.findIndex((meal) => meal.id === mealId);
        if (index !== -1) {
          draft.splice(index, 1);
          // Update mealOrder for remaining meals
          draft.forEach((meal, i) => {
            meal.mealOrder = i + 1;
          });
        }
      })
    );
  };

  const handleSelectServing = (
    food: Food,
    serving: Serving,
    mealId: number,
    itemIndex: number
  ) => {
    setMeals(
      produce((draft) => {
        const meal = draft.find((m) => m.id === mealId);
        if (meal) {
          const servingSizeMetricUnit =
            // eslint-disable-next-line no-nested-ternary
            serving.metricServingUnit === "g"
              ? "Grams"
              : // eslint-disable-next-line no-nested-ternary
              serving.metricServingUnit === "ml"
              ? "Millilitres"
              : serving.metricServingUnit === "oz"
              ? "Ounces"
              : "Undefined";

          const newItem: UserNutritionPlanMealItemResponse = {
            // eslint-disable-next-line no-plusplus
            id: newItemIdCounter.current--,
            name: food.foodName,
            imageUrl: food.foodImages?.[0]?.imageUrl || "",
            protein: Number(serving.protein) || 0,
            carbohydrates: Number(serving.carbohydrate) || 0,
            fats: Number(serving.fat) || 0,
            calories: Number(serving.calories) || 0,
            quantity: Number(serving.metricServingAmount) || 0,
            servingSizeUnit: servingSizeMetricUnit,
            serving: serving.metricServingAmount?.toString() || "",
            organisationId: nutritionPlan?.organisationId || 0,
          };

          meal.userNutritionPlanMealItems[itemIndex] = newItem;
        }
      })
    );
  };

  if (isLoadingPlan || isLoadingUser) {
    return <ProgramsSkeleton />;
  }

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.profileContent}>
        <UserInfoCard elevation={3}>
          <IdenticonAvatar
            sizeValue={60}
            seedValue={userProfile?.id?.toString()}
            profileImageUrl={userProfile?.profileImageUrl}
          />
          <UserDetails spacing={0.5}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                color: Colors.gray[900],
              }}
            >
              {userProfile?.firstName} {userProfile?.lastName}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Inter",
                color: Colors.gray[600],
              }}
            >
              {userProfile?.email}
            </Typography>
            <PlanInfo>
              <Typography variant="body2">{nutritionPlan?.name}</Typography>
            </PlanInfo>
            <PlanInfo>
              <Typography variant="body2">
                {nutritionPlan?.startDate &&
                  dayjs(nutritionPlan.startDate).format("MMM D, YYYY")}
                {nutritionPlan?.endDate &&
                  ` - ${dayjs(nutritionPlan.endDate).format("MMM D, YYYY")}`}
              </Typography>
            </PlanInfo>

            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() =>
                  navigate(
                    `${Routes.profile.url}/${nutritionPlan?.userId}/${UserProfileTabValues.Nutrition}`
                  )
                }
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                }}
              >
                Back to profile
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate(`${Routes.nutrition.url}`)}
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                }}
              >
                View all nutrition plans
              </Button>
            </Box>
          </UserDetails>
        </UserInfoCard>

        {showMacroSummary && (
          <MacroSummaryCard>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ color: Colors.gray[900], mb: 2 }}>
                Macro Distribution by Meal
              </Typography>
              <MacroChart meals={meals} />
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ color: Colors.gray[900], mb: 2 }}>
                Detailed Macro Breakdown
              </Typography>
              <MacroTable>
                <thead>
                  <tr>
                    <th>Meal</th>
                    <th>Calories</th>
                    <th>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <MacroStatusDot color={MACRO_COLORS.protein} />
                        Protein (g)
                      </Box>
                    </th>
                    <th>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <MacroStatusDot color={MACRO_COLORS.carbs} />
                        Carbs (g)
                      </Box>
                    </th>
                    <th>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <MacroStatusDot color={MACRO_COLORS.fats} />
                        Fats (g)
                      </Box>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {meals.map((meal) => {
                    const mealTotals = calculateMealTotals(meal);
                    return (
                      <tr key={meal.id}>
                        <td>{meal.name}</td>
                        <td>{mealTotals.calories.toFixed(1)}</td>
                        <td>{mealTotals.protein.toFixed(1)}</td>
                        <td>{mealTotals.carbs.toFixed(1)}</td>
                        <td>{mealTotals.fats.toFixed(1)}</td>
                      </tr>
                    );
                  })}
                  <tr
                    style={{
                      fontWeight: 600,
                      backgroundColor: Colors.gray[50],
                    }}
                  >
                    <td>Daily Total</td>
                    <td>{calculateDailyTotals().calories.toFixed(1)}</td>
                    <td>{calculateDailyTotals().protein.toFixed(1)}</td>
                    <td>{calculateDailyTotals().carbs.toFixed(1)}</td>
                    <td>{calculateDailyTotals().fats.toFixed(1)}</td>
                  </tr>
                  <tr style={{ fontStyle: "italic", color: Colors.gray[700] }}>
                    <td>%</td>
                    <td>-</td>
                    {(() => {
                      const totals = calculateDailyTotals();
                      const totalMacros =
                        totals.protein + totals.carbs + totals.fats;
                      return (
                        <>
                          <td>
                            {totalMacros === 0
                              ? "0.0%"
                              : `${(
                                  (totals.protein / totalMacros) *
                                  100
                                ).toFixed(1)}%`}
                          </td>
                          <td>
                            {totalMacros === 0
                              ? "0.0%"
                              : `${((totals.carbs / totalMacros) * 100).toFixed(
                                  1
                                )}%`}
                          </td>
                          <td>
                            {totalMacros === 0
                              ? "0.0%"
                              : `${((totals.fats / totalMacros) * 100).toFixed(
                                  1
                                )}%`}
                          </td>
                        </>
                      );
                    })()}
                  </tr>
                </tbody>
              </MacroTable>
            </Box>
          </MacroSummaryCard>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 3,
            mt: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  onChange={() => setShowMacroSummary(!showMacroSummary)}
                  checked={showMacroSummary}
                />
              }
              label="Macro Summary"
              sx={{
                "& span": {
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: Colors.oxford[1100],
                },
              }}
            />
            {nutritionPlan?.nutritionPlanStatus === "Draft" && (
              <PrimaryButton
                variant="outlined"
                color="primary"
                value="Save Draft"
                onClick={() => handleSaveChanges("Draft")}
                sx={{ textTransform: "none" }}
              />
            )}
            <PrimaryButton
              variant="contained"
              color="primary"
              value="Complete and Share"
              onClick={() => handleSaveChanges("InProgress")}
              sx={{ textTransform: "none" }}
            />
          </Box>
        </Box>
        {successMessage && (
          <Alert
            sx={{
              mb: 1,
              width: "100%",
            }}
            variant="outlined"
            severity="success"
          >
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert
            sx={{
              mb: 1,
              width: "100%",
            }}
            variant="outlined"
            severity="error"
          >
            {errorMessage}
          </Alert>
        )}

        {meals.map((meal, index) => (
          <MealContainer key={meal.id}>
            <MealHeader>
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton
                  size="small"
                  onClick={() => handleMoveMeal(index, "up")}
                  disabled={index === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleMoveMeal(index, "down")}
                  disabled={index === meals.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Box>
              <EditableTypography
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleMealNameChange(
                    meal.id,
                    e.currentTarget.textContent || ""
                  )
                }
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 600,
                  flex: 1,
                  display: "inline-block",
                }}
              >
                {meal.name}
              </EditableTypography>
              <IconButton
                size="small"
                onClick={() => handleDeleteMeal(meal.id)}
                sx={{ ml: "auto" }}
              >
                <DeleteIcon />
              </IconButton>
            </MealHeader>

            <StyledTableContainer>
              <StyledHeaderRow>
                <StyledHeaderCell width={columnWidths.food}>
                  Food
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.portion}>
                  Serving
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.servingUnit}>
                  Unit
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.calories}>
                  Calories
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.protein}>
                  Protein (g)
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.carbs}>
                  Carbs (g)
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.fats}>
                  Fats (g)
                </StyledHeaderCell>
                <StyledHeaderCell width={columnWidths.actions}>
                  Actions
                </StyledHeaderCell>
              </StyledHeaderRow>

              {meal.userNutritionPlanMealItems.map((item, itemIndex) => (
                <StyledRow
                  key={`${meal.id}-${item.id}-${itemIndex}`}
                  isEven={itemIndex % 2 === 0}
                >
                  <StyledCell width={columnWidths.food}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <StyledInput
                        key={`food-${meal.id}-${item.id}`}
                        value={item.name || ""}
                        onChange={(e) =>
                          handleInputChange(
                            meal.id,
                            item.id,
                            "name",
                            e.target.value
                          )
                        }
                        placeholder="Enter food name"
                        sx={{ flexGrow: 1 }}
                      />
                      <Tooltip title="Find serving suggestions">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setIsDrawerOpen(true);
                            setInitialSearchState({
                              searchTerm: item.name || "",
                              mealId: meal.id,
                              itemIndex,
                            });
                          }}
                          sx={{
                            ml: 1,
                            color: item.name
                              ? Colors.blue[500]
                              : Colors.gray[400],
                            "&:hover": {
                              color: Colors.blue[500],
                            },
                          }}
                        >
                          <SearchIcon
                            fontSize="small"
                            sx={{
                              transform: item.name ? "scale(1.1)" : "scale(1)",
                              transition: "transform 0.2s ease-in-out",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StyledCell>
                  <StyledCell width={columnWidths.portion}>
                    <StyledInput
                      key={`serving-${meal.id}-${item.id}`}
                      value={item.serving || ""}
                      onChange={(e) =>
                        handleInputChange(
                          meal.id,
                          item.id,
                          "serving",
                          e.target.value
                        )
                      }
                      placeholder="e.g. 100, 1"
                    />
                  </StyledCell>
                  <StyledCell width={columnWidths.servingUnit}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      value={item.servingSizeUnit}
                      onChange={(e) =>
                        handleInputChange(
                          meal.id,
                          item.id,
                          "servingSizeUnit",
                          e.target.value
                        )
                      }
                      variant="standard"
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {[
                        { value: "Grams", label: "g" },
                        { value: "Millilitres", label: "ml" },
                        { value: "Ounces", label: "oz" },
                        { value: "Cups", label: "cup" },
                        { value: "Pieces", label: "pc" },
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </StyledCell>
                  <StyledCell width={columnWidths.calories}>
                    <StyledInput
                      key={`calories-${meal.id}-${item.id}`}
                      type="number"
                      value={item.calories || ""}
                      onChange={(e) =>
                        handleInputChange(
                          meal.id,
                          item.id,
                          "calories",
                          e.target.value
                        )
                      }
                      placeholder="Calories"
                    />
                  </StyledCell>
                  <StyledCell width={columnWidths.protein}>
                    <StyledInput
                      key={`protein-${meal.id}-${item.id}`}
                      type="number"
                      value={item.protein || ""}
                      onChange={(e) =>
                        handleInputChange(
                          meal.id,
                          item.id,
                          "protein",
                          e.target.value
                        )
                      }
                      placeholder="Protein"
                    />
                  </StyledCell>
                  <StyledCell width={columnWidths.carbs}>
                    <StyledInput
                      key={`carbs-${meal.id}-${item.id}`}
                      type="number"
                      value={item.carbohydrates || ""}
                      onChange={(e) =>
                        handleInputChange(
                          meal.id,
                          item.id,
                          "carbohydrates",
                          e.target.value
                        )
                      }
                      placeholder="Carbs"
                    />
                  </StyledCell>
                  <StyledCell width={columnWidths.fats}>
                    <StyledInput
                      key={`fats-${meal.id}-${item.id}`}
                      type="number"
                      value={item.fats || ""}
                      onChange={(e) =>
                        handleInputChange(
                          meal.id,
                          item.id,
                          "fats",
                          e.target.value
                        )
                      }
                      placeholder="Fats"
                    />
                  </StyledCell>
                  <StyledCell width={columnWidths.actions}>
                    <IconButton
                      onClick={() => handleDeleteItem(meal.id, item.id)}
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </StyledCell>
                </StyledRow>
              ))}
            </StyledTableContainer>

            <Box sx={{ mt: 2 }}>
              <PrimaryButton
                variant="text"
                color="primary"
                value="Add new item"
                onClick={() => handleAddItem(meal.id)}
                sx={{ textTransform: "none" }}
                startIcon={<AddIcon />}
              />
            </Box>
          </MealContainer>
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: -1,
            position: "relative",
          }}
        >
          <PrimaryButton
            variant="contained"
            color="primary"
            value="Add New Meal"
            onClick={handleAddMeal}
            startIcon={<AddIcon />}
            sx={{
              textTransform: "none",
              padding: "12px 24px",
              fontSize: "16px",
              // Optional: add a hover effect
              "&:hover": {
                transform: "translateY(-2px)",
                transition: "transform 0.2s ease-in-out",
              },
            }}
          />
        </Box>
      </Box>

      <NutritionPlanSettingsSidebar
        isDrawerOpen={isDrawerOpen}
        handleCloseDrawer={() => {
          setIsDrawerOpen(false);
          setInitialSearchState(null);
        }}
        onSelectServing={handleSelectServing}
        meals={meals}
        initialSearchTerm={initialSearchState?.searchTerm}
        initialMealId={initialSearchState?.mealId}
        initialItemIndex={initialSearchState?.itemIndex}
      />
    </Box>
  );
};

export { NutritionPlanView };
