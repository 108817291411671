import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { useGetNotesForUserQuery } from "shared/api";
import {
  IconButton,
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { useGetGoalsForUserQuery } from "shared/api/goals/goals";
import NotesModal from "./NoteModal";
import GoalModal from "./GoalModal";

dayjs.extend(utc);
dayjs.extend(timezone);

type UserProfileGoalsProps = {
  preselectedUserId: number;
};

const UserProfileGoals: FC<UserProfileGoalsProps> = ({
  preselectedUserId: userId,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const goalsContainerRef = useRef<HTMLDivElement>(null);

  // Fetch notes
  const { data: goalsData, isLoading } = useGetGoalsForUserQuery(
    {
      count: 10, // Assuming this fetches all notes
      page: 1,
      userId,
      orderByDescending: true,
    },
    {
      skip: userId == null,
    }
  );

  const goals = goalsData || [];

  // Set up virtualizer
  const rowVirtualizer = useVirtualizer({
    count: goals.length,
    getScrollElement: () => goalsContainerRef.current,
    estimateSize: () => 45,
    overscan: 5,
  });

  if (isLoading) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  const items = rowVirtualizer.getVirtualItems();

  return (
    <Card
      sx={{
        width: "100%",
        bgcolor: Colors.gray[100],
        borderRadius: "8px",
        mb: "16px",
      }}
    >
      <CardContent
        sx={{
          padding: "8px",
          ml: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <OutlinedIcon
            iconName="workspace_premium"
            style={{
              color: `${Colors.blue[1200]}`,
              fontSize: "32px",
              marginRight: "4px",
            }}
          />
          <Typography variant="h6">Goals</Typography>
        </Box>
        <IconButton color="primary" onClick={handleToggleModal}>
          <OutlinedIcon
            iconName="edit"
            style={{ color: `${Colors.blue[1200]}` }}
          />
        </IconButton>
      </CardContent>
      <CardContent sx={{ padding: "8px" }}>
        {/* Goals List */}
        {goals.length > 0 ? (
          <Box
            ref={goalsContainerRef}
            sx={{
              height: "200px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${items[0]?.start ?? 0}px)`,
                }}
              />
              {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                const goal = goals[virtualItem.index];

                return (
                  <Box
                    key={virtualItem.key}
                    ref={rowVirtualizer.measureElement}
                    className={
                      virtualItem.index % 2 ? "ListItemOdd" : "ListItemEven"
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center", // Align items vertically center
                        padding: "12px",
                        gap: 1,
                        height: "45px",
                        textDecoration: "none",
                        color: "inherit",
                        borderBottom: "1px solid",
                        borderColor: Colors.gray[500],
                        "&:hover": {
                          backgroundColor: Colors.gray[200],
                        },
                      }}
                    >
                      {/* Date/Time Typography */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          flexShrink: 0, // Prevent shrinking
                          minWidth: "85px", // Ensure it has enough space
                        }}
                      >
                        {dayjs.utc(goal.dateTime).local().format("D MMM YYYY")}
                      </Typography>

                      {/* Title Typography with Truncation */}
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          flexGrow: 1, // Allow it to take up remaining space
                        }}
                        title={goal.title} // Tooltip on hover
                      >
                        {goal.title}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Add a new goal to get started.
          </Typography>
        )}
        <GoalModal
          handleCloseModal={handleToggleModal}
          isOpenModal={isOpenModal}
          preSelectedUserId={userId}
        />
      </CardContent>
    </Card>
  );
};

export { UserProfileGoals };
