export interface Timeline {
  id: number;
  dateTimeCreated: string;
  dateTimeUpdated: string;
  name: string;
  profileImageUrl: string;
  timelineStatus: string;
  userId: number;
  unreadCount: number;
}

export interface TimelineItem {
  id: number;
  dateTime: string;
  timelineItemType: string;
  title: string;
  name: string;
  profileImageUrl: string;
  mediaUrl: string;
  content: string;
  userId?: number;
  entityId?: number;
  entityType: string;
  timelineItemParentId?: number;
  timelineItemChildren?: TimelineItem[];
  timelineItemLikes?: TimelineItemLike[];
}

export type TimelineItemLike = {
  id: number;
  userId: number;
  timelineItemId: number;
  dateTime: string;
  firstName: string;
  lastName: string;
};

export enum TimelineItemType {
  Undefined = "Undefined",
  Message = "Message",
  WorkoutProgramClientFeedback = "WorkoutProgramClientFeedback",
  Media = "Media",
}

export enum TimelineItemEntityType {
  Workout = "Workout",
  WorkoutProgramNote = "WorkoutProgramNote",
}

export interface TimelineItemsListParams {
  count?: number;
  page?: number;
  orderByDescending?: boolean;
  userId?: number;
  timelineId?: number;
}

export interface TimelineParams {
  page?: number;
  count?: number;
  countOnly?: boolean;
  orderByDescending?: boolean;
  orderByUnreadItems?: boolean;
  userId?: number;
}

export interface CreateTimeLineItemParams {
  timelineItemType: string;
  title: string;
  content: string;
  userId?: number;
  entityId?: number;
  timelineItemParentId?: number;
  timelineId?: number;
  file?: File;
}

export interface UploadMediaParams {
  timelineId?: number;
  uploadFile: File;
}
