import {
  Badge,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useGetUsersQuery,
  useGetUsersCountQuery,
  useClearAllUnreadNotificationsMutation,
} from "shared/api";
import { createUsersManagementRows } from "shared/helpers";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { sharedStyles } from "shared/themes/shared/styles";
import { GridStatusItem } from "components/Grid/GridStatusItem";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import MailIcon from "@mui/icons-material/Mail";
import { LoadingButton } from "@mui/lab";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import SearchIcon from "@mui/icons-material/Search";
import { UserCategoryRadioGroup } from "./UserCategoryRadioGroup";

type UserRow = {
  id: number;
  userFullName: string;
  email: string;
  status: string;
  userCategory: string;
  phoneNumber?: string;
};

const UsersContainer: FC = () => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [userCategoryRadio, setUserCategoryRadio] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [queryString, setQueryString] = useState<string>("");

  const [showSnackbar, setShowSnackbar] = useState(false);

  const { data: usersArrayLength } = useGetUsersCountQuery({
    countOnly: true,
    userRole: "athlete",
    userCategory: userCategoryRadio,
    includeInactive: true,
    queryString,
  });

  const { data: users, isLoading } = useGetUsersQuery({
    count: pageSize,
    page,
    userRole: "athlete",
    userCategory: userCategoryRadio,
    orderByName: true,
    includeInactive: true,
    queryString,
  });
  const [rowCountState, setRowCountState] = useState(usersArrayLength || 0);
  const [rows, setRows] = useState<UserRow[]>([]);

  const [
    trigger,
    { isLoading: isLoadingClearAllNotifications, isSuccess, isError },
  ] = useClearAllUnreadNotificationsMutation();

  const handleClearAllUnreadNotifications = () => {
    trigger();
    setShowSnackbar(true);
  };

  const handleSearch = () => {
    setQueryString(searchValue);
    setPage(1);
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleCategoryChange = (value: string) => {
    setUserCategoryRadio(value);
    setPage(1);
    setQueryString(searchValue);
  };

  useEffect(() => {
    if (!users) return;
    const rowsData = createUsersManagementRows(users);
    setRows(rowsData);
    setRowCountState((prevRowCountState) =>
      usersArrayLength !== undefined ? usersArrayLength : prevRowCountState
    );
  }, [users]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      usersArrayLength !== undefined ? usersArrayLength : prevRowCountState
    );
  }, [usersArrayLength, setRowCountState]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      flex: isMobile ? 0.2 : 0.25,
      minWidth: 50,
      sortable: false,
      renderCell: (params) => {
        return (
          <IdenticonAvatar
            seedValue={params.row?.id}
            profileImageUrl={params.row?.profileImageUrl}
          />
        );
      },
      filterable: false,
    },
    {
      field: "userFullName",
      headerName: t("users-management.user-name"),
      flex: isMobile ? 0.8 : 1,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        return (
          <Stack spacing={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: isMobile ? "0.875rem" : "1rem",
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {params.row?.userFullName}
              </Typography>
              {params.row?.unreadCount > 0 && (
                <Badge
                  badgeContent={params.row?.unreadCount}
                  color="warning"
                  sx={{ ml: 1 }}
                >
                  <MailIcon
                    color="action"
                    sx={{ fontSize: isMobile ? "1rem" : "1.25rem" }}
                  />
                </Badge>
              )}
            </Box>
            {!isMobile && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "0.75rem", marginTop: "-2px" }}
              >
                {params.row?.email && `e: ${params.row.email}`}
                {params.row?.phoneNumber && params.row?.email && " • "}
                {params.row?.phoneNumber && `ph: ${params.row.phoneNumber}`}
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: t("users-management.status"),
      flex: 0.5,
      minWidth: 100,
      sortable: true,
      hide: isMobile,
      renderCell: (params) => {
        return <GridStatusItem status={params.value} />;
      },
    },
    {
      field: "userCategory",
      headerName: t("users-management.user-category"),
      flex: 0.5,
      minWidth: 120,
      sortable: true,
      hide: isMobile,
      renderCell: (params) => {
        return (
          <Box sx={sharedStyles.containers.dataGrid.tagNotStarted}>
            <Box sx={sharedStyles.tags.tagNotStarted} />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: isMobile ? 70 : 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Link to={`${Routes.profile.url}/${id}`}>
            <Button
              color="primary"
              variant="contained"
              sx={{
                textTransform: "none",
                padding: isMobile ? "4px 8px" : "6px 16px",
                fontSize: isMobile ? "0.75rem" : "0.875rem",
              }}
            >
              {t("users-management.edit-btn")}
            </Button>
          </Link>
        );
      },
      filterable: false,
    },
  ];

  const matches = useMediaQuery("(min-width: 1280px)");

  return (
    <Box sx={sharedStyles.containers.dataGrid.wrapper}>
      {!isLoading ? (
        <Box sx={{ height: "700px" }}>
          <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
            {matches && (
              <Typography sx={sharedStyles.headings.dataGrid}>
                Clients
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row" },
                alignItems: { xs: "stretch", sm: "center" },
              }}
            >
              <UserCategoryRadioGroup
                value={userCategoryRadio}
                setValue={handleCategoryChange}
              />
              <TextField
                label="Search by user"
                variant="standard"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleSearchKeyDown}
                sx={{ minWidth: { xs: "50%", sm: "200px" } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleSearch}
                        edge="end"
                        size="small"
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <DataGrid
            headerHeight={56}
            rowHeight={isMobile ? 55 : 65}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows || []}
            rowCount={rowCountState}
            paginationMode="server"
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                padding: isMobile ? "0 8px" : "0 16px",
              },
            }}
          />
          <LoadingButton
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleClearAllUnreadNotifications}
            disabled={isLoading || isLoadingClearAllNotifications}
            loading={isLoading || isLoadingClearAllNotifications}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              paddingRight: "16px",
              mt: 2,
            }}
          >
            {t("timeline.mark-all-as-read")}
          </LoadingButton>
          {isSuccess && showSnackbar && (
            <SnackbarAlert
              isOpen={isSuccess}
              onClose={() => setShowSnackbar(false)}
              severity="success"
              message="All notifications have been marked as read."
            />
          )}
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};

export { UsersContainer };
