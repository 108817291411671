/* eslint-disable no-nested-ternary */
import { Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { useGetAthleteDashboardWorkoutsQuery } from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";
import { WorkoutPageSkeleton } from "./WorkoutPageSkeleton";
import { WorkoutList } from "./WorkoutList";

interface CompletedWorkoutsProps {
  existingWorkoutProgramId: number;
}

const CompletedWorkouts: FC<CompletedWorkoutsProps> = ({
  existingWorkoutProgramId,
}) => {
  dayjs.extend(advancedFormat);
  const userId = useTypedSelector(userSelectors.userId);

  const { data, isLoading, refetch } = useGetAthleteDashboardWorkoutsQuery(
    {
      userId,
      workoutProgramId: existingWorkoutProgramId ?? 0,
    },
    {
      skip: !existingWorkoutProgramId,
    }
  );
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const { t } = useTranslation();

  useEffect(() => {
    setActiveIndex(data?.currentWorkout ? 0 : 1);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const workoutProgramWeeksFrequency =
    data?.curentWorkoutProgram?.weeksInProgram === 1
      ? "1 week"
      : `${data?.curentWorkoutProgram?.weeksInProgram} weeks`;

  const workoutProgramDaysFrequency =
    data?.curentWorkoutProgram?.daysPerWeek === 1
      ? "1 day/week"
      : `${data?.curentWorkoutProgram?.daysPerWeek} days/week`;

  const workoutProgramFrequencyText = `${workoutProgramWeeksFrequency}, ${workoutProgramDaysFrequency}`;

  const navigate = useNavigate();

  const goBack = () => {
    navigate(`${Routes.workouts.url}`);
  };

  return !isLoading ? (
    <Box sx={{ height: "88%", overflowY: "auto" }}>
      <LoadingButton
        variant="outlined"
        color="secondary"
        sx={{
          height: "36px",
          mt: "5px",
          textAlign: "right",
        }}
        fullWidth
        type="button"
        size="small"
        onClick={() => goBack()}
      >
        <Typography
          sx={{
            textTransform: "none",
            fontSize: "14px",
          }}
        >
          Click here to return to Programs.
        </Typography>
      </LoadingButton>
      <Box
        sx={{
          padding: "9px 24px 23px 24px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h2"
            sx={{
              height: "34px",

              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              color: `${Colors.blue[1500]}`,
            }}
          >
            <OutlinedIcon
              iconName="check_circle"
              style={{
                color: `${Colors.blue[1200]}`,
                fontSize: "20px",
              }}
            />
            {data?.curentWorkoutProgram?.name ||
              t("athlete-dashboard.no-program")}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                ...sharedStyles.body.timeline.cardBody,
                color: `${Colors.red[200]}`,
              }}
            >
              This workout program is complete.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={sharedStyles.body.timeline.cardBody}>
              Start Date:{" "}
              {dayjs(data?.curentWorkoutProgram?.startDate).format(
                "Do MMMM YY"
              )}
            </Typography>
            <Typography sx={sharedStyles.body.timeline.cardBody}>
              {workoutProgramFrequencyText}
            </Typography>
          </Box>
        </Box>

        {data?.allWorkouts?.length !== 0 && (
          <Collapse in timeout="auto" unmountOnExit>
            <WorkoutList
              workouts={data?.allWorkouts ?? []}
              isCompleted
              isWorkoutProgramCompleted
            />
          </Collapse>
        )}
      </Box>
    </Box>
  ) : (
    <WorkoutPageSkeleton />
  );
};

export { CompletedWorkouts };
