/* eslint-disable */
import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const SkeletonAnimation = styled("div")({
  animation: "skeleton 1.5s ease-in-out infinite",
  "@keyframes skeleton": {
    "0%": { backgroundPosition: "-200px 0" },
    "100%": { backgroundPosition: "200px 0" },
  },
});

const SkeletonBox = styled(Box)({
  backgroundColor: "#e0e0e0",
  backgroundImage:
    "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
  backgroundSize: "200% 100%",
  borderRadius: "4px",
  height: "100%",
  width: "100%",
});

export default function LoadingSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
        maxWidth: "400px",
        padding: "16px",
      }}
    >
      <Box sx={{ fontSize: "18px", fontWeight: "bold" }}>Loading...</Box>
      <SkeletonBox
        component={SkeletonAnimation}
        sx={{ height: "40px", width: "100%" }}
      />
      <SkeletonBox
        component={SkeletonAnimation}
        sx={{ height: "20px", width: "60%" }}
      />
      <SkeletonBox
        component={SkeletonAnimation}
        sx={{ height: "20px", width: "80%" }}
      />
      <SkeletonBox
        component={SkeletonAnimation}
        sx={{ height: "20px", width: "90%" }}
      />
    </Box>
  );
}
