import { FC } from "react";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useGetNutritionPlanByIdQuery } from "shared/api";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Colors } from "shared/themes";
import dayjs from "dayjs";
import { MacroChart } from "./NutritionPlanView";

const AthleteNutritionView: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams();
  const planId = parseInt(params.nutritionPlanId!, 10);

  const { data: nutritionPlan, isLoading } = useGetNutritionPlanByIdQuery(
    planId,
    {
      skip: !planId,
    }
  );

  if (isLoading) return <ProgramsSkeleton />;

  if (!nutritionPlan || !nutritionPlan.userNutritionPlanMeals) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography variant="h6" color={Colors.gray[600]}>
          Nutrition plan not found
        </Typography>
      </Box>
    );
  }

  const calculateTotalMacros = () => {
    const totals = { calories: 0, protein: 0, carbs: 0, fats: 0 };
    nutritionPlan?.userNutritionPlanMeals?.forEach((meal) => {
      meal.userNutritionPlanMealItems.forEach((item) => {
        totals.calories += item.calories || 0;
        totals.protein += item.protein || 0;
        totals.carbs += item.carbohydrates || 0;
        totals.fats += item.fats || 0;
      });
    });
    return totals;
  };

  const totals = calculateTotalMacros();

  return (
    <Box sx={{ p: isMobile ? 2 : 3, pb: "60px" }}>
      {/* Header Section */}
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            color: Colors.gray[900],
            mb: 1,
            fontFamily: "Inter",
          }}
        >
          {nutritionPlan.name}
        </Typography>
        {nutritionPlan.description && (
          <Typography variant="body2" color={Colors.gray[600]} sx={{ mb: 1 }}>
            {nutritionPlan.description}
          </Typography>
        )}
        <Typography variant="body2" color={Colors.gray[600]}>
          {nutritionPlan.startDate &&
            dayjs(nutritionPlan.startDate).format("MMM D, YYYY")}
          {nutritionPlan.endDate &&
            ` - ${dayjs(nutritionPlan.endDate).format("MMM D, YYYY")}`}
        </Typography>
      </Box>

      {/* Macro Summary - Always visible */}
      {nutritionPlan.userNutritionPlanMeals && (
        <>
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Daily Totals
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 1,
                      bgcolor: Colors.gray[50],
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" color={Colors.gray[600]}>
                      Calories
                    </Typography>
                    <Typography variant="h6">
                      {totals.calories.toFixed(0)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 1,
                      bgcolor: Colors.gray[50],
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" color={Colors.gray[600]}>
                      Protein
                    </Typography>
                    <Typography variant="h6">
                      {totals.protein.toFixed(1)}g
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 1,
                      bgcolor: Colors.gray[50],
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" color={Colors.gray[600]}>
                      Carbs
                    </Typography>
                    <Typography variant="h6">
                      {totals.carbs.toFixed(1)}g
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 1,
                      bgcolor: Colors.gray[50],
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" color={Colors.gray[600]}>
                      Fats
                    </Typography>
                    <Typography variant="h6">
                      {totals.fats.toFixed(1)}g
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Macro Distribution by Meal
              </Typography>
              <MacroChart meals={nutritionPlan.userNutritionPlanMeals} />
            </CardContent>
          </Card>
        </>
      )}

      {/* Meals Breakdown - Always visible */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: Colors.gray[900],
          mb: 2,
          fontFamily: "Inter",
        }}
      >
        Meal Plan
      </Typography>
      {nutritionPlan.userNutritionPlanMeals?.map((meal) => (
        <Card key={meal.id} sx={{ mb: 2 }}>
          <CardContent>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: Colors.gray[900], mb: 2 }}
            >
              {meal.name}
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Food</TableCell>
                    <TableCell align="right">Serving</TableCell>
                    {!isMobile && <TableCell align="right">Calories</TableCell>}
                    <TableCell align="right">P/C/F (g)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meal.userNutritionPlanMealItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">{item.name}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">{item.serving}</Typography>
                      </TableCell>
                      {!isMobile && (
                        <TableCell align="right">
                          <Typography variant="body2">
                            {item.calories}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <Typography variant="body2">
                          {item.protein}/{item.carbohydrates}/{item.fats}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export { AthleteNutritionView };
