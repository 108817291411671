import { Capacitor } from "@capacitor/core";
import OneSignal from "onesignal-cordova-plugin";
import { Device } from "@capacitor/device";

// Define the OSNotificationPermission enum
enum OSNotificationPermission {
  NotDetermined = 0,
  Denied = 1,
  Authorized = 2,
}

class OneSignalService {
  private static instance: OneSignalService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): OneSignalService {
    if (!OneSignalService.instance) {
      OneSignalService.instance = new OneSignalService();
    }
    return OneSignalService.instance;
  }

  /**
   * Initializes OneSignal based on the platform.
   */
  async oneSignalInit() {
    if (Capacitor.isNativePlatform()) {
      document.addEventListener(
        "deviceready",
        () => {
          this.initOneSignal();
        },
        false
      );
    }
  }

  /**
   * Sets up OneSignal configurations and event listeners.
   */
  private initOneSignal() {
    OneSignal.Debug.setLogLevel(6);
    OneSignal.initialize(process.env.REACT_APP_ONESIGNAL_APP_ID!);

    OneSignal.Notifications.addEventListener("click", async (e: any) => {
      const clickData = await e.notification;
      console.log("Notification Clicked:", clickData);
    });

    // Handle permissions after initialization
    this.handlePermissions();
  }

  /**
   * Handles OneSignal notification permissions for both iOS and Android.
   */
  public async handlePermissions() {
    try {
      const platform = Capacitor.getPlatform();
      if (platform === "ios") {
        await this.requestIosPermission();
      } else if (platform === "android") {
        await this.requestAndroidPermission();
      } else {
        // Handle other platforms if necessary
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Requests notification permission on iOS.
   */
  // eslint-disable-next-line class-methods-use-this
  private async requestIosPermission() {
    const iosPermission = await OneSignal.Notifications.permissionNative();
    if (iosPermission === OSNotificationPermission.NotDetermined) {
      // Request permission for the first time
      const accepted = await OneSignal.Notifications.requestPermission(true);
      console.log("iOS notification permission granted:", accepted);
    } else {
      console.log("iOS notification permission status:", iosPermission);
    }
  }

  /**
   * Requests notification permission on Android 13 and above.
   */
  // eslint-disable-next-line class-methods-use-this
  private async requestAndroidPermission() {
    const info = await Device.getInfo();
    const version = parseInt(info.osVersion.split(".")[0], 10);
    if (version >= 13) {
      const hasPermission = await OneSignal.Notifications.hasPermission();
      console.log("Android notification permission status:", hasPermission);
      if (!hasPermission) {
        // Request permission for the first time
        const accepted = await OneSignal.Notifications.requestPermission(true);
        console.log("Android notification permission granted:", accepted);
      }
    } else {
      // For Android versions below 13, permissions are granted at install time
      console.log(
        "Android version below 13, permission is granted at install time."
      );
    }
  }

  /**
   * Logs in the user with OneSignal using their UID.
   * @param uid User ID.
   */
  static login(uid: string) {
    OneSignal.login(uid);
  }

  /**
   * Logs out the user from OneSignal
   */
  static logout() {
    OneSignal.logout();
  }
}

export default OneSignalService;
