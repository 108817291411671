import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Modal,
  Typography,
  Icon,
  Fade,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";

interface SavingProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SavingProgramModal: FC<SavingProgramModalProps> = ({
  isOpenModal,
  handleCloseModal,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenModal}
      onClose={handleCloseModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: () => {},
        },
      }}
    >
      <Fade in={isOpenModal}>
        <Box sx={style}>
          <Icon
            onClick={handleCloseModal}
            sx={{
              color: `${Colors.gray[1400]}`,
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            close_icon
          </Icon>
          <Typography sx={sharedStyles.body.modalDescription}>
            Hold on while we save your work. It could take a minute... Please
            keep this window open and avoid navigating away.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress size={80} />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export { SavingProgramModal };
