import { Box, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartRateIcon from "../../../assets/heart-rate.svg";

interface ReadinessCardProps {
  averageRestingHeartRate: number | undefined;
}

export const ReadinessCard: React.FC<ReadinessCardProps> = ({
  averageRestingHeartRate = 0,
}) => {
  if (!averageRestingHeartRate) return null;

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "#f5f5f5",
        borderRadius: "12px",
        textAlign: "left",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <FavoriteIcon sx={{ color: "rgb(255, 99, 132)", mr: 0.5 }} />
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "bold",
            color: "rgb(255, 99, 132)",
          }}
        >
          {Math.round(averageRestingHeartRate)}
        </Typography>
        <Typography
          sx={{ fontSize: "0.9rem", color: "rgb(255, 99, 132)", ml: 0.5 }}
        >
          BPM AVG
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          backgroundImage: `url(${HeartRateIcon})`,
          backgroundRepeat: "repeat-x",
          backgroundPosition: "center",
          backgroundSize: "120px 100%",
          minHeight: "50px",
          height: "50px",
        }}
      />
    </Box>
  );
};
