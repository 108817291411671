import { Box } from "@mui/system";
import { FC } from "react";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { AthleteTrainingInsightsContent } from "./AthleteTrainingInsights/AthleteTrainingInsightsContent";

const AthleteTrainingInsights: FC = () => {
  const userId = useTypedSelector(userSelectors.userId);
  return (
    <Box
      sx={{
        width: "100%",
        height: "90vh",
      }}
    >
      <AthleteTrainingInsightsContent preselectedUserId={userId!} />
    </Box>
  );
};

export { AthleteTrainingInsights };
