import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateGoalParams,
  Goal,
  GoalsCountParams,
  GoalsListParams,
  UpdateGoalParams,
} from "./models";

export const goalsApi = createApi({
  baseQuery,
  reducerPath: "goalsApi",
  tagTypes: ["Goals", "GoalsCount"],
  endpoints: (builder) => ({
    addGoal: builder.mutation<Goal, CreateGoalParams>({
      query: (params) => ({
        url: "/user-goals",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Goals"],
    }),
    getGoalById: builder.query<Goal, number>({
      query: (id) => ({
        url: `/user-goals/${id}`,
        method: "GET",
      }),
      providesTags: ["Goals"],
    }),
    getGoalsForUser: builder.query<Goal[], GoalsListParams>({
      query: (params) => ({
        url: `/user-goals`,
        method: "GET",
        params,
      }),
      providesTags: ["Goals"],
    }),
    getGoalsCount: builder.query<number, GoalsCountParams>({
      query: (params) => ({
        url: `/user-goals`,
        method: "GET",
        params: {
          countOnly: true,
          userId: params.userId,
        },
      }),
      providesTags: ["GoalsCount"],
    }),
    updateGoal: builder.mutation<Goal, UpdateGoalParams>({
      query: (params) => ({
        url: `/user-goals/${params.id}`,
        method: "PATCH",
        body: params,
      }),
      invalidatesTags: ["Goals"],
    }),
  }),
});

export const {
  useAddGoalMutation,
  useGetGoalByIdQuery,
  useGetGoalsForUserQuery,
  useGetGoalsCountQuery,
  useUpdateGoalMutation,
} = goalsApi;
