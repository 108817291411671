import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Chart from "react-apexcharts";
import {
  Typography,
  Icon,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import { useTypedDispatch } from "shared/stores";
import { clientInsightsApi, ClientInsightsEngineResponse } from "shared/api";

interface ClientInsightsProps {
  selectedUserId: number | undefined;
  fullWidth?: boolean;
  showTitle?: boolean;
  showFatigue?: boolean;
  showVolume?: boolean;
  showRating?: boolean;
}

const ClientInsights: FC<ClientInsightsProps> = ({
  selectedUserId,
  fullWidth,
  showTitle,
  showFatigue,
  showVolume,
  showRating,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [clientInsight, setClientInsight] =
    useState<ClientInsightsEngineResponse>();

  const getClientInsight =
    clientInsightsApi.endpoints.getClientInsightsEngine.initiate(
      selectedUserId!!,
      { forceRefetch: true }
    );

  const weeklyTrainingVolumes = {
    series: [
      {
        name: "Volume",
        type: "line",
        // eslint-disable-next-line
        data: clientInsight?.weeklyTrainingVolumes?.map((ms) => (ms ? ms : 0)),
      },
    ],
  };

  const weeklyTrainingRatings = {
    series: [
      {
        name: "Rating",
        type: "line",
        // eslint-disable-next-line
        data: clientInsight?.weeklyTrainingRatings?.map((ms) => (ms ? ms : 0)),
      },
    ],
  };

  const weeklyFatigueLevels = {
    series: [
      {
        name: "Fatigue",
        type: "line",
        // eslint-disable-next-line
        data: clientInsight?.weeklyFatigueLevels?.map((ms) => (ms ? ms : 0)),
      },
    ],
  };

  async function callGetClientInsights() {
    try {
      setErrorMessage("");
      setSuccessMessage("");
      setIsLoading(true);
      const response = await dispatch(getClientInsight);
      setClientInsight(response.data ?? undefined);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUserId !== null) {
      setIsLoading(true);
      setClientInsight(undefined);
      setErrorMessage("");
      setSuccessMessage("");
    }
    if (selectedUserId) callGetClientInsights();
  }, [selectedUserId]);

  return (
    // eslint-disable-next-line
    <>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ height: "131px", width: "100%", mb: "12px" }}
        />
      ) : (
        <>
          {showTitle && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  lineHeight: "24px",
                }}
              >
                Insights are based on the last 3 programs of training data
              </Typography>
            </Grid>
          )}
          {showFatigue && (
            <Grid item xs={12} md={fullWidth ? 12 : 4}>
              <Card
                sx={{
                  width: "100%",
                  mb: "12px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                      }}
                    >
                      Fatigue
                    </Typography>
                  }
                  action={
                    <Icon
                      sx={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => callGetClientInsights()}
                    >
                      refresh
                    </Icon>
                  }
                  sx={{
                    borderBottom: "1px solid #E5E5E5",
                    padding: "16px 20px",
                  }}
                />
                <CardContent
                  sx={{
                    padding: "0 20px",
                    mt: 1,
                  }}
                >
                  {clientInsight?.fatigueTrendInsight ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            clientInsight?.fatigueTrendInsight
                          ),
                        }}
                      />
                      {weeklyFatigueLevels && (
                        <Chart
                          options={{
                            chart: {
                              id: "fatigueTrendInsights",
                              toolbar: {
                                show: false,
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            grid: {
                              show: false,
                            },
                            colors: ["#4245FF"],
                            xaxis: {
                              labels: {
                                show: false,
                              },
                            },
                          }}
                          series={weeklyFatigueLevels.series as any}
                          type="line"
                          width="100%"
                          height="150"
                        />
                      )}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          mt: 1,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            clientInsight?.predictedFatigueStatus
                          ),
                        }}
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      No data available
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {showVolume && (
            <Grid item xs={12} md={fullWidth ? 12 : 4}>
              <Card
                sx={{
                  width: "100%",
                  mb: "12px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                      }}
                    >
                      Volume
                    </Typography>
                  }
                  action={
                    <Icon
                      sx={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => callGetClientInsights()}
                    >
                      refresh
                    </Icon>
                  }
                  sx={{
                    borderBottom: "1px solid #E5E5E5",
                    padding: "16px 20px",
                  }}
                />
                <CardContent
                  sx={{
                    padding: "0 20px",
                    mt: 1,
                  }}
                >
                  {clientInsight?.trainingVolumeTrendInsight ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            clientInsight?.trainingVolumeTrendInsight
                          ),
                        }}
                      />
                      <Chart
                        options={{
                          chart: {
                            id: "trainingVolumeTrendInsights",
                            toolbar: {
                              show: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          grid: {
                            show: false,
                          },
                          colors: ["#4245FF"],
                          xaxis: {
                            labels: {
                              show: false,
                            },
                          },
                        }}
                        series={weeklyTrainingVolumes.series as any}
                        type="line"
                        width="100%"
                        height="150"
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      No data available
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {showRating && (
            <Grid item xs={12} md={fullWidth ? 12 : 4}>
              <Card
                sx={{
                  width: "100%",
                  mb: "12px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                      }}
                    >
                      Training Rating
                    </Typography>
                  }
                  action={
                    <Icon
                      sx={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => callGetClientInsights()}
                    >
                      refresh
                    </Icon>
                  }
                  sx={{
                    borderBottom: "1px solid #E5E5E5",
                    padding: "16px 20px",
                  }}
                />
                <CardContent
                  sx={{
                    padding: "0 20px",
                    mt: 1,
                  }}
                >
                  {clientInsight?.trainingRatingTrendInsight ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            clientInsight?.trainingRatingTrendInsight
                          ),
                        }}
                      />
                      <Chart
                        options={{
                          chart: {
                            id: "trainingRatingTrendInsights",
                            toolbar: {
                              show: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          grid: {
                            show: false,
                          },
                          colors: ["#4245FF"],
                          xaxis: {
                            labels: {
                              show: false,
                            },
                          },
                        }}
                        series={weeklyTrainingRatings.series as any}
                        type="line"
                        width="100%"
                        height="150"
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      No data available
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export { ClientInsights };
