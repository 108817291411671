import { Button } from "@mui/material";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import { WorkoutProgramStatus } from "shared/api";
import { AnyType } from "shared/helpers";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";
import { StatusItem } from "../ui/StatusItem";

export enum Status {
  isActive = "Active",
  isInactive = "Inactive",
}

export const statusItemProps: AnyType = {
  [WorkoutProgramStatus.IN_PROGRESS]: {
    background: `${Colors.yellow[100]}`,
    color: `${Colors.green[600]}`,
    title: "programs.in-progress",
  },
  [WorkoutProgramStatus.NOT_STARTED]: {
    background: `${Colors.blue[1900]}`,
    color: `${Colors.blue[1800]}`,
    title: "programs.not-started",
  },
  [WorkoutProgramStatus.COMPLETED]: {
    background: `${Colors.green[700]}`,
    color: `${Colors.green[800]}`,
    title: "programs.completed",
  },
  [WorkoutProgramStatus.DRAFT]: {
    background: `${Colors.gray[1800]}`,
    color: `${Colors.gray[2000]}`,
    title: "programs.draft",
  },
};

export const currentUserColumns: GridColDef[] = [
  {
    field: "nameOfProgram",
    headerName: "Name of Program",
    flex: 1,
    sortable: false,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
    sortable: false,
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => {
      return <StatusItem status={params.value} />;
    },
  },
  {
    field: "menu",
    headerName: "",
    minWidth: 50,
    flex: 0,
    sortable: false,
    renderCell: (params) => {
      return (
        <NavLink
          to={`${Routes.modernWorkoutProgramBuilder.url}/${params.row.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              background: `${Colors.blue[1200]}`,
              color: `${Colors.gray[100]}`,
              "&.MuiButtonBase-root:hover": {
                bgcolor: `${Colors.blue[1200]}`,
              },
            }}
          >
            View
          </Button>
        </NavLink>
      );
    },
  },
];

export const workoutTemplatesColumns: GridColDef[] = [
  { field: "programName", headerName: "Name of Program", width: 280 },
  { field: "goal", headerName: "Goal", width: 212 },
  { field: "split", headerName: "Session split", width: 186 },
  { field: "lastModified", headerName: "Last modified", width: 208 },
];

export const workoutTemplatesRows: GridRowsProp = [
  {
    id: 1,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "1 days/week",
    lastModified: "1 December 2022",
  },
  {
    id: 2,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "2 days/week",
    lastModified: "2 December 2022",
  },
  {
    id: 3,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "3 days/week",
    lastModified: "3 December 2022",
  },
  {
    id: 4,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "4 days/week",
    lastModified: "4 December 2022",
  },
  {
    id: 5,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "5 days/week",
    lastModified: "5 December 2022",
  },
  {
    id: 6,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "6 days/week",
    lastModified: "6 December 2022",
  },
  {
    id: 7,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "7 days/week",
    lastModified: "7 December 2022",
  },
  {
    id: 8,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "8 days/week",
    lastModified: "8 December 2022",
  },
  {
    id: 9,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "9 days/week",
    lastModified: "9 December 2022",
  },
  {
    id: 10,
    programName: "Sport training program",
    goal: "Increase strength",
    split: "10 days/week",
    lastModified: "10 December 2022",
  },
];
