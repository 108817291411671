import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateNutritionPlanParams,
  Food,
  NutritionPlanCountParams,
  NutritionPlanListParams,
  SearchFoodsParams,
  UserNutritionPlanResponse,
} from "./models";

export const nutritionPlansApi = createApi({
  baseQuery,
  reducerPath: "nutritionPlansApi",
  tagTypes: ["NutritionPlans", "NutritionPlanCount"],
  endpoints: (builder) => ({
    searchFoods: builder.query<Food[], SearchFoodsParams>({
      query: (params) => ({
        url: "/nutrition-plans/search-foods",
        method: "GET",
        params,
      }),
    }),

    getNutritionPlans: builder.query<
      UserNutritionPlanResponse[],
      NutritionPlanListParams
    >({
      query: (params) => ({
        url: "/nutrition-plans",
        method: "GET",
        params,
      }),
      providesTags: ["NutritionPlans"],
    }),

    getNutritionPlansCount: builder.query<number, void>({
      query: () => ({
        url: "/nutrition-plans",
        method: "GET",
        params: {
          countOnly: true,
        },
      }),
      providesTags: ["NutritionPlanCount"],
    }),

    getNutritionPlanById: builder.query<UserNutritionPlanResponse, number>({
      query: (id) => ({
        url: `/nutrition-plans/${id}`,
        method: "GET",
      }),
      providesTags: ["NutritionPlans"],
    }),

    createNutritionPlan: builder.mutation<
      UserNutritionPlanResponse,
      Omit<CreateNutritionPlanParams, "id">
    >({
      query: (params) => ({
        url: "/nutrition-plans",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["NutritionPlans", "NutritionPlanCount"],
    }),

    updateNutritionPlan: builder.mutation<
      UserNutritionPlanResponse,
      CreateNutritionPlanParams
    >({
      query: (params) => ({
        url: `/nutrition-plans/${params.id}`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: ["NutritionPlans"],
    }),

    updateNutritionPlanStatus: builder.mutation<
      UserNutritionPlanResponse,
      { nutritionPlanId: number; nutritionPlanStatus: string }
    >({
      query: (params) => ({
        url: `/nutrition-plans/${params.nutritionPlanId}/status`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: ["NutritionPlans"],
    }),
  }),
});

export const {
  useSearchFoodsQuery,
  useGetNutritionPlansQuery,
  useGetNutritionPlansCountQuery,
  useGetNutritionPlanByIdQuery,
  useCreateNutritionPlanMutation,
  useUpdateNutritionPlanMutation,
  useUpdateNutritionPlanStatusMutation,
} = nutritionPlansApi;
