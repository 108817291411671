import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Typography,
  Paper,
  Divider,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useGetUserProfileItemQuery, useLogoutMutation } from "shared/api";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Routes } from "shared/routers";
import { Device } from "@capacitor/device";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import SahhaService from "../../../SahhaService";
import { SuccessfulUpdateModal } from "./ui/modals/SuccessfulUpdateModal";
import { UserProfileSkeleton } from "./ui/skeletons/UserProfileSkeleton";
import { UpdateUserForm } from "./ui/UpdateUserForm";

const FeatureCard = ({
  icon,
  title,
  description,
  onClick,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick: () => void;
}) => (
  <Paper
    elevation={0}
    sx={{
      backgroundColor: "white",
      border: `1px solid ${Colors.gray[200]}`,
      borderRadius: 2,
      p: 2,
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        borderColor: Colors.blue[300],
      },
    }}
    onClick={onClick}
  >
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Box
        sx={{
          borderRadius: 1,
          p: 1,
          mr: 2,
          color: Colors.blue[500],
        }}
      >
        {icon}
      </Box>
      <Typography variant="h6" sx={{ color: Colors.gray[900] }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ color: Colors.gray[600] }}>
      {description}
    </Typography>
  </Paper>
);

const AthleteUserProfile: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [isMobileApp, setIsMobileApp] = useState(false);

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    setIsMobileApp(device.platform === "android" || device.platform === "ios");
  };

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(params.userId));

  const [successfulUpdateModal, setSuccessfulUpdateModal] = useState(false);
  const toggleSuccessfulUpdateModal = () => {
    setSuccessfulUpdateModal((prev) => !prev);
  };

  const onLogout = async () => {
    try {
      await logout().unwrap();
      navigate(Routes.signIn.url);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleOpenAppSettings = async () => {
    try {
      if (isMobileApp) {
        await SahhaService.openAppSettings();
      }
    } catch (error) {
      console.error("Error opening app settings:", error);
    }
  };

  useEffect(() => {
    checkDeviceIsMobileApp();
  }, []);

  if (isLoadingCurrentUserData || !currentUserData) {
    return <UserProfileSkeleton />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: Colors.gray[50],
        pt: 2,
        paddingBottom: "40px",
      }}
    >
      <Box sx={{ flex: 1, overflow: "auto", p: 3 }}>
        <SuccessfulUpdateModal
          successfulUpdateModal={successfulUpdateModal}
          toggleModal={toggleSuccessfulUpdateModal}
        />

        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <IdenticonAvatar
            sizeValue={80}
            seedValue={currentUserData.id.toString()}
            profileImageUrl={currentUserData.profileImageUrl || ""}
            allowUpload
          />
          <Box sx={{ ml: 2 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                color: Colors.gray[900],
                mb: 0.5,
              }}
            >
              {currentUserData?.firstName} {currentUserData?.lastName}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: Colors.gray[600],
              }}
            >
              {currentUserData?.email}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FeatureCard
              icon={<RestaurantMenuIcon />}
              title="Nutrition Plans"
              description="View and manage your personalized nutrition plans, track your meals, and monitor your progress."
              onClick={() => navigate(Routes.athleteNutrition.url)}
            />

            <Box sx={{ mt: 3 }}>
              <FeatureCard
                icon={<Icon>calendar_today</Icon>}
                title="My Bookings"
                description="View and manage your scheduled appointments and training sessions."
                onClick={() => navigate(Routes.athleteBookings.url)}
              />
            </Box>

            {isMobileApp && (
              <Box sx={{ mt: 3 }}>
                <FeatureCard
                  icon={<SettingsIcon />}
                  title="App Settings"
                  description="Manage your app permissions and preferences"
                  onClick={handleOpenAppSettings}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Accordion
              elevation={0}
              sx={{
                backgroundColor: "white",
                border: `1px solid ${Colors.gray[200]}`,
                borderRadius: 2,
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  borderBottom: `1px solid ${Colors.gray[200]}`,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      ml: 1,
                      p: 1,
                      mr: 2,
                      color: Colors.blue[500],
                    }}
                  >
                    <SettingsIcon />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: Colors.gray[900],
                    }}
                  >
                    Profile Information
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <UpdateUserForm
                  currentUserData={currentUserData}
                  toggleSuccessfulUpdateModal={toggleSuccessfulUpdateModal}
                  isAthlete
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <LoadingButton
          type="button"
          size="large"
          onClick={onLogout}
          variant="outlined"
          color="secondary"
          sx={{
            textTransform: "none",
            color: `${Colors.gray[900]}`,
            borderColor: `${Colors.gray[900]}`,
            mt: 2,
          }}
          fullWidth
          startIcon={
            <Icon
              sx={{ color: `${Colors.gray[900]}`, cursor: "pointer" }}
              fontSize="small"
            >
              logout
            </Icon>
          }
        >
          Log out
        </LoadingButton>
      </Box>
    </Box>
  );
};

export { AthleteUserProfile };
