// BookingScheduleCalendarView.tsx

import React, { FC, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Booking, BookingGroup, ClientResponse } from "shared/api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getGraphicSvg } from "shared/themes";
import { UpdateBookingModal } from "./UpdateBookingModal";

dayjs.extend(utc);
dayjs.extend(timezone);

interface BookingScheduleCalendarViewProps {
  bookingGroups: BookingGroup[] | undefined;
  athleteUsers: ClientResponse[] | undefined;
  nonAthleteUsers: ClientResponse[] | undefined;
  bookings: Booking[] | undefined;
  setBookingsStartDate: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setBookingsEndDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const BookingScheduleCalendarView: FC<BookingScheduleCalendarViewProps> = ({
  bookingGroups,
  athleteUsers,
  nonAthleteUsers,
  bookings,
  setBookingsStartDate,
  setBookingsEndDate,
}) => {
  const calendarSvg = getGraphicSvg("calendar");

  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  // State to keep track of selected group types
  const [selectedGroupTypes, setSelectedGroupTypes] = useState<number[]>([]);

  const [selectedCoaches, setSelectedCoaches] = useState<number[]>([]);

  // State for date filtering
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  const handleLoadMoreBookings = (newDate: dayjs.Dayjs) => {
    // Get the previous Sunday and next Saturday
    const prevSunday = newDate.startOf("week");
    const nextSaturday = newDate.endOf("week");

    // Update the booking date range
    setBookingsStartDate(prevSunday.format("YYYY-MM-DD"));
    setBookingsEndDate(nextSaturday.format("YYYY-MM-DD"));
  };

  const handleCloseBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  const handleGroupTypeChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedGroupTypes(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };

  const handleCoachChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedCoaches(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };

  // Filter bookings based on selected group types, coaches, and dates
  const filteredBookings = bookings
    ? bookings.filter((booking) => {
        const bookingDate = dayjs(booking.startDateTime);
        // Check if booking is within date range
        const isWithinDateRange =
          (!startDate || bookingDate.isSameOrAfter(startDate, "day")) &&
          (!endDate || bookingDate.isSameOrBefore(endDate, "day"));
        const matchesGroupType =
          selectedGroupTypes.length === 0 ||
          (booking.bookingGroupId !== null &&
            selectedGroupTypes.includes(booking.bookingGroupId));
        const matchesCoach =
          selectedCoaches.length === 0 ||
          (booking.coachAttendees &&
            booking.coachAttendees.some((coach) =>
              selectedCoaches.includes(coach.id)
            ));

        return isWithinDateRange && matchesGroupType && matchesCoach;
      })
    : [];

  // Prepare events for react-big-calendar
  const events = filteredBookings.map((booking) => ({
    title: booking.name,
    start: booking.startDateTime
      ? dayjs.tz(booking.startDateTime, dayjs.tz.guess()).utc().toDate()
      : new Date(),
    end: booking.endDateTime
      ? dayjs.tz(booking.endDateTime, dayjs.tz.guess()).utc().toDate()
      : new Date(),
    resource: booking,
  }));
  const localizer = dayjsLocalizer(dayjs);

  // Add this function before the return statement
  const eventStyleGetter = (event: any) => {
    // Generate a consistent color based on the bookingGroupItemId
    const colors = [
      "#FF6B6B",
      "#4ECDC4",
      "#45B7D1",
      "#96CEB4",
      "#D4A5A5",
      "#9B59B6",
      "#3498DB",
      "#E67E22",
      "#1ABC9C",
    ];

    const colorIndex = event.resource.bookingGroupId % colors.length;

    return {
      style: {
        backgroundColor: colors[colorIndex],
        borderRadius: "4px",
      },
    };
  };

  return (
    <Box>
      {/* Filters */}
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="group-type-filter-label">
            Filter by Group Type
          </InputLabel>
          <Select
            labelId="group-type-filter-label"
            id="group-type-filter"
            multiple
            value={selectedGroupTypes}
            onChange={handleGroupTypeChange}
            renderValue={(selected) =>
              bookingGroups
                ?.filter((group) => selected.includes(group.id))
                .map((group) => group.name)
                .join(", ") || "All"
            }
          >
            {bookingGroups?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                <Checkbox checked={selectedGroupTypes.indexOf(group.id) > -1} />
                <ListItemText primary={group.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="group-type-filter-label">Filter by Coach</InputLabel>
          <Select
            labelId="group-type-filter-label"
            id="group-type-filter"
            multiple
            value={selectedCoaches}
            onChange={handleCoachChange}
            renderValue={(selected) =>
              nonAthleteUsers
                ?.filter((group) => selected.includes(group.id))
                .map((group) => `${group.firstName} ${group.lastName}`)
                .join(", ") || "All"
            }
          >
            {nonAthleteUsers?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                <Checkbox checked={selectedCoaches.indexOf(group.id) > -1} />
                <ListItemText
                  primary={`${group.firstName} ${group.lastName}`}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="DD/MM/YYYY"
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End date"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="DD/MM/YYYY"
          />
        </LocalizationProvider>
      </Box>

      {/* Always show calendar */}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 850 }}
        views={["week"]}
        defaultView="week"
        eventPropGetter={eventStyleGetter}
        onSelectEvent={(event) => {
          setSelectedBooking(event.resource);
          setIsBookingModalOpen(true);
        }}
        onNavigate={(newDate, view, action) => {
          if (action === "NEXT" || action === "PREV") {
            handleLoadMoreBookings(dayjs(newDate));
          }
        }}
      />
      <UpdateBookingModal
        isOpenModal={isBookingModalOpen}
        handleCloseModal={handleCloseBookingModal}
        booking={selectedBooking}
        setBooking={setSelectedBooking}
        athleteUsers={athleteUsers}
        nonAthleteUsers={nonAthleteUsers}
      />
    </Box>
  );
};

export { BookingScheduleCalendarView };
