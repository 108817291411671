import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateNoteParams,
  Note,
  NotesCountParams,
  NotesListParams,
  UpdateNoteParams,
} from "./models";

export const notesApi = createApi({
  baseQuery,
  reducerPath: "notesApi",
  tagTypes: ["Notes", "NotesCount"],
  endpoints: (builder) => ({
    addNote: builder.mutation<Note, CreateNoteParams>({
      query: (params) => ({
        url: "/notes",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Notes"],
    }),
    getNoteById: builder.query<Note, number>({
      query: (id) => ({
        url: `/notes/${id}`,
        method: "GET",
      }),
      providesTags: ["Notes"],
    }),
    getNotesForUser: builder.query<Note[], NotesListParams>({
      query: (params) => ({
        url: `/notes`,
        method: "GET",
        params,
      }),
      providesTags: ["Notes"],
    }),
    getNotesCount: builder.query<number, NotesCountParams>({
      query: (params) => ({
        url: `/notes`,
        method: "GET",
        params: {
          countOnly: true,
          userId: params.userId,
        },
      }),
      providesTags: ["NotesCount"],
    }),
    updateNote: builder.mutation<Note, UpdateNoteParams>({
      query: (params) => ({
        url: `/notes/${params.id}`,
        method: "PATCH",
        body: params,
      }),
      invalidatesTags: ["Notes"],
    }),
  }),
});

export const {
  useAddNoteMutation,
  useGetNoteByIdQuery,
  useGetNotesForUserQuery,
  useGetNotesCountQuery,
  useUpdateNoteMutation,
} = notesApi;
