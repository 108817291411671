/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Drawer,
  TextField,
  List,
  ListItem,
  Paper,
  Chip,
} from "@mui/material";
import { Colors } from "shared/themes";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { useGetExercisesLibraryQuery } from "shared/api";
import { ExerciseItem } from "shared/api/exercises/models";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";

interface ModernProgramBuilderSettingsSidebarProps {
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  onSelectExercise: (
    exercise: ExerciseItem,
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => void;
  weekIndex?: number;
  dayIndex?: number;
  exerciseIndex?: number;
  initialSearchTerm?: string;
}

const ExerciseCard = ({
  exercise,
  onClick,
}: {
  exercise: ExerciseItem;
  onClick: () => void;
}) => (
  <Paper
    elevation={0}
    sx={{
      backgroundColor: "white",
      border: `1px solid ${Colors.gray[200]}`,
      borderRadius: 2,
      p: 1.5,
      mb: 1.5,
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        borderColor: Colors.blue[300],
      },
    }}
    onClick={onClick}
  >
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 600,
            color: Colors.gray[900],
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 0.5,
          }}
        >
          {exercise.name}
          {exercise.videoUrl && (
            <VideoLibraryIcon
              sx={{
                fontSize: 16,
                color: Colors.blue[500],
                ml: "auto",
              }}
            />
          )}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: Colors.gray[600],
            display: "flex",
            gap: 1,
          }}
        >
          <span>{exercise.exerciseType}</span>
          {exercise.primaryMuscleGroup && (
            <>
              <span>•</span>
              <span>{exercise.primaryMuscleGroup}</span>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  </Paper>
);

export const ModernProgramBuilderSettingsSidebar: FC<
  ModernProgramBuilderSettingsSidebarProps
> = ({
  isDrawerOpen,
  handleCloseDrawer,
  onSelectExercise,
  weekIndex,
  dayIndex,
  exerciseIndex,
  initialSearchTerm = "",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0);
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(0);
  const [shouldSearch, setShouldSearch] = useState(false);

  // Handle initial values and trigger search
  useEffect(() => {
    if (isDrawerOpen) {
      if (initialSearchTerm) {
        setSearchTerm(initialSearchTerm);
        setShouldSearch(true); // Trigger search immediately when opened with initial term
      }
      if (typeof weekIndex === "number") {
        setSelectedWeekIndex(weekIndex);
      }
      if (typeof dayIndex === "number") {
        setSelectedDayIndex(dayIndex);
      }
    }
  }, [isDrawerOpen, initialSearchTerm, weekIndex, dayIndex]);

  // Reset when drawer closes
  useEffect(() => {
    if (!isDrawerOpen) {
      setSearchTerm("");
      setSelectedWeekIndex(0);
      setSelectedDayIndex(0);
      setShouldSearch(false);
    }
  }, [isDrawerOpen]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShouldSearch(true);
  };

  const { data: exercises, isFetching } = useGetExercisesLibraryQuery(
    { queryString: searchTerm },
    { skip: !shouldSearch || !searchTerm }
  );

  const handleExerciseSelect = (exercise: ExerciseItem) => {
    if (typeof exerciseIndex === "number") {
      // If we have an exerciseIndex, update that specific exercise
      onSelectExercise(
        exercise,
        selectedWeekIndex,
        selectedDayIndex,
        exerciseIndex
      );
    }
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
      <Box
        sx={{
          mt: 8,
          width: "35vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            borderBottom: `1px solid ${Colors.gray[200]}`,
            padding: "16px 24px",
            zIndex: 1,
          }}
        >
          <Typography variant="h6" sx={{ mb: 3 }}>
            Search Exercises
          </Typography>

          <form onSubmit={handleSearchSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search exercises..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShouldSearch(false);
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ color: Colors.gray[400], mr: 1 }} />
                ),
              }}
            />
          </form>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: "16px 24px",
          }}
        >
          {isFetching ? (
            <Typography sx={{ color: Colors.gray[600] }}>
              Searching...
            </Typography>
          ) : searchTerm && exercises?.length === 0 ? (
            <Typography sx={{ color: Colors.gray[600] }}>
              No exercises found
            </Typography>
          ) : !searchTerm ? (
            <Typography sx={{ color: Colors.gray[600] }}>
              Enter a search term to find exercises
            </Typography>
          ) : (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {exercises?.map((exercise) => (
                <ExerciseCard
                  key={exercise.id}
                  exercise={exercise}
                  onClick={() => handleExerciseSelect(exercise)}
                />
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};
