import { HealthFactor, HealthScore } from "shared/api";
import {
  ActivityInsights,
  ReadinessInsights,
  SleepInsights,
  TrendDataItem,
} from "./types";

const blankFactor: Omit<HealthFactor, "name"> = {
  value: null,
  goal: null,
  unit: "",
  score: null,
  state: "",
};

export const getActivityInsights = (
  healthScores: HealthScore[] = []
): ActivityInsights => {
  // Prepare trend data: an array of dates, step counts, and active calories
  const trendData: TrendDataItem[] = healthScores.map((score) => {
    const stepsFactor = score.factors.find((factor) => factor.name === "steps");
    const activeCaloriesFactor = score.factors.find(
      (factor) => factor.name === "active_calories"
    );
    return {
      date: new Date(score.scoreDateTime),
      steps: stepsFactor?.value || 0,
      goal: stepsFactor?.goal || 0,
      activeCalories: activeCaloriesFactor?.value || 0,
      activeCaloriesGoal: activeCaloriesFactor?.goal || 0,
    };
  });

  // Sort the trend data by date
  trendData.sort((a, b) => a.date.getTime() - b.date.getTime());

  const defaultDay = {
    date: new Date(),
    steps: 0,
    goal: 0,
    activeCalories: 0,
    activeCaloriesGoal: 0,
  };

  const bestDay =
    trendData.length > 0
      ? trendData.reduce((max, current) =>
          current.steps > max.steps ? current : max
        )
      : defaultDay;

  const worstDay =
    trendData.length > 0
      ? trendData.reduce((min, current) =>
          current.steps < min.steps ? current : min
        )
      : defaultDay;

  const todayStats =
    trendData.length > 0 ? trendData[trendData.length - 1] : defaultDay;

  const goalAchievementDays = trendData.filter(
    (data) => data.steps >= data.goal
  ).length;
  const totalDays = trendData.length;

  const totalSteps = trendData.reduce((sum, current) => sum + current.steps, 0);
  const averageDailySteps =
    totalDays > 0 ? Math.round(totalSteps / totalDays) : 0;

  const totalActiveCalories = trendData.reduce(
    (sum, current) => sum + current.activeCalories,
    0
  );
  const averageDailyActiveCalories =
    totalDays > 0 ? Math.round(totalActiveCalories / totalDays) : 0;

  return {
    trendData,
    bestDay,
    worstDay,
    todayStats,
    goalAchievementDays,
    totalDays,
    averageDailySteps,
    averageDailyActiveCalories,
  };
};

export const getSleepMetrics = (
  healthScores: HealthScore[] = []
): SleepInsights => {
  if (!healthScores.length) {
    return {
      overallScore: 0,
      overallState: "",
      sleepDuration: blankFactor,
      sleepRegularity: blankFactor,
      sleepContinuity: blankFactor,
      sleepDebt: blankFactor,
      circadianAlignment: blankFactor,
      mentalRecovery: blankFactor,
      physicalRecovery: blankFactor,
    };
  }

  const sortedHealthScores = [...healthScores].sort(
    (a, b) =>
      new Date(b.scoreDateTime).getTime() - new Date(a.scoreDateTime).getTime()
  );

  const latestHealthScore = sortedHealthScores[0];

  const getFactorValue = (factorName: string) => {
    const factor = latestHealthScore.factors.find(
      (factor) => factor.name === factorName
    );
    return factor
      ? {
          value: factor.value,
          goal: factor.goal,
          unit: factor.unit,
          score: factor.score,
          state: factor.state,
        }
      : blankFactor;
  };

  return {
    overallScore: latestHealthScore.score ?? 0,
    overallState: latestHealthScore.state ?? "",
    sleepDuration: getFactorValue("sleep_duration"),
    sleepRegularity: getFactorValue("sleep_regularity"),
    sleepContinuity: getFactorValue("sleep_continuity"),
    sleepDebt: getFactorValue("sleep_debt"),
    circadianAlignment: getFactorValue("circadian_alignment"),
    mentalRecovery: getFactorValue("mental_recovery"),
    physicalRecovery: getFactorValue("physical_recovery"),
  };
};

export const getReadinessMetrics = (
  healthScores: HealthScore[] = []
): ReadinessInsights => {
  const sortedHealthScores = [...healthScores].sort(
    (a, b) =>
      new Date(b.scoreDateTime).getTime() - new Date(a.scoreDateTime).getTime()
  );

  if (!healthScores.length) {
    return {
      averageRestingHeartRate: 0,
      overallScore: 0,
      overallState: "",
      heartRateVariability: blankFactor,
      sleepDebt: blankFactor,
      sleepDuration: blankFactor,
      mentalRecovery: blankFactor,
      physicalRecovery: blankFactor,
      walkingStrainCapacity: blankFactor,
      exerciseStrainCapacity: blankFactor,
    };
  }

  const latestHealthScore = sortedHealthScores[0];

  const getFactorValue = (factorName: string) => {
    const factor = latestHealthScore?.factors.find(
      (factor) => factor.name === factorName
    );
    return factor
      ? {
          value: factor.value,
          goal: factor.goal,
          unit: factor.unit,
          score: factor.score,
          state: factor.state,
        }
      : blankFactor;
  };

  const restingHeartRateArray = healthScores
    .map(
      (score) =>
        score.factors?.find((factor) => factor.name === "resting_heart_rate")
          ?.value
    )
    .filter((value): value is number => value !== undefined);

  const averageRestingHeartRate =
    restingHeartRateArray.length > 0
      ? Math.round(
          restingHeartRateArray.reduce((sum, rate) => sum + rate, 0) /
            restingHeartRateArray.length
        )
      : 0;

  return {
    overallScore: latestHealthScore?.score ?? 0,
    overallState: latestHealthScore?.state ?? "",
    averageRestingHeartRate,
    heartRateVariability: getFactorValue("heart_rate_variability"),
    sleepDebt: getFactorValue("sleep_debt"),
    sleepDuration: getFactorValue("sleep_duration"),
    mentalRecovery: getFactorValue("mental_recovery"),
    physicalRecovery: getFactorValue("physical_recovery"),
    walkingStrainCapacity: getFactorValue("walking_strain_capacity"),
    exerciseStrainCapacity: getFactorValue("exercise_strain_capacity"),
  };
};
