import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import {
  WorkoutProgramStatus,
  WorkoutProgramStatusDictionary,
  useUpdateWorkoutProgramStatusMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { ProgramCardModal } from "./ProgramCardModal";
import { InvoiceOverdueModal } from "./InvoiceOverdueModal";

interface ProgramCardProps {
  image: string;
  workoutStatus: string;
  id: number;
  startDate: string;
  programName: string;
  hasInProgressWorkout: boolean;
  weeksInProgram: number;
  daysPerWeek: number;
  invoiceIsOverdue: boolean;
  showDrawerOnSuccess: (workoutStatus: string) => void;
}
const ProgramCard: FC<ProgramCardProps> = ({
  image,
  workoutStatus,
  id,
  programName,
  startDate,
  weeksInProgram,
  daysPerWeek,
  hasInProgressWorkout,
  invoiceIsOverdue,
  showDrawerOnSuccess,
}) => {
  dayjs.extend(advancedFormat);
  const [showProgramCardModal, setShowProgramCardModal] = useState(false);
  const [showInvoiceOverdueModal, setShowInvoiceOverdueModal] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    if (workoutStatus === WorkoutProgramStatus.COMPLETED) {
      navigate(`${Routes.programs.url}/${id}`);
      return;
    }
    if (invoiceIsOverdue) {
      setShowInvoiceOverdueModal(!showInvoiceOverdueModal);
      return;
    }
    if (workoutStatus === WorkoutProgramStatus.IN_PROGRESS) {
      // do nothing
      return;
    }
    // else when its a new program, you can start it.
    setShowProgramCardModal(!showProgramCardModal);
  };

  const [updateWorkoutProgramStatus] = useUpdateWorkoutProgramStatusMutation();

  const updateWorkoutProgram = async () => {
    if (workoutStatus === WorkoutProgramStatus.IN_PROGRESS) {
      await updateWorkoutProgramStatus({
        workoutProgramId: id,
        workoutProgramStatus: WorkoutProgramStatus.COMPLETED,
      }).unwrap();
      showDrawerOnSuccess(WorkoutProgramStatus.IN_PROGRESS);
    } else if (workoutStatus === WorkoutProgramStatus.NOT_STARTED) {
      await updateWorkoutProgramStatus({
        workoutProgramId: id,
        workoutProgramStatus: WorkoutProgramStatus.IN_PROGRESS,
      }).unwrap();
      showDrawerOnSuccess(WorkoutProgramStatus.NOT_STARTED);
    }
  };

  const workoutProgramWeeksFrequency =
    weeksInProgram === 1 ? "1 week" : `${weeksInProgram} weeks`;

  const workoutProgramDaysFrequency =
    daysPerWeek === 1 ? "1 day/week" : `${daysPerWeek} days/week`;

  const workoutProgramFrequencyText = `${workoutProgramWeeksFrequency}, ${workoutProgramDaysFrequency}`;

  const programStatusMessage =
    invoiceIsOverdue && workoutStatus !== WorkoutProgramStatus.COMPLETED
      ? "Invoice overdue"
      : WorkoutProgramStatusDictionary[workoutStatus];

  return (
    <Card
      onClick={toggleModal}
      sx={{
        width: "100%",
        height: "184px",
        position: "relative",
        mb: "16px",
        boxShadow: "none",

        "&:hover, &:focus": {
          border: `2px solid ${Colors.gray[300]}`,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {workoutStatus === WorkoutProgramStatus.COMPLETED && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            backgroundColor: `${Colors.gray[100]}`,
            zIndex: 1,
          }}
        >
          <OutlinedIcon
            iconName="check_circle"
            style={{
              color: `${Colors.blue[1200]}`,
              fontSize: "20px",
            }}
          />
          Completed
        </Box>
      )}
      <InvoiceOverdueModal
        isShow={showInvoiceOverdueModal}
        setIsShow={toggleModal}
      />
      <ProgramCardModal
        isShow={showProgramCardModal}
        onUpdateWorkoutProgram={updateWorkoutProgram}
        setIsShow={toggleModal}
        workoutStatus={workoutStatus}
        hasInProgressWorkout={hasInProgressWorkout}
      />
      <CardMedia
        sx={{ width: "100%", height: "100%", position: "relative" }}
        image={image}
      />
      <CardContent
        sx={{
          position: "absolute",
          top: "40%",
          width: "100%",
          height: "60%",
          padding: "0px 20px 0px 20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ height: "120%" }}>
            <Box
              sx={{
                padding: "5px",
                borderRadius: "16px",
                bgcolor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: "16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                Start Date: {dayjs(startDate).format("Do MMMM YY")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "18px",
                  letterSpacing: "0.16px",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {workoutProgramFrequencyText}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "28px",
                color: `${Colors.gray[100]}`,
                mb: "8px",
              }}
            >
              {programName}
            </Typography>
          </Box>
          <Box
            sx={{
              height: "120%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              paddingBottom: "8px",
            }}
          >
            <Button
              variant="text"
              sx={{
                p: "4px 16px",
                borderRadius: "5px",
                background: `${Colors.gray[100]}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: `${Colors.blue[1300]}`,
                  textTransform: "none",
                }}
              >
                {programStatusMessage}
              </Box>
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export { ProgramCard };
