import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import dayjs from "dayjs";
import {
  ClinikoBooking,
  useGetCrmBookingsForUserQuery,
  useCreateTreatmentNoteMutation,
  CreateTreatmentNoteRequest,
} from "shared/api";
import { useFormik } from "formik";

interface SyncToClinikoModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  userNoteId: number;
  userId: number;
}

export const SyncToClinikoModal: FC<SyncToClinikoModalProps> = ({
  isOpenModal,
  handleCloseModal,
  userNoteId,
  userId,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedBooking, setSelectedBooking] = useState<ClinikoBooking | null>(
    null
  );

  const { data: bookings, isLoading: isLoadingBookings } =
    useGetCrmBookingsForUserQuery(userId, { skip: !isOpenModal });

  const [createTreatmentNote, { isLoading: isCreatingNote }] =
    useCreateTreatmentNoteMutation();

  const formik = useFormik<CreateTreatmentNoteRequest>({
    initialValues: {
      userNoteId,
      bookingId: "",
      patientId: "",
      ahrrNumber: "",
      sessionNumber: "",
      medicareNumber: "",
      medicareReference: "",
      consentGivenForQuestions: false,
      consentGivenForTreatment: false,
      consentGivenForDryNeedling: false,
    },
    onSubmit: async (values) => {
      try {
        await createTreatmentNote(values).unwrap();
        setSuccessMessage("Successfully synced note to Cliniko");
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } catch (error: any) {
        setErrorMessage(
          error?.data?.message || "Failed to sync note to Cliniko"
        );
      }
    },
  });

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpenModal) {
      setSelectedBooking(null);
      setErrorMessage("");
      setSuccessMessage("");
      formik.resetForm();
    }
  }, [isOpenModal]);

  // Update form when booking is selected
  useEffect(() => {
    if (selectedBooking) {
      formik.setValues({
        ...formik.values,
        bookingId: selectedBooking.bookingId,
        patientId: selectedBooking.patientId,
        medicareNumber: selectedBooking.medicareNumber || "",
        medicareReference: selectedBooking.medicareReference || "",
      });
    }
  }, [selectedBooking]);

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          ...sharedStyles.containers.modal,
          alignItems: { xs: "flex-end", md: "center" },
        }}
      >
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            width: { xs: "100%", md: "50%" },
            maxHeight: { xs: "90vh", md: "none" },
            borderRadius: { xs: "16px 16px 0 0", md: "8px" },
            m: { xs: 0, md: 3 },
            position: "relative",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
              position: "sticky",
              top: 0,
              bgcolor: "background.paper",
              pt: 1,
              zIndex: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: { xs: "16px", md: "18px" },
                color: Colors.gray[900],
              }}
            >
              Sync to Cliniko
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: Colors.gray[600], cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>

          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.gray[600],
              mb: 2,
            }}
          >
            Select a booking to sync your clinical note.
          </Typography>

          {isLoadingBookings ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  backgroundColor: Colors.gray[50],
                  borderRadius: 1,
                  border: `1px solid ${Colors.gray[200]}`,
                  height: { xs: "150px", md: "200px" },
                  overflow: "auto",
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        sx={{
                          backgroundColor: Colors.gray[50],
                          borderBottom: `1px solid ${Colors.gray[200]}`,
                        }}
                      />
                      <TableCell
                        sx={{
                          backgroundColor: Colors.gray[50],
                          borderBottom: `1px solid ${Colors.gray[200]}`,
                          fontFamily: "Inter",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: Colors.gray[900],
                        }}
                      >
                        Date & Time
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: Colors.gray[50],
                          borderBottom: `1px solid ${Colors.gray[200]}`,
                          fontFamily: "Inter",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: Colors.gray[900],
                        }}
                      >
                        Duration
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      overflowY: "auto",
                    }}
                  >
                    {bookings
                      ?.slice()
                      .sort((a, b) => dayjs(a.startsAt).diff(dayjs(b.startsAt)))
                      .map((booking) => (
                        <TableRow
                          key={booking.bookingId}
                          hover
                          selected={
                            selectedBooking?.bookingId === booking.bookingId
                          }
                          onClick={() => setSelectedBooking(booking)}
                          sx={{
                            cursor: "pointer",
                            "&:last-child td": { border: 0 },
                            "&.Mui-selected": {
                              backgroundColor: `${Colors.blue[200]} !important`,
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: `${Colors.blue[100]} !important`,
                            },
                            "& td": {
                              fontFamily: "Inter",
                              fontSize: "14px",
                              color: Colors.gray[900],
                            },
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Radio
                              checked={
                                selectedBooking?.bookingId === booking.bookingId
                              }
                              onChange={() => setSelectedBooking(booking)}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            {dayjs(booking.startsAt).format("DD/MM/YYYY HH:mm")}
                          </TableCell>
                          <TableCell>
                            {`${dayjs(booking.endsAt).diff(
                              booking.startsAt,
                              "minutes"
                            )} mins`}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  my: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    color: Colors.gray[600],
                  }}
                >
                  {bookings?.length || 0} bookings found
                </Typography>
              </Box>

              {selectedBooking && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: "14px", mb: 2 }}
                    >
                      Additional details
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                          gap: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label="AHRR No."
                          name="ahrrNumber"
                          value={formik.values.ahrrNumber}
                          onChange={formik.handleChange}
                          size="small"
                        />
                        <TextField
                          fullWidth
                          label="Session No."
                          name="sessionNumber"
                          value={formik.values.sessionNumber}
                          onChange={formik.handleChange}
                          size="small"
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                          gap: 2,
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Medicare No."
                          name="medicareNumber"
                          value={formik.values.medicareNumber}
                          onChange={formik.handleChange}
                          size="small"
                        />
                        <TextField
                          fullWidth
                          label="Medicare Ref."
                          name="medicareReference"
                          value={formik.values.medicareReference}
                          onChange={formik.handleChange}
                          size="small"
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.consentGivenForQuestions}
                              onChange={formik.handleChange}
                              name="consentGivenForQuestions"
                              size="small"
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              Consent given for questions
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.consentGivenForTreatment}
                              onChange={formik.handleChange}
                              name="consentGivenForTreatment"
                              size="small"
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              Consent given for treatment
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.consentGivenForDryNeedling}
                              onChange={formik.handleChange}
                              name="consentGivenForDryNeedling"
                              size="small"
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: "14px" }}>
                              Consent given for dry needling
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          )}

          <Box
            sx={{
              mt: 2,
              position: "sticky",
              bottom: 0,
              bgcolor: "background.paper",
              pt: 2,
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{
                textTransform: "none",
              }}
              onClick={() => formik.handleSubmit()}
              loading={isCreatingNote}
              disabled={!selectedBooking}
            >
              <Icon>sync</Icon> Sync
            </LoadingButton>

            {(errorMessage || successMessage) && (
              <Box sx={{ mt: 2, mb: { xs: 2, md: 0 } }}>
                {errorMessage && (
                  <Alert variant="outlined" severity="error">
                    {errorMessage}
                  </Alert>
                )}
                {successMessage && (
                  <Alert variant="outlined" severity="success">
                    {successMessage}
                  </Alert>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
