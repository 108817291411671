import { FC, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { AnyType } from "shared/helpers";
import {
  useGetUserProfileItemQuery,
  UserProfileItemResponse,
  useUpdateUserProfileMutation,
  useGetUsersQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { BillingFrequency } from "shared/api/user/models";
import dayjs from "dayjs";

interface UpdateUserFormProps {
  currentUserData?: UserProfileItemResponse;
  toggleSuccessfulUpdateModal: () => void;
  isAthlete?: boolean;
}

interface SubmitValues {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  myFitnessPalUsername?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  userRole?: string;
  userStatus?: string;
  userCategory?: string;
  assignedFitnessProfessionalId?: number;
  userLiftingProfileType?: string;
  swapExercisesEnabled?: boolean;
  billingFrequency?: string;
  billingStartDate?: string;
  billingEndDate?: string;
}

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Min 2").required("This field is required"),
  lastName: Yup.string().min(2, "Min 2").required("This field is required"),
  billingStartDate: Yup.date().nullable(),
  billingEndDate: Yup.date()
    .nullable()
    .test(
      "is-after-start",
      "End date must be after start date",
      function (endDate) {
        const { billingStartDate } = this.parent;
        if (!billingStartDate || !endDate) return true; // If either date is not set, validation passes
        return dayjs(endDate).isAfter(dayjs(billingStartDate));
      }
    ),
});

const UpdateUserForm: FC<UpdateUserFormProps> = ({
  currentUserData,
  toggleSuccessfulUpdateModal,
  isAthlete = false,
}) => {
  const { t } = useTranslation();
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);

  const [errorMessage, setErrorMessage] = useState("");
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  const { data: updatedUser, refetch } = useGetUserProfileItemQuery(
    currentUserData!.id
  );

  const {
    data: fitnessProfessionals,
    isLoading: isLoadingFitnessProfessionals,
  } = useGetUsersQuery({
    count: 0,
    page: 0,
    userRole: "admin",
    orderByName: true,
  });

  const onSubmit = async (params: SubmitValues) => {
    try {
      await updateUserProfile({
        ...params,
        swapExercisesEnabled: `${params.swapExercisesEnabled}`,
        billingFrequency:
          BillingFrequency[
            params.billingFrequency as keyof typeof BillingFrequency
          ],
      }).unwrap();
      toggleSuccessfulUpdateModal();
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      await refetch();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<SubmitValues>({
    initialValues: {
      id: currentUserData?.id,
      firstName: currentUserData?.firstName,
      lastName: currentUserData?.lastName,
      phoneNumber: currentUserData?.phoneNumber,
      email: currentUserData?.email,
      userStatus: currentUserData?.userStatus,
      userCategory: currentUserData?.userCategory,
      userRole: currentUserData?.userRole,
      dateOfBirth: currentUserData?.dateOfBirth,
      assignedFitnessProfessionalId:
        currentUserData?.assignedFitnessProfessionalId,
      userLiftingProfileType:
        currentUserData?.userLiftingProfileType?.liftingProfileType,
      myFitnessPalUsername: currentUserData?.myFitnessPalUsername,
      swapExercisesEnabled: currentUserData?.swapExercisesEnabled,
      billingFrequency: currentUserData?.billingFrequency || "Undefined",
      billingStartDate: currentUserData?.billingStartDate,
      billingEndDate: currentUserData?.billingEndDate,
    },
    enableReinitialize: true,
    validationSchema: UserProfileSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const checkBeforeSubmit =
    values.dateOfBirth === currentUserData?.dateOfBirth &&
    values.email === currentUserData?.email &&
    values.firstName === currentUserData?.firstName &&
    values.lastName === currentUserData?.lastName &&
    values.phoneNumber === currentUserData?.phoneNumber &&
    values.userRole === currentUserData?.userRole &&
    values.userStatus === currentUserData?.userStatus &&
    values.userCategory === currentUserData?.userCategory &&
    values.assignedFitnessProfessionalId ===
      currentUserData?.assignedFitnessProfessionalId &&
    values.userLiftingProfileType ===
      currentUserData?.userLiftingProfileType?.liftingProfileType &&
    values.myFitnessPalUsername === currentUserData?.myFitnessPalUsername &&
    values.swapExercisesEnabled === currentUserData?.swapExercisesEnabled &&
    values.billingFrequency === currentUserData?.billingFrequency &&
    values.billingStartDate === currentUserData?.billingStartDate &&
    values.billingEndDate === currentUserData?.billingEndDate;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            bgcolor: `${Colors.gray[100]}`,
            mt: "20px",
            padding: "20px",
            height: "auto",
            width: "100%",
          }}
        >
          {/* Personal Information Section */}
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "18px",
                color: Colors.gray[900],
                mb: 2,
              }}
            >
              Personal Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  disabled={!isAdminOrCoach}
                  label={t("user-profile.first-name")}
                  value={values.firstName}
                  onBlur={handleBlur("firstName")}
                  onChange={handleChange("firstName")}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label={t("user-profile.last-name")}
                  disabled={!isAdminOrCoach}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  value={values.lastName}
                  onBlur={handleBlur("lastName")}
                  onChange={handleChange("lastName")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  type="email"
                  label={t("user-profile.email")}
                  value={values.email}
                  disabled
                  onBlur={handleBlur("email")}
                  onChange={handleChange("email")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label={t("user-profile.phone")}
                  value={values.phoneNumber}
                  onBlur={handleBlur("phoneNumber")}
                  onChange={handleChange("phoneNumber")}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Account Settings Section */}
          {isAdminOrCoach && (
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: Colors.gray[900],
                  mb: 2,
                }}
              >
                Account Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {t("user-profile.user-status")}
                  </Typography>
                  <Select
                    fullWidth
                    value={values.userStatus || currentUserData?.userStatus}
                    onBlur={handleBlur("userStatus")}
                    onChange={(e) =>
                      setFieldValue("userStatus", e.target.value)
                    }
                    size="small"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {t("user-profile.user-category")}
                  </Typography>
                  <Select
                    fullWidth
                    value={values.userCategory || currentUserData?.userCategory}
                    onBlur={handleBlur("userCategory")}
                    onChange={(e) =>
                      setFieldValue("userCategory", e.target.value)
                    }
                    size="small"
                  >
                    <MenuItem value="Undefined">None</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="InPerson">In Person</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="NonCoaching">Non Coaching</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {t("user-profile.user-lifting-profile")}
                  </Typography>
                  <Select
                    fullWidth
                    value={values.userLiftingProfileType}
                    onBlur={handleBlur("userLiftingProfileType")}
                    onChange={(e) =>
                      setFieldValue("userLiftingProfileType", e.target.value)
                    }
                    size="small"
                  >
                    <MenuItem value="Undefined">None</MenuItem>
                    <MenuItem value="Powerlifting">Powerlifting</MenuItem>
                    <MenuItem value="OlympicWeightlifting">
                      Olympic Weightlifting
                    </MenuItem>
                    <MenuItem value="Bodybuilding">Bodybuilding</MenuItem>
                    <MenuItem value="Crossfit">Crossfit</MenuItem>
                    <MenuItem value="Strongman">Strongman</MenuItem>
                    <MenuItem value="Powerbuilding">Powerbuilding</MenuItem>
                    <MenuItem value="Calisthenics">Calisthenics</MenuItem>
                    <MenuItem value="GeneralFitness">General Fitness</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {t("user-profile.swap-exercises-enabled")}
                  </Typography>
                  <Select
                    fullWidth
                    value={values.swapExercisesEnabled}
                    onBlur={handleBlur("swapExercisesEnabled")}
                    onChange={(e) =>
                      setFieldValue("swapExercisesEnabled", e.target.value)
                    }
                    size="small"
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Box>
          )}

          <Divider sx={{ my: 3 }} />

          {/* Billing Information Section */}
          {isAdminOrCoach && (
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: Colors.gray[900],
                  mb: 2,
                }}
              >
                Billing Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Frequency
                  </Typography>
                  <Select
                    fullWidth
                    value={values.billingFrequency || "Undefined"}
                    onBlur={handleBlur("billingFrequency")}
                    onChange={(e) =>
                      setFieldValue("billingFrequency", e.target.value)
                    }
                    size="small"
                  >
                    <MenuItem value="Undefined">None</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                    <MenuItem value="Biannually">Biannually</MenuItem>
                    <MenuItem value="Annually">Annually</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Start Date
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="date"
                    value={
                      values.billingStartDate
                        ? dayjs(values.billingStartDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      setFieldValue("billingStartDate", e.target.value);
                    }}
                    error={!!errors.billingStartDate}
                    helperText={errors.billingStartDate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    End Date
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="date"
                    value={
                      values.billingEndDate
                        ? dayjs(values.billingEndDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      setFieldValue("billingEndDate", e.target.value);
                    }}
                    error={!!errors.billingEndDate}
                    helperText={errors.billingEndDate}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Coach Assignment Section */}
          {isAdmin &&
            !isLoadingFitnessProfessionals &&
            fitnessProfessionals && (
              <>
                <Divider sx={{ my: 3 }} />
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: "18px",
                      color: Colors.gray[900],
                      mb: 2,
                    }}
                  >
                    Coach Assignment
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {t("user-profile.coach")}
                      </Typography>
                      <Select
                        fullWidth
                        value={values.assignedFitnessProfessionalId}
                        onChange={(e) =>
                          setFieldValue(
                            "assignedFitnessProfessionalId",
                            e.target.value
                          )
                        }
                        size="small"
                      >
                        {fitnessProfessionals.map((user) => (
                          <MenuItem
                            key={user.id}
                            value={user.id}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                              }}
                            >
                              {`${user.firstName} ${user.lastName}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: Colors.gray[600] }}
                            >
                              {user.email}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

          {/* Submit Button */}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={isLoading}
              onClick={() => handleSubmit()}
              disabled={checkBeforeSubmit}
              color="primary"
              variant="contained"
              sx={{
                minWidth: "120px",
                textTransform: "none",
              }}
            >
              {t("user-profile.update-btn")}
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export { UpdateUserForm };
