import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { useTypedDispatch } from "shared/stores";
import { ExerciseFrequency } from "shared/api";
import { trainingInsightsApi } from "shared/api/trainingInsights/trainingInsights";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TrainingInsightsLineChartContainerForWeight } from "./TrainingInsightsLineChartContainerForWeight";
import { TrainingInsightsLineChartContainerForReps } from "./TrainingInsightsLineChartContainerForReps";
import { TrainingInsightsClientSelector } from "./TrainingInsightsClientSelector";
import { TrainingInsightsLineChartContainerForAverages } from "./TrainingInsightsLineChartContainerForAverages";

type TrainingInsightsContentProps = {
  preselectedUserId?: number;
  showOverviewOnly?: boolean;
};

export type SelectedExercise = {
  exerciseId: number;
  exerciseName: string;
};

const TrainingInsightsContent: FC<TrainingInsightsContentProps> = ({
  preselectedUserId = null,
  showOverviewOnly = false,
}) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(
    preselectedUserId
  );

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ExerciseFrequency[]>([]);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const dispatch = useTypedDispatch();
  const getFrequency =
    trainingInsightsApi.endpoints.getMostFrequentPrescribedExercises.initiate(
      selectedUserId!!
    );

  const [selectedExercise, setSelectedExercise] = useState<SelectedExercise>();

  async function callGetMostFrequentExercises() {
    try {
      const response = await dispatch(getFrequency);
      setData(response.data ?? []);
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUserId !== null) {
      setIsLoading(true);
      setData([]);
      setSelectedExercise(undefined);
      setFetchErrorMessage("");
    }
    if (selectedUserId) {
      callGetMostFrequentExercises();
    }
  }, [selectedUserId]);

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const showChart = data && !isLoading && selectedExercise && selectedUserId;

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        mb: "16px",
        overflowY: "auto",
        padding: 2,
      }}
    >
      {fetchErrorMessage && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {t("errors.server-unable")}
          </Typography>
        </Alert>
      )}
      {!preselectedUserId && (
        <TrainingInsightsClientSelector setSelectedUserId={setSelectedUserId} />
      )}
      {isLoading || !selectedUserId ? (
        <Box />
      ) : (
        <Box sx={{ mt: "10px" }}>
          <FormControl
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
            variant="outlined"
          >
            <InputLabel>Select exercise</InputLabel>
            <Select
              label="Select exercise"
              onChange={(e: { target: { value: any } }) =>
                setSelectedExercise({
                  exerciseId: e.target.value,
                  exerciseName:
                    data.find((x) => x.exerciseId === e.target.value)
                      ?.exerciseName ?? "",
                })
              }
              defaultValue=""
            >
              {data?.map((exercise) => (
                <MenuItem value={exercise.exerciseId} key={exercise.exerciseId}>
                  {exercise.exerciseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {showChart && !showOverviewOnly && (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Tabs">
              <Tab label="Overview" value="1" />
              <Tab label="Volume" value="2" />
              <Tab label="Weight" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TrainingInsightsLineChartContainerForAverages
              exercise={selectedExercise!!}
              userId={selectedUserId!!}
            />
          </TabPanel>
          <TabPanel value="2">
            <TrainingInsightsLineChartContainerForReps
              exercise={selectedExercise!!}
              userId={selectedUserId!!}
            />
          </TabPanel>
          <TabPanel value="3">
            <TrainingInsightsLineChartContainerForWeight
              exercise={selectedExercise!!}
              userId={selectedUserId!!}
            />
          </TabPanel>
        </TabContext>
      )}
      {showChart && showOverviewOnly && (
        <Box
          sx={{
            maxHeight: "35vh",
          }}
        >
          <TrainingInsightsLineChartContainerForAverages
            exercise={selectedExercise!!}
            userId={selectedUserId!!}
            isAthleteView
          />
        </Box>
      )}
    </Box>
  );
};

export { TrainingInsightsContent };
