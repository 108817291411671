import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface WorkoutBuilderState {
  isModified: boolean;
}

const initialState: WorkoutBuilderState = {
  isModified: false,
};

const workoutBuilderSlice = createSlice({
  name: "workoutBuilderSlice",
  initialState,
  reducers: {
    setIsModified: (state, action: PayloadAction<boolean>) => {
      state.isModified = action.payload;
    },
  },
});

export const { setIsModified } = workoutBuilderSlice.actions;

export default workoutBuilderSlice.reducer;
