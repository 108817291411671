import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Colors, Images } from "shared/themes";
import { useTypedSelector } from "shared/stores";
import {
  WorkoutProgramStatus,
  useChangeWorkoutStatusMutation,
  useGetWorkoutQuery,
  useSkipWorkoutMutation,
} from "shared/api";
import DOMPurify from "dompurify";
import { WorkoutList } from "./ui";
import { FinishWorkoutModal } from "./ui/FinishWorkoutModal";
import { Feedback } from "./ui/Feedback";
import { SkipWorkoutModal } from "./ui/SkipWorkoutModal";

const { IN_PROGRESS, NOT_STARTED, COMPLETED, SKIPPED } = WorkoutProgramStatus;
const EXERCISE_FEEDBACK_SUFFIX = "<br><b>Exercise Feedback:</b><br>";

const WorkoutDetails: FC = () => {
  const { t } = useTranslation();
  const currentWorkout = useTypedSelector((state) => state.workout);

  const [showFeedback, setShowFeedback] = useState(false);
  const [isShowFinishWorkoutModal, setIsShowFinishWorkoutModal] =
    useState(false);
  const [isShowSkipWorkoutModal, setIsShowSkipWorkoutModal] = useState(false);

  const buttonProps: { [key: string]: { title: any; background: string } } = {
    [IN_PROGRESS]: {
      title: t("session-card.finish-workout"),
      background: `${Colors.green[100]}`,
    },
    [NOT_STARTED]: {
      title: t("session-card.start-workout"),
      background: `${Colors.blue[1200]}`,
    },
    [SKIPPED]: {
      title: t("session-card.skip-workout"),
      background: `${Colors.gray[100]}`,
    },
  };

  const params = useParams();

  const workoutId = (currentWorkout.id || params?.userId) as number;
  const isCompletedWorkoutProgram = !!params?.completed;

  const [changeWorkoutStatus, { isLoading }] = useChangeWorkoutStatusMutation();
  const [skipWorkout, { isLoading: isLoadingSkip }] = useSkipWorkoutMutation();
  const { data: workout } = useGetWorkoutQuery(workoutId);

  const [btnTitle, setBtnTitle] = useState(
    buttonProps[workout?.workoutStatus ?? ""]
  );

  const isInProgress = workout?.workoutStatus === IN_PROGRESS;

  const isNotCompleted = workout?.workoutStatus !== COMPLETED;

  const workoutSkipped = workout?.workoutStatus === SKIPPED;

  const onProgressWorkout = async () => {
    try {
      await changeWorkoutStatus({
        ...workout,
        description: "",
        workoutStatus: isInProgress ? COMPLETED : IN_PROGRESS,
      }).unwrap();
    } catch (error) {
      console.error("Error progressing workout");
    }
  };

  const handleFinishWorkoutModal = () => {
    setIsShowFinishWorkoutModal(!isShowFinishWorkoutModal);
  };

  const handleSkipWorkoutModal = () => {
    setIsShowSkipWorkoutModal(!isShowSkipWorkoutModal);
  };

  const handleCheckStatusBeforeRequest = () => {
    if (isInProgress) {
      handleFinishWorkoutModal();
    } else {
      onProgressWorkout();
    }
  };

  const handleSkippedWorkoutRequest = async () => {
    await changeWorkoutStatus({
      ...workout,
      description: "",
      workoutStatus: SKIPPED,
    }).unwrap();

    await skipWorkout({
      workoutId: workout!.id,
      workoutProgramId: workout!.workoutProgramId,
    }).unwrap();
  };

  useEffect(() => {
    setBtnTitle(buttonProps[workout?.workoutStatus ?? ""]);
  }, [workout]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [showDialog, setShowDialog] = useState(false);

  const handleOpen = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const sanitisedPreviousWorkoutSession = (() => {
    let sanitised = DOMPurify.sanitize(workout?.previousWorkoutNotes ?? "");
    if (sanitised.endsWith(EXERCISE_FEEDBACK_SUFFIX)) {
      sanitised = sanitised.slice(0, -EXERCISE_FEEDBACK_SUFFIX.length);
    }
    return sanitised;
  })();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FinishWorkoutModal
        isShowFinishWorkoutModal={isShowFinishWorkoutModal}
        onProgressWorkout={onProgressWorkout}
        setShowFeedback={setShowFeedback}
        setIsShowFinishWorkoutModal={setIsShowFinishWorkoutModal}
      />

      <SkipWorkoutModal
        isShowSkipWorkoutModal={isShowSkipWorkoutModal}
        onSkip={handleSkippedWorkoutRequest}
        setIsShowSkipWorkoutModal={setIsShowSkipWorkoutModal}
      />

      <Card
        sx={{
          width: "100%",
          height: "175px",
          position: "relative",
          borderRadius: "0px",
        }}
      >
        <CardMedia
          sx={{ width: "100%", height: "100%", position: "relative" }}
          image={Images.RemainingSession}
        />
        <Box
          sx={{
            position: "absolute",
            top: "12%",
            width: "100%",
            height: "20%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              paddingLeft: "24px",
              paddingRight: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "32px",
                height: "32px",
                background: `${Colors.gray[100]}`,
                color: `${Colors.blue[1600]}`,
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              <Button type="button" onClick={goBack}>
                <Icon
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    mt: "4px",
                  }}
                >
                  chevron_left
                </Icon>
              </Button>
            </Box>
          </Box>
        </Box>

        <CardContent
          sx={{
            position: "absolute",
            top: "50%",
            width: "100%",
            height: "50%",
            padding: "0px 24px 0px 24px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "50%", height: "120%" }}>
              <Box
                sx={{
                  width: "65px",
                  height: "24px",
                  borderRadius: "16px",
                  bgcolor: `${Colors.gray[400]}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                {!!currentWorkout.weekNumber && (
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      color: `${Colors.blue[1300]}`,
                    }}
                  >
                    {t("session-card.week")} {currentWorkout.weekNumber}
                  </Typography>
                )}
              </Box>

              {!!currentWorkout.dayNumber && (
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: `${Colors.gray[100]}`,
                    mb: "8px",
                  }}
                >
                  {t("session-card.session")} {currentWorkout.dayNumber}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                width: "50%",
                height: "120%",
                display: "flex",
                alignItems: "flex-end",
                paddingBottom: "8px",
              }}
            />
          </Box>
        </CardContent>
      </Card>

      {workout && (
        <Feedback
          showFeedback={showFeedback}
          finishedWorkout={workout}
          workoutId={workoutId}
        />
      )}

      {sanitisedPreviousWorkoutSession && (
        <>
          <LoadingButton
            variant="outlined"
            color="primary"
            sx={{
              height: "36px",
              mt: "5px",
              textAlign: "right",
            }}
            fullWidth
            type="button"
            size="small"
            onClick={() => handleOpen()}
          >
            <Typography
              sx={{
                textTransform: "none",
                fontSize: "14px",
                ml: "3px",
              }}
            >
              View Last Session Feedback
            </Typography>
          </LoadingButton>

          <Dialog
            open={showDialog}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            sx={{
              maxHeight: "90vh",
              paddingTop: 10,
            }}
          >
            <Box
              sx={{
                margin: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: `${Colors.oxford[1100]}`,
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitisedPreviousWorkoutSession,
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  top: "7.5px",
                  right: 25,
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    ml: "3px",
                  }}
                >
                  Close
                </Typography>
                <Icon fontSize="medium" sx={{ color: `${Colors.gray[1200]}` }}>
                  close
                </Icon>
              </IconButton>
            </Box>
          </Dialog>
        </>
      )}

      {workout && <WorkoutList workout={workout} />}

      {!isCompletedWorkoutProgram &&
        workout &&
        isNotCompleted &&
        !workoutSkipped && (
          <Box
            sx={{
              height: "140px",
              width: "100%",
              padding: "16px 24px 0px 32px",
              position: "sticky",
              bottom: "0px",
              bgcolor: `${Colors.gray[100]}`,
              zIndex: 1000,
              boxShadow:
                "0px 3px 8px -1px rgba(0, 0, 0, 0.16), 0px 6px 24px rgba(0, 0, 0, 0.16), 0px 2px 32px rgba(0, 0, 0, 0.08)",
            }}
          >
            <LoadingButton
              onClick={handleCheckStatusBeforeRequest}
              loading={isLoading || isLoadingSkip}
              disabled={isLoading || isLoadingSkip}
              sx={{
                width: "100%",
                height: "42px",
              }}
              variant="contained"
              color={isInProgress ? "success" : "primary"}
              size="large"
            >
              {!isLoading && !isLoadingSkip ? (
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "26px",
                    letterSpacing: " 0.46px",
                    textTransform: "none",
                    color: `${Colors.gray[100]}`,
                  }}
                >
                  {btnTitle?.title}
                </Typography>
              ) : (
                ""
              )}
            </LoadingButton>
            <LoadingButton
              type="button"
              onClick={handleSkipWorkoutModal}
              loading={isLoading || isLoadingSkip}
              disabled={isLoading || isLoadingSkip}
              sx={{
                width: "100%",
                height: "42px",
                mt: 2,
                mb: 2,
                textTransform: "none",
                fontSize: "16px",
              }}
              variant="outlined"
              color="primary"
              size="large"
            >
              Skip Session
            </LoadingButton>
          </Box>
        )}
    </Box>
  );
};

export { WorkoutDetails };
