import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  AssignWorkoutProgramTemplateToClientParams,
  WorkoutProgramItem,
  useAssignWorkoutTemplateToClientMutation,
  useGetAllUsersQuery,
  UserRole,
  ClientResponse,
  useGetWorkoutProgramGroupItemsQuery,
  WorkoutProgramGroupItemParams,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Colors } from "shared/themes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { OrganisationClientSelector } from "pages";

interface CopyAndAssignProgramToUserModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  workoutProgram?: WorkoutProgramItem;
}

const CopyAndAssignProgramToUserModalSchema = Yup.object().shape({
  userId: Yup.number().required(),
  workoutProgramId: Yup.number().required(),
});

const CopyAndAssignProgramToUserModal: FC<
  CopyAndAssignProgramToUserModalProps
> = ({ isOpenModal, handleCloseModal, workoutProgram }) => {
  const { t } = useTranslation();
  const [assignWorkoutProgramTemplateToClient, { isLoading }] =
    useAssignWorkoutTemplateToClientMutation();

  const { data: workoutProgramGroupItems, isLoading: isLoadingProgramGroup } =
    useGetWorkoutProgramGroupItemsQuery(
      {
        parentWorkoutProgramId: workoutProgram?.id,
      } as WorkoutProgramGroupItemParams,
      { skip: !isOpenModal }
    );

  const latestWorkoutProgramVersion = workoutProgramGroupItems
    ? workoutProgramGroupItems[workoutProgramGroupItems.length - 1]
    : undefined;
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { data: allUsers, isLoading: isLoadingAllUsers } = useGetAllUsersQuery(
    {}
  );

  const onlyAthletes = allUsers?.filter(
    (item) => item.userRole === UserRole.Athlete
  );

  const [selectedUserValue, setSelectedUserValue] =
    useState<ClientResponse | null>(null);

  const onSubmit = async (
    params: AssignWorkoutProgramTemplateToClientParams
  ) => {
    try {
      await assignWorkoutProgramTemplateToClient({
        workoutProgramId: latestWorkoutProgramVersion!.childWorkoutProgramId,
        userId: params.userId,
      }).unwrap();
      setSuccessMessage("Successfully copied program to user");
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 1000);
    }
  };

  const { values, handleBlur, setFieldValue, errors, handleSubmit } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        workoutProgramId: workoutProgram?.id ?? 0,
        userId: selectedUserValue?.id ?? 0,
      },
      validationSchema: CopyAndAssignProgramToUserModalSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    }
  );

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Assign {workoutProgram?.nameOfProgram} to client
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 1,
              mb: 4,
            }}
          >
            This action copies the latest version of the workout program. Select
            a client to copy this program to.
          </Typography>
          <OrganisationClientSelector
            setSelectedUserId={(id) => setFieldValue("userId", id)}
            selectedUserId={values.userId}
          />
          <PrimaryButton
            value="Assign"
            type="submit"
            variant="contained"
            fullWidth
            loading={isLoading}
            disabled={
              isLoading ||
              isLoadingAllUsers ||
              isLoadingProgramGroup ||
              !latestWorkoutProgramVersion
            }
            onClick={() => handleSubmit()}
          />
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CopyAndAssignProgramToUserModal };
