import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { useTypedDispatch } from "shared/stores";
import { ExerciseFrequency } from "shared/api";
import { trainingInsightsApi } from "shared/api/trainingInsights/trainingInsights";
import { TrainingInsightsClientSelector } from "../TrainingInsightsClientSelector";
import { TrainingInsightsLineChartContainerForAverages } from "../TrainingInsightsLineChartContainerForAverages";
import { SelectedExercise } from "../TrainingInsightsContent";

type AthleteTrainingInsightsContentProps = {
  preselectedUserId?: number;
};

const AthleteTrainingInsightsContent: FC<
  AthleteTrainingInsightsContentProps
> = ({ preselectedUserId = null }) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(
    preselectedUserId
  );

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ExerciseFrequency[]>([]);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const dispatch = useTypedDispatch();
  const getFrequency =
    trainingInsightsApi.endpoints.getMostFrequentPrescribedExercises.initiate(
      selectedUserId!!
    );
  const [selectedExercise, setSelectedExercise] = useState<SelectedExercise>();

  async function callGetMostFrequentExercises() {
    try {
      const response = await dispatch(getFrequency);
      setData(response.data ?? []);
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUserId !== null) {
      setIsLoading(true);
      setData([]);
      setSelectedExercise(undefined);
      setFetchErrorMessage("");
    }
    if (selectedUserId) callGetMostFrequentExercises();
  }, [selectedUserId]);

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const showChart = data && !isLoading && selectedExercise && selectedUserId;

  return (
    <Box
      sx={{
        height: "88%",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: 1,
      }}
    >
      <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
        <Typography sx={sharedStyles.headings.dataGrid}>
          {t("training-insights.sub-title")}
        </Typography>
      </Box>
      {!preselectedUserId && (
        <TrainingInsightsClientSelector setSelectedUserId={setSelectedUserId} />
      )}
      {isLoading || !selectedUserId ? (
        <Box />
      ) : (
        <Box sx={{ mt: "10px" }}>
          <FormControl
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
            variant="outlined"
          >
            <InputLabel>Select History</InputLabel>
            <Select
              label="Select Exercise"
              onChange={(e: { target: { value: any } }) =>
                setSelectedExercise({
                  exerciseId: e.target.value,
                  exerciseName:
                    data.find((x) => x.exerciseId === e.target.value)
                      ?.exerciseName ?? "",
                })
              }
              defaultValue=""
            >
              {data?.map((exercise) => (
                <MenuItem value={exercise.exerciseId} key={exercise.exerciseId}>
                  {exercise.exerciseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {showChart && (
        <TrainingInsightsLineChartContainerForAverages
          exercise={selectedExercise!!}
          userId={selectedUserId!!}
          isAthleteView
        />
      )}
    </Box>
  );
};

export { AthleteTrainingInsightsContent };
