import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CompleteAndShareModernProgramBuilderRequest,
  ModernProgramBuilderResponse,
  ModernProgramBuilderGenerateProgramRequest,
} from "./models";

export const modernProgramBuilderApi = createApi({
  reducerPath: "modernProgramBuilderApi",
  baseQuery,
  tagTypes: ["ModernProgramBuilder"],
  endpoints: (builder) => ({
    getModernWorkoutPrograms: builder.query<
      ModernProgramBuilderResponse[],
      number
    >({
      query: (parentWorkoutProgramId) => ({
        url: `/modern-program-builder/${parentWorkoutProgramId}`,
        method: "GET",
      }),
      providesTags: ["ModernProgramBuilder"],
    }),
    createBaseDraftWorkoutProgram: builder.mutation<
      ModernProgramBuilderResponse,
      ModernProgramBuilderResponse
    >({
      query: (params) => ({
        url: `/modern-program-builder/create-base-draft`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["ModernProgramBuilder"],
    }),
    generateModernWorkoutProgram: builder.mutation<
      ModernProgramBuilderResponse,
      ModernProgramBuilderGenerateProgramRequest
    >({
      query: (params) => ({
        url: `/modern-program-builder/generate-program`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["ModernProgramBuilder"],
    }),
    createBaseTemplateWorkoutProgram: builder.mutation<
      ModernProgramBuilderResponse,
      ModernProgramBuilderResponse
    >({
      query: (params) => ({
        url: `/modern-program-builder/create-base-template`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["ModernProgramBuilder"],
    }),
    updateDraftWorkoutProgram: builder.mutation<
      ModernProgramBuilderResponse,
      ModernProgramBuilderResponse
    >({
      query: (params) => ({
        url: `/modern-program-builder/update-draft`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: ["ModernProgramBuilder"],
    }),
    completeWorkoutProgramAndShare: builder.mutation<
      ModernProgramBuilderResponse,
      CompleteAndShareModernProgramBuilderRequest
    >({
      query: (params) => ({
        url: `/modern-program-builder/complete-and-share/${
          params.id
        }?invoiceRequested=${params.invoiceRequested}${
          params.invoiceDueDate
            ? `&invoiceDueDate=${params.invoiceDueDate}`
            : ""
        }`,
        method: "PUT",
      }),
      invalidatesTags: ["ModernProgramBuilder"],
    }),
    createNewVersionOfExistingWorkoutProgram: builder.mutation<
      ModernProgramBuilderResponse,
      ModernProgramBuilderResponse
    >({
      query: (params) => ({
        url: `/modern-program-builder/create-new-version`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["ModernProgramBuilder"],
    }),
  }),
});

export const {
  useGetModernWorkoutProgramsQuery,
  useCreateBaseDraftWorkoutProgramMutation,
  useGenerateModernWorkoutProgramMutation,
  useCreateBaseTemplateWorkoutProgramMutation,
  useUpdateDraftWorkoutProgramMutation,
  useCompleteWorkoutProgramAndShareMutation,
  useCreateNewVersionOfExistingWorkoutProgramMutation,
} = modernProgramBuilderApi;
