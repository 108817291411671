import { FC } from "react";
import { BottomNavigation, BottomNavigationAction, Icon } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Colors } from "../../../themes";
import { navData } from "./constants";

const AthleteBottomNavigation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <BottomNavigation
      value={location.pathname}
      onChange={(_, newValue) => {
        navigate(newValue);
      }}
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1200,
        height: "60px",
        borderTop: `1px solid ${Colors.gray[300]}`,
        "& .MuiBottomNavigationAction-root": {
          minWidth: 0,
          padding: 0,
        },
      }}
    >
      {navData.map((item) => (
        <BottomNavigationAction
          key={item.id}
          label={item.title}
          value={item.link}
          icon={<Icon>{item.icon}</Icon>}
          showLabel
          sx={{
            color: Colors.gray[900],
            "&.Mui-selected": {
              color: Colors.blue[1200],
              opacity: 1,
            },
            "& .MuiIcon-root": {
              marginBottom: 0,
            },
          }}
        />
      ))}
    </BottomNavigation>
  );
};

export { AthleteBottomNavigation };
