import { Icon, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../shared/themes";
import { RequireNewProgramModal } from "./modals/RequireNewProgramModal";
import { YourScheduleSkeleton } from "./skeletons/YourScheduleSkeleton";
import { YouScheduleItem } from "./YouScheduleItem";
import { DashboardResponse } from "../../../../shared/api/dashboard/models";

interface YourScheduleProps {
  getUserIdForWorkoutCreation: (userId: number) => void;
  dashboardResponse: DashboardResponse | undefined;
  isLoading: boolean;
}

const YourSchedule: FC<YourScheduleProps> = ({
  getUserIdForWorkoutCreation,
  dashboardResponse,
  isLoading,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);
  const { t } = useTranslation();

  const clientsWhoRequireANewProgramCount =
    dashboardResponse?.clientsWhoRequireANewProgramCount;

  const clientsWhoRequireANewProgram =
    dashboardResponse?.clientsWhoRequireANewProgram;

  const showThreeClientArray = clientsWhoRequireANewProgram?.slice(0, 3);

  if (isLoading) {
    return <YourScheduleSkeleton />;
  }

  if (clientsWhoRequireANewProgramCount === 0) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "auto",
          bgcolor: `${Colors.gray[100]}`,
          mb: "16px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "140%",
              textTransform: "none",
              color: `${Colors.blue[1300]}`,
            }}
          >
            {t("your-schedule.users-don't-need-programs-title")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            sx={{
              color: `${Colors.green[900]}`,
              mb: "16px",
            }}
          >
            <TaskAltRoundedIcon />
          </Icon>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "16px",
              color: `${Colors.gray[1000]}`,
              textAlign: "center",
            }}
          >
            {t("your-schedule.users-don't-need-programs-description")}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        bgcolor: `${Colors.gray[100]}`,
        mb: "16px",
        padding: "20px",
      }}
    >
      <RequireNewProgramModal
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        usersData={dashboardResponse!}
        getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
      />
      <Typography
        sx={{
          width: "auto",
          height: "22px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "140%",
          color: `${Colors.blue[1300]}`,
          mb: "16px",
        }}
      >
        {t("your-schedule.title")}
      </Typography>
      <Typography
        sx={{
          width: "auto",
          height: "22px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "140%",
          color: `${Colors.blue[1300]}`,
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            height: "22px",
            fontFamily: "Inter",
            fontStyle: "normal",
            display: "inline",
          }}
        >
          {clientsWhoRequireANewProgramCount}{" "}
          {clientsWhoRequireANewProgramCount === 1
            ? t("your-schedule.client")
            : t("your-schedule.clients")}
        </Typography>{" "}
        {clientsWhoRequireANewProgramCount === 1
          ? "needs a new program"
          : "need a new program"}
      </Typography>
      {showThreeClientArray?.map((item) => (
        <YouScheduleItem
          key={item.id}
          item={item}
          getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
        />
      ))}
      <Box
        sx={{
          width: "100%",
          bgcolor: `${Colors.gray[1300]}`,
          height: "30px",
        }}
      >
        <Button
          onClick={handleOpenModal}
          variant="outlined"
          sx={{
            width: "100%",
            height: "30px",
            color: "primary",
            textTransform: "none",
            bgcolor: `${Colors.blue[1700]}`,
          }}
        >
          {t("your-schedule.view-all")}
        </Button>
      </Box>
    </Box>
  );
};

export { YourSchedule };
