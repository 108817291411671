import { styled } from "@mui/material/styles";
import { Box, Card, Stack } from "@mui/material";
import { Colors } from "shared/themes";

// Table Styles
export const StyledTableContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "10px",
  flex: 1,
});

export const StyledHeaderRow = styled("div")({
  display: "flex",
  backgroundColor: "#e0e0e0",
  borderBottom: "2px solid #ccc",
});

export const StyledHeaderCell = styled("div")<{ width: string }>(
  ({ width }) => ({
    width,
    textAlign: "left",
    padding: "12px 8px",
    fontWeight: 600,
    color: "#333",
    borderRight: "1px solid #ccc",
    boxSizing: "border-box",
    height: "50px",
    display: "flex",
    alignItems: "center",
  })
);

export const StyledRow = styled("div")<{ isEven: boolean }>(({ isEven }) => ({
  display: "flex",
  borderBottom: "1px solid #ccc",
  backgroundColor: isEven ? "#ffffff" : "#f7fcff",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

export const StyledCell = styled("div")<{ width: string }>(({ width }) => ({
  width,
  padding: "8px",
  color: "#212529",
  fontSize: "14px",
  borderRight: "1px solid #ccc",
  boxSizing: "border-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  height: "50px",
  display: "flex",
  alignItems: "center",
}));

export const StyledInput = styled("input")({
  width: "100%",
  height: "100%",
  padding: "8px",
  border: "none",
  background: "transparent",
  "&:focus": {
    outline: "2px solid #1976d2",
  },
});

// Card and Container Styles
export const UserInfoCard = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: Colors.gray[50],
  boxShadow: "none",
  marginTop: theme.spacing(2),
}));

export const UserDetails = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const PlanInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  color: Colors.gray[600],
}));

export const MacroSummaryCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: Colors.gray[50],
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    gap: theme.spacing(4),
  },
}));

export const MacroStatusDot = styled("span")<{ color: string }>(
  ({ color }) => ({
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "8px",
  })
);

export const MacroTable = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "16px",
  "& th, & td": {
    padding: "12px",
    textAlign: "left",
    borderBottom: `1px solid ${Colors.gray[200]}`,
  },
  "& th": {
    backgroundColor: Colors.gray[100],
    fontWeight: 600,
    color: Colors.gray[900],
  },
  "& tr:last-child td": {
    borderBottom: "none",
  },
  "& .macro-cell": {
    display: "flex",
    alignItems: "center",
  },
});

export const MealContainer = styled(Box)({
  marginBottom: "2rem",
  backgroundColor: "#fff",
  borderRadius: "4px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
  padding: "1rem",
  width: "100%",
});

export const MealHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "1rem",
  gap: "8px",
});

export const EditableTypography = styled("div")({
  padding: "4px 8px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#f5f5f5",
    cursor: "text",
  },
  "&:focus": {
    outline: "2px solid #1976d2",
    backgroundColor: "#fff",
  },
});

// Constants
export const MACRO_COLORS = {
  protein: Colors.blue[700],
  carbs: Colors.green[500],
  fats: Colors.yellow[200],
};

export const columnWidths = {
  food: "25%",
  portion: "15%",
  calories: "12%",
  protein: "12%",
  carbs: "12%",
  fats: "12%",
  actions: "12%",
  servingUnit: "10%",
};
