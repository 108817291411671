import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Icon, Modal, TextField, Typography, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Colors } from "shared/themes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyType } from "shared/helpers";
import {
  useCreateBaseDraftWorkoutProgramMutation,
  useCreateBaseTemplateWorkoutProgramMutation,
  ModernProgramBuilderResponse,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { Routes } from "shared/routers";
import { OrganisationClientSelector } from "pages/UserNotes/OrganisationClientSelector";

interface AddBaseWorkoutProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  isTemplateProgram: boolean;
  preselectedUserId?: number;
}

const AddBaseWorkoutProgramModal: FC<AddBaseWorkoutProgramModalProps> = ({
  isOpenModal,
  handleCloseModal,
  isTemplateProgram,
  preselectedUserId = null,
}) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [createBaseDraftWorkoutProgram, { isLoading }] =
    useCreateBaseDraftWorkoutProgramMutation();

  const [createBaseTemplateWorkoutProgram, { isLoading: isTemplateLoading }] =
    useCreateBaseTemplateWorkoutProgramMutation();

  const [selectedUserId, setSelectedUserId] = useState<number | null>(
    preselectedUserId
  );

  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [startDate, setStartDate] = useState("");
  const [daysPerWeek, setDaysPerWeek] = useState(4);
  const [weeksInProgram, setWeeksInProgram] = useState(4);

  const onSubmit = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      if (!selectedUserId && !isTemplateProgram) {
        setErrorMessage("A client is required");
        return;
      }

      if (!title) {
        setErrorMessage("Title is required");
        return;
      }

      if (!startDate && !isTemplateProgram) {
        setErrorMessage("Start date is required");
        return;
      }

      if (!daysPerWeek) {
        setErrorMessage("Days per week is required");
        return;
      }

      if (!weeksInProgram) {
        setErrorMessage("Weeks in program is required");
        return;
      }

      if (daysPerWeek > 7) {
        setErrorMessage("Days per week must be less than or equal to 7");
        return;
      }

      if (daysPerWeek < 1) {
        setErrorMessage("Days per week must be greater than 0");
        return;
      }

      if (weeksInProgram < 1) {
        setErrorMessage("Weeks in program must be greater than 0");
        return;
      }

      if (weeksInProgram > 52) {
        setErrorMessage("Weeks in program must be less than or equal to 52");
        return;
      }

      const programToSave = {
        title,
        notes,
        startDate: isTemplateProgram ? null : startDate,
        daysPerWeek,
        weeksInProgram,
        userId: isTemplateProgram ? null : selectedUserId,
        workoutProgramType: isTemplateProgram ? "Template" : "UserProgram",
      } as ModernProgramBuilderResponse;

      if (!isTemplateProgram) {
        const response = await createBaseDraftWorkoutProgram(
          programToSave
        ).unwrap();

        setSuccessMessage(
          "Program created successfully. You will be redirected to the program builder in a few seconds."
        );

        setTimeout(() => {
          navigate(`${Routes.modernWorkoutProgramBuilder.url}/${response.id}`);
        }, 3000);
      } else {
        const response = await createBaseTemplateWorkoutProgram(
          programToSave
        ).unwrap();

        setSuccessMessage(
          "Template created successfully. You will be redirected to the program builder in a few seconds."
        );

        setTimeout(() => {
          navigate(`${Routes.modernWorkoutProgramBuilder.url}/${response.id}`);
        }, 3000);
      }
    } catch (error: AnyType) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleDateChange = (date: string | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            width: "50%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Create new {isTemplateProgram ? "template" : "program"}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "8px",
            }}
          >
            {isTemplateProgram
              ? "Create a new template that you can share with multiple clients. You will be able to add workouts to this template once it is created."
              : "Create a new program for a client. You will be able to add workouts to this program once it is created."}
          </Typography>
          {!isTemplateProgram && !preselectedUserId && (
            <OrganisationClientSelector
              setSelectedUserId={setSelectedUserId}
              selectedUserId={selectedUserId}
            />
          )}
          <TextField
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "24px" }}
            type="text"
            label={isTemplateProgram ? "Template title" : "Program title"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {!isTemplateProgram && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => handleDateChange(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "100%",
                      height: "56px",
                      mb: "24px",
                      mt: "24px",
                    }}
                    label="Start Date"
                  />
                )}
              />
            </LocalizationProvider>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              sx={{ width: "48%", height: "56px" }}
              type="number"
              value={daysPerWeek}
              label="Days per week"
              onChange={(e) => setDaysPerWeek(Number(e.target.value))}
            />
            <TextField
              sx={{ width: "48%", height: "56px" }}
              type="number"
              value={weeksInProgram}
              label={
                isTemplateProgram ? "Weeks in template" : "Weeks in program"
              }
              onChange={(e) => setWeeksInProgram(Number(e.target.value))}
            />
          </Box>
          <TextField
            sx={{ width: "100%", height: "56px", mb: "48px", mt: "24px" }}
            type="text"
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={3}
          />
          <LoadingButton
            sx={{
              width: "100%",
              height: "36px",
              background: `${Colors.blue[1200]}`,
              mt: "24px",
            }}
            size="large"
            loading={isLoading || isTemplateLoading}
            disabled={isLoading || isTemplateLoading}
            variant="contained"
            onClick={onSubmit}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              Create {isTemplateProgram ? "template" : "program"}
            </Typography>
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { AddBaseWorkoutProgramModal };
