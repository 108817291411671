import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Button, Icon, Rating, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Colors } from "../../../../shared/themes";
import {
  WorkoutSession,
  useSendFeedbackMutation,
} from "../../../../shared/api";
import { SubmitFeedback } from "./SubmitFeedback";
import { Routes } from "../../../../shared/routers";

interface FeedbackProps {
  showFeedback: boolean;
  finishedWorkout: WorkoutSession;
  workoutId: number;
  linkTo?: string;
}

interface SubmitValues {
  description: string;
  rating: number;
  workoutProgramId: number;
  workoutId: number;
}

const FeedbackSchema = Yup.object().shape({
  rating: Yup.number()
    .moreThan(0, "feedback.rating-required")
    .required("feedback.rating-required"),
});

const Feedback: FC<FeedbackProps> = ({
  showFeedback,
  finishedWorkout,
  workoutId,
  linkTo = Routes.dashboard.url,
}) => {
  const { t } = useTranslation();
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();
  const [feedbackFinalStep, setFeedbackFinalStep] = useState(false);

  const onSubmit = async (params: SubmitValues) => {
    try {
      await sendFeedback(params).unwrap();
      setFeedbackFinalStep(true);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        description: "",
        rating: 0,
        workoutProgramId: finishedWorkout.workoutProgramId,
        workoutId,
      },
      validationSchema: FeedbackSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    });

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "175px",
          width: "100%",
          height: "76%",
          bgcolor: `${Colors.gray[100]}`,
          padding: "0px 24px 0px 24px ",
          zIndex: 9999,
          display: showFeedback ? "block" : "none",
        }}
      >
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "140%",
            color: `${Colors.gray[1200]}`,
            mb: "21px",
          }}
        >
          {t("feedback.leave-feedback")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "60x",
              width: "100%",
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "140%",
                color: `${Colors.gray[1000]}`,
                mb: "8px",
              }}
            >
              {t("feedback.how-was-your-workout")}
            </Typography>
            <Rating
              value={Number(values.rating)}
              onBlur={handleBlur("rating")}
              onChange={handleChange("rating")}
              sx={{
                fontSize: "1.87rem",
              }}
            />
            {errors.rating && (
              <Box sx={{ color: `${Colors.red[200]}`, fontSize: "14px" }}>
                {t(errors.rating)}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "140%",
              color: `${Colors.gray[1000]}`,
            }}
          >
            {t("feedback.further-comments")}
          </Box>
          <TextField
            sx={{
              zIndex: 9999,
            }}
            variant="outlined"
            name="description"
            fullWidth
            multiline
            type="text"
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Button
            onClick={() => handleSubmit()}
            disabled={isLoading}
            variant="contained"
            color="primary"
            sx={{
              marginTop: "24px",
              width: "100%",
              height: "42px",
              textTransform: "none",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "26px",
              letterSpacing: "0.46px",
            }}
          >
            {t("feedback.send-feedback")}
            <Icon
              sx={{
                marginLeft: "8px",
              }}
            >
              send_icon
            </Icon>
          </Button>
        </Box>
      </Box>
      {feedbackFinalStep && <SubmitFeedback linkTo={linkTo} />}
    </>
  );
};

export { Feedback };
