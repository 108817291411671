/* eslint-disable */
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import { produce } from "immer";
import { useVirtualizer } from "@tanstack/react-virtual";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  Typography,
  useMediaQuery,
  Grid,
  IconButton,
  Tab,
  FormControlLabel,
  Switch,
  TextField,
  Card,
  Tooltip,
  Autocomplete,
  Button,
  Icon,
  ToggleButton,
} from "@mui/material";
import { Box, Stack, style } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { setIsModified } from "shared/stores/workoutBuilder/slice";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  ModernProgramBuilderResponse,
  useGetModernWorkoutProgramsQuery,
  useGetWorkoutProgramGroupItemsQuery,
  useUpdateDraftWorkoutProgramMutation,
  useCreateNewVersionOfExistingWorkoutProgramMutation,
  useCompleteWorkoutProgramAndShareMutation,
  WorkoutProgramGroupItem,
  WorkoutProgramGroupItemParams,
  useCheckStripeIntegrationQuery,
  CompleteAndShareModernProgramBuilderRequest,
} from "shared/api";
import { ClientInsights } from "pages/Users/ClientInsights";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { TrainingInsightsContent } from "pages/TrainingInsights/TrainingInsightsContent";
import { SaveNewVersionModal } from "./SaveNewVersionModal";
import { SavingProgramModal } from "./SavingProgramModal";
import { CompleteAndShareModal } from "./CompleteAndShareModal";
import { validationRules } from "./validationRules";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { MealContainer } from "pages/Nutrition/styles";
import { MealHeader } from "pages/Nutrition/styles";
import { ModernProgramBuilderSettingsSidebar } from "./ModernProgramBuilderSettingsSidebar";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import { MergeTypeOutlined } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Checkbox from '@mui/material/Checkbox';

interface ParsedProgression {
  type: "RPE" | "RIR" | "1RM";
  value: number;
  frequency: number;
  oneRmValue?: number;
  unit?: string;
}

// Update the getColumnWidths function to better handle column widths
const getColumnWidths = (showNotes: boolean, showLogs: boolean, rpeProgressionView: boolean, showSupersets: boolean, show1RMProgression: boolean) => {
  // Base widths for always-visible columns
  const baseWidths = {
    order: 4,
    exerciseName: show1RMProgression ? 20 : (showSupersets ? 25 : 30),
    sets: 6,
    reps: 6,
    intensity: 6,
    load: 8,
    rest: 6,
  };

  // Optional columns
  const optionalWidths = {
    supersetGroup: showSupersets ? 8 : 0,
    progression: rpeProgressionView ? 10 : 0,
    maximumWeight: show1RMProgression ? 8 : 0,
    maximumWeightPercentage: show1RMProgression ? 6 : 0,
    maximumWeightIncreasePercentage: show1RMProgression ? 6 : 0,
    notes: showNotes ? 25 : 0,
    readOnlyCompletedLog: showLogs ? 25 : 0,
  };

  // Actions column
  const actionWidth = {
    actions: 4
  };

  // Combine all widths
  const combinedWidths = {
    ...baseWidths,
    ...optionalWidths,
    ...actionWidth
  };

  // Filter out columns with zero width
  const visibleWidths = Object.fromEntries(
    Object.entries(combinedWidths).filter(([_, width]) => width > 0)
  );

  // Calculate total visible width
  const totalWidth = Object.values(visibleWidths).reduce((sum, width) => sum + width, 0);

  // Normalize widths to ensure they sum to 100%
  const normalizedWidths: { [key: string]: number } = {};
  Object.entries(visibleWidths).forEach(([key, width]) => {
    normalizedWidths[key] = (width / totalWidth) * 100;
  });

  return normalizedWidths;
};

// Update the StyledToggleButton styling
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '13px', // Increased from 12px
  padding: '8px 16px', // Increased from 8px 12px
  borderRadius: '4px',
  height: '32px', // Increased from 28px
  border: `1px solid ${Colors.gray[300]}`,
  display: 'flex',
  alignItems: 'center',
  gap: '6px', // Increased from 4px
  '&.MuiToggleButton-root': {
    color: Colors.gray[700],
    backgroundColor: 'white',
  },
  '&.Mui-selected': {
    color: Colors.blue[700],
    backgroundColor: Colors.blue[200],
    '&:hover': {
      backgroundColor: Colors.blue[100],
    },
  },
  '&:hover': {
    backgroundColor: Colors.gray[100],
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px', // Increased from 16px
  },
  '& .info-icon': {
    fontSize: '16px', // Increased from 14px
    marginLeft: '2px',
    color: Colors.gray[400],
    '&:hover': {
      color: Colors.blue[500],
    },
  },
}));

// Create a wrapper component for toggle buttons with tooltips
const TooltipToggleButton = ({
  title,
  children,
  ...props
}: {
  title?: string;
  children: React.ReactNode;
  [key: string]: any;
}) => {
  if (!title) {
    return <StyledToggleButton value={{}} {...props}>{children}</StyledToggleButton>;
  }

  return (
    <Tooltip
      title={title}
      placement="top"
      arrow
      enterDelay={200}
      leaveDelay={0}
    >
      <StyledToggleButton value={{}} {...props}>
        {children}
      </StyledToggleButton>
    </Tooltip>
  );
};

// Styled Components
const UserInfoCard = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: Colors.gray[50],
  boxShadow: "none",
}));

const UserDetails = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const ProgramInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  color: Colors.gray[600],
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
}));

// Update StyledInput to match NutritionPlanView styling
const StyledInput = styled("input")({
  width: "100%",
  height: "100%",
  padding: "6px", // Reduced padding
  boxSizing: "border-box",
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  fontSize: "13px", // Reduced font size
  fontFamily: "Inter",
  "&:focus": {
    outline: "none",
  },
  "&::placeholder": {
    color: Colors.gray[400],
  },
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
});

const getFieldKey = (
  weekIndex: number,
  dayIndex: number,
  exerciseIndex: number,
  fieldName: string
) => {
  return `week-${weekIndex}_day-${dayIndex}_exercise-${exerciseIndex}_field-${fieldName}`;
};

// Updated InputCell Component
const InputCell = ({
  initialValue,
  onValueChange,
  inputRef,
  style,
  fieldNames,
  weekIndex,
  dayIndex,
  exerciseIndex,
  fieldName,
  inputRefs,
  maxIndices,
  isSelected,
  isEditing,
  onClick,
  onBlurEditing,
  onEnterEditMode,
}: {
  initialValue: string;
  onValueChange: (value: string) => void;
  inputRef: React.Ref<any>;
  style: React.CSSProperties;
  fieldNames: string[];
  weekIndex: number;
  dayIndex: number;
  exerciseIndex: number;
  fieldName: string;
  inputRefs: React.MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
  maxIndices: {
    weeks: number;
    daysPerWeek: number[];
    exercisesPerDay: number[][];
  };
  isSelected: boolean;
  isEditing: boolean;
  onClick: () => void;
  onBlurEditing: () => void;
  onEnterEditMode: () => void;
}) => {
  const [value, setValue] = useState(initialValue || "");
  const [isValid, setIsValid] = useState(true);
  const [cellErrorMessage, setCellErrorMessage] = useState("");
  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  useEffect(() => {
    const validate = validationRules[fieldName];
    if (validate) {
      const { isValid, cellErrorMessage } = validate(value);
      setIsValid(isValid);
      setCellErrorMessage(cellErrorMessage);
    } else {
      setIsValid(true);
      setCellErrorMessage("");
    }
  }, [value, fieldName]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (value !== initialValue) {
      onValueChange(value);
    }
    onBlurEditing();
    // After blur, reselect the cell for keyboard navigation
    onClick();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (value !== initialValue) {
        onValueChange(value);
      }
      onBlurEditing();
      // After Enter, reselect the cell for keyboard navigation
      onClick();
    }
  };

  const inputStyles: React.CSSProperties = {
    ...style,
    border: isEditing ? "1px solid #ccc" : "none",
    backgroundColor: isValid ? "transparent" : "#ffe6e6", // Light red background if invalid
    color: "#212529",
    fontSize: "14px",
    fontFamily: "Inter",
    width: "100%",
    height: "100%",
    padding: "8px",
    boxSizing: "border-box",
  };

  const handleClick = (e: React.MouseEvent) => {
    if (clickTimeout.current) {
      // If a second click happens within the timeout, it's a double click
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      onEnterEditMode(); // Trigger the double-click action (enter edit mode)
    } else {
      // If it's the first click, set a timeout to detect a double click
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null;
        onClick(); // Trigger the single-click action
      }, 300); // Timeout for detecting double-clicks
    }
  };

  if (isEditing) {
    return (
      <StyledInput
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={inputRef}
        style={inputStyles}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleKeyDown}
      />
    );
  }
  const cellContent = (
    <Typography
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        padding: "6px", // Reduced padding
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        backgroundColor: isValid ? "transparent" : Colors.red[200],
        fontSize: "13px", // Reduced font size
        fontFamily: "Inter",
        color: Colors.gray[900],
      }}
    >
      {value}
    </Typography>
  );

  if (!isValid && cellErrorMessage !== "") {
    return (
      <Tooltip title={cellErrorMessage} placement="top">
        {cellContent}
      </Tooltip>
    );
  }
  return cellContent;
};

// Main Component
const ModernProgramBuilder: FC = () => {
  const params = useParams();

  const [openSaveVersionModal, setOpenSaveVersionModal] =
    useState<boolean>(false);

  const [openCompleteAndShareModal, setOpenCompleteAndShareModal] =
    useState<boolean>(false);

  const [invoiceRequested, setInvoiceRequested] = useState(false);

  const [invoiceDueDate, setInvoiceDueDate] = useState<string>(
    dayjs().add(14, "day").format("YYYY-MM-DDTHH:mm:ss[Z]")
  );

  const handleInvoiceRequestedDateChange = (date: string | null) => {
    setInvoiceDueDate(date ?? invoiceDueDate);
  };

  const workoutProgramId = Number(params.workoutProgramId);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedExerciseIndices, setSelectedExerciseIndices] = useState<{
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
  } | null>(null);
  const [initialSearchTerm, setInitialSearchTerm] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [successMessage, setSuccessMessage] = useState<string>("");

  const [savingProgram, setSavingProgram] = useState<boolean>(false);

  const [showNotes, setShowNotes] = useState<boolean>(true);

  const [showLogs, setShowLogs] = useState<boolean>(false);

  const [showInsights, setShowInsights] = useState<boolean>(false);

  const [showProgramDetails, setShowProgramDetails] = useState<boolean>(false);

  const [duplicateView, setDuplicateView] = useState<boolean>(true);

  const [showRpeProgressionView, setShowRpeProgressionView] = useState<boolean>(false);

  const [showSupersets, setShowSupersets] = useState<boolean>(false);

  const [show1RMProgression, setShow1RMProgression] = useState<boolean>(false);

  const matches = useMediaQuery("(min-width:900px)");

  const dispatch = useTypedDispatch();

  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );

  const {
    data: hasStripeIntegration,
    isLoading: isLoadingCheckStripeIntegration,
  } = useCheckStripeIntegrationQuery();

  const [versionNumber, setVersionNumber] = useState<number>(1);

  const [selectedWorkoutProgram, setSelectedWorkoutProgram] =
    useState<ModernProgramBuilderResponse>();

  const showSidebar = matches && isOpenSidebar;

  const [updateDraftWorkoutProgram] = useUpdateDraftWorkoutProgramMutation();

  const [createNewVersionOfExistingWorkoutProgram] =
    useCreateNewVersionOfExistingWorkoutProgramMutation();

  const [completeWorkoutProgramAndShare] =
    useCompleteWorkoutProgramAndShareMutation();

  const {
    data: workoutPrograms,
    isLoading: isLoadingPrograms,
    refetch: refetchPrograms,
  } = useGetModernWorkoutProgramsQuery(workoutProgramId);

  const {
    data: workoutProgramGroupItems,
    isLoading: isLoadingProgramGroup,
    refetch: refetchVersions,
  } = useGetWorkoutProgramGroupItemsQuery({
    parentWorkoutProgramId: workoutProgramId,
  } as WorkoutProgramGroupItemParams);

  const findMatchingWorkoutProgram = (
    groupItems: WorkoutProgramGroupItem[],
    programs: ModernProgramBuilderResponse[],
    versionNumber: number
  ) => {
    const matchingGroupItem = groupItems?.find(
      (item) => item.versionNumber === versionNumber
    );

    if (matchingGroupItem) {
      const matchingWorkoutProgram = programs?.find(
        (item) => item.id === matchingGroupItem.childWorkoutProgramId
      );

      return matchingWorkoutProgram;
    }

    return null;
  };

  const handleCompleteWorkoutProgramAndShare = async () => {
    if (!selectedWorkoutProgram || !selectedWorkoutProgram.id) return;

    setSavingProgram(true);
    setSuccessMessage("");
    setErrorMessage("");
    setOpenCompleteAndShareModal(false);

    try {
      const saveDraftResponse = await updateDraftWorkoutProgram(
        selectedWorkoutProgram
      ).unwrap();

      const response = await completeWorkoutProgramAndShare({
        id: selectedWorkoutProgram.id,
        invoiceRequested: invoiceRequested && hasStripeIntegration,
        invoiceDueDate,
      } as CompleteAndShareModernProgramBuilderRequest).unwrap();

      setSuccessMessage("Program completed and shared successfully");
    } catch (error) {
      setErrorMessage(
        "Error completing program. Please try again. If the error persists, please save your work and contact support."
      );
    } finally {
      setInvoiceRequested(false);
      setSavingProgram(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  const handleUpdateDraftWorkoutProgram = async () => {
    if (!selectedWorkoutProgram) return;

    setSavingProgram(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await updateDraftWorkoutProgram(
        selectedWorkoutProgram
      ).unwrap();
      setSuccessMessage(
        selectedWorkoutProgram.workoutProgramType === "Template"
          ? "Template saved successfully"
          : "Program saved successfully"
      );
    } catch (error) {
      setErrorMessage("Error saving program. Please try again.");
    } finally {
      setSavingProgram(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  const handleCreateNewVersion = async () => {
    if (!selectedWorkoutProgram) return;
    setOpenSaveVersionModal(false);
    setSavingProgram(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await createNewVersionOfExistingWorkoutProgram(selectedWorkoutProgram).unwrap();
      await refetchPrograms();
      await refetchVersions();

      setVersionNumber(versionNumber + 1);

      setSuccessMessage("New version created successfully");
    } catch (error: any) {
      const errorMessage = error.data?.message || error.error || "Error creating new version. Please try again.";
      setErrorMessage(errorMessage);
    } finally {
      setSavingProgram(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3500);
    }
  };

  useEffect(() => {
    const workoutProgramGroupVersionsLoaded =
      workoutProgramGroupItems && workoutProgramGroupItems?.length !== 0;
    if (workoutProgramGroupVersionsLoaded) {
      setVersionNumber(workoutProgramGroupItems.length);
    }
  }, [workoutProgramGroupItems]);

  useEffect(() => {
    if (workoutPrograms) {
      if (versionNumber === 1) {
        setSelectedWorkoutProgram(workoutPrograms[0]);
      } else {
        const matchingWorkoutProgram = findMatchingWorkoutProgram(
          workoutProgramGroupItems!,
          workoutPrograms!,
          versionNumber
        );

        if (matchingWorkoutProgram) {
          setSelectedWorkoutProgram(matchingWorkoutProgram);
        }
      }
    }
  }, [versionNumber, workoutPrograms, workoutProgramGroupItems]);

  useEffect(() => {
    refetchPrograms();
    refetchVersions();
    // eslint-disable-next-line
  }, []);

  const handleTitleChange = (newValue: string) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram || prevProgram.title === newValue) return prevProgram;

      const updatedProgram = { ...prevProgram };
      updatedProgram.title = newValue;

      dispatch(setIsModified(true));
      return updatedProgram;
    });
  };

  const handleProgramNotesChange = (newValue: string) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram || prevProgram.notes === newValue) return prevProgram;

      const updatedProgram = { ...prevProgram };
      updatedProgram.notes = newValue;

      dispatch(setIsModified(true));
      return updatedProgram;
    });
  };

  const parseProgression = (
    progressionStr: string
  ): ParsedProgression | null => {
    progressionStr = progressionStr.trim().toUpperCase();

    // Patterns for RPE and RIR
    const rpeRirPattern =
      /^(RPE|RIR)\s+([\d\.]+(?:-\d+(\.\d+)?)?)\s+E\/(\d+)W$/;
    const rpeRirSimplePattern =
      /^(RPE|RIR)\s+([\d\.]+(?:-\d+(\.\d+)?)?)\s+E\/W$/;

    // Pattern for 1RM
    const oneRmPattern = /^1RM\s+([\d\.]+)(KG|LBS)\s+([\d\.]+)%\s+E\/(\d+)W$/;
    const oneRmSimplePattern = /^1RM\s+([\d\.]+)(KG|LBS)\s+([\d\.]+)%\s+E\/W$/;

    let match =
      progressionStr.match(rpeRirPattern) ||
      progressionStr.match(rpeRirSimplePattern);
    if (match) {
      const type = match[1] as "RPE" | "RIR";
      const value = parseFloat(match[2]);
      const frequency = match[4] ? parseInt(match[4], 10) : 1;
      return { type, value, frequency };
    }

    match =
      progressionStr.match(oneRmPattern) ||
      progressionStr.match(oneRmSimplePattern);
    if (match) {
      const type = "1RM";
      const oneRmValue = parseFloat(match[1]);
      const unit = match[2];
      const value = parseFloat(match[3]); // Percentage increment
      const frequency = match[4] ? parseInt(match[4], 10) : 1;
      return { type, value, frequency, oneRmValue, unit };
    }

    // If no patterns matched, return null
    return null;
  };

  const applyRpeProgression = (
    baseIntensity: string,
    progressionValue: number | [number, number],
    weekIndex: number,
    type: "RPE",
    frequency: number
  ): string => {
    // Extract numeric value(s) from baseIntensity
    const intensityPattern = /([\d\.]+)(?:-([\d\.]+))?\s*(RPE|RIR)?/i;
    const match = baseIntensity.match(intensityPattern);

    if (!match) return baseIntensity;

    const baseValue = parseFloat(match[1]);
    const baseValueMax = match[2] ? parseFloat(match[2]) : null;
    const unit = match[3] ? match[3].toUpperCase() : type;

    // Calculate how many times the progression should be applied
    const timesToApply = Math.floor(weekIndex / frequency);

    let newValue: number;
    let newValueMax: number | null = null;

    if (typeof progressionValue === "number") {
      const totalIncrement = progressionValue * timesToApply;

      if (type === "RPE") {
        newValue = baseValue + totalIncrement;
        if (baseValueMax !== null) {
          newValueMax = baseValueMax + totalIncrement;
        }
      } else {
        newValue = baseValue - totalIncrement;
        if (baseValueMax !== null) {
          newValueMax = baseValueMax - totalIncrement;
        }
      }
    } else {
      // progressionValue is a range [minIncrement, maxIncrement]
      const [minIncrementPerFreq, maxIncrementPerFreq] = progressionValue;
      const minTotalIncrement = minIncrementPerFreq * timesToApply;
      const maxTotalIncrement = maxIncrementPerFreq * timesToApply;

      if (type === "RPE") {
        newValue = baseValue + minTotalIncrement;
        newValueMax =
          (baseValueMax !== null ? baseValueMax : baseValue) +
          maxTotalIncrement;
      } else {
        newValue = baseValue - minTotalIncrement;
        newValueMax =
          (baseValueMax !== null ? baseValueMax : baseValue) -
          maxTotalIncrement;
      }
    }

    // Enforce min/max limits
    if (type === "RPE") {
      if (newValue > 10) newValue = 10;
      if (newValueMax !== null && newValueMax > 10) newValueMax = 10;
    } else {
      if (newValue < 0) newValue = 0;
      if (newValueMax !== null && newValueMax < 0) newValueMax = 0;
    }

    // Build the new intensity string
    if (newValueMax !== null && newValue !== newValueMax) {
      return `${newValue.toFixed(1)}-${newValueMax.toFixed(1)} ${unit}`;
    } else {
      return `${newValue.toFixed(1)} ${unit}`;
    }
  };

  // Add this helper function to calculate load from 1RM and percentage
  const calculateLoadFrom1RM = (maximumWeight: string, maximumWeightPercentage: string): string => {
    const oneRM = parseFloat(maximumWeight);
    const percentage = parseFloat(maximumWeightPercentage);

    if (!isNaN(oneRM) && !isNaN(percentage)) {
      const calculatedLoad = (oneRM * percentage) / 100;
      return calculatedLoad.toFixed(1);
    }
    return "";
  };

  const defaultExerciseObject = {
    exerciseId: 0,
    substitueExerciseId: null,
    substitueExerciseName: "",
    order: 0,
    supersetGroup: "",
    exerciseName: "",
    type: "",
    workoutItemWeightUnit: "",
    sets: "",
    reps: "",
    progression: "",
    maximumWeight: "",
    maximumWeightPercentage: "",
    maximumWeightIncreasePercentage: "",
    intensity: "",
    load: "",
    rest: "",
    notes: "",
    readOnlyCompletedLog: "",
  };

  // Update the calculate1RMProgressiveLoad function to return both load and percentage
  const calculate1RMProgressiveLoad = (
    maximumWeight: string,
    maximumWeightPercentage: string,
    maximumWeightIncreasePercentage: string,
    weekIndex: number
  ): { load: string; percentage: string } => {
    const oneRM = parseFloat(maximumWeight);
    const basePercentage = parseFloat(maximumWeightPercentage || "100");
    const increasePercentage = parseFloat(maximumWeightIncreasePercentage || "0");

    if (!isNaN(oneRM) && !isNaN(basePercentage) && !isNaN(increasePercentage)) {
      // Calculate the cumulative percentage for this week
      const weeklyPercentage = basePercentage + (increasePercentage * weekIndex);
      // Ensure percentage doesn't exceed 100%
      const finalPercentage = Math.min(weeklyPercentage, 100);
      // Calculate the load based on the cumulative percentage
      const calculatedLoad = (oneRM * finalPercentage) / 100;
      
      return {
        load: calculatedLoad.toFixed(1),
        percentage: finalPercentage.toFixed(1)
      };
    }
    return { load: "", percentage: "" };
  };

  // Update the handleInputChangeValue function to handle progressive loading from any week
  const handleInputChangeValue = useCallback(
    (
      newValue: any,
      weekIndex: number,
      dayIndex: number,
      exerciseIndex: number,
      field: string
    ) => {
      setSelectedWorkoutProgram((prevProgram) =>
        produce(prevProgram, (draft) => {
          if (!prevProgram) return;
          if (!draft) return;
  
          // Ensure indices are within bounds
          if (
            weekIndex < 0 ||
            weekIndex >= draft.weeks.length ||
            dayIndex < 0 ||
            dayIndex >= draft.weeks[weekIndex].days.length ||
            exerciseIndex < 0 ||
            exerciseIndex >=
              draft.weeks[weekIndex].days[dayIndex].exercises.length
          ) {
            console.error(
              "Invalid indices provided to handleInputChangeValue"
            );
            return;
          }
  
          // Update the specific field in the targeted exercise
          const targetExercise =
            draft.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex];
  
          if (targetExercise) {
            (targetExercise as any)[field] = newValue;
          } else {
            // Initialize with default values if it doesn't exist
            draft.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex] = {
              ...defaultExerciseObject,
              [field]: newValue,
            };
          }
  
          let isProgressionApplied = false;
          let is1rmProgressionApplied = false;
  
          // Define fields related to progression
          const progressionFields = [
            "progression",
            "intensity",
            "load",
            "maximumWeight",
            "maximumWeightPercentage",
            "maximumWeightIncreasePercentage",
          ];
  
          // Apply progression logic if necessary
          if (progressionFields.includes(field)) {
            // Handle 1RM progression
            const maximumWeight =
              field === "maximumWeight"
                ? newValue
                : targetExercise.maximumWeight;
            const maximumWeightPercentage =
              field === "maximumWeightPercentage"
                ? newValue
                : targetExercise.maximumWeightPercentage;
            const maximumWeightIncreasePercentage =
              field === "maximumWeightIncreasePercentage"
                ? newValue
                : targetExercise.maximumWeightIncreasePercentage;
  
            if (maximumWeight && maximumWeightPercentage) {
              if (maximumWeightIncreasePercentage) {
                is1rmProgressionApplied = true;
  
                // Apply 1RM progression starting from current week
                for (let w = weekIndex; w < draft.weeks.length; w++) {
                  let exercise =
                    draft.weeks[w].days[dayIndex].exercises[exerciseIndex];
  
                  if (!exercise) {
                    // Initialize if it doesn't exist
                    exercise = { ...defaultExerciseObject };
                    draft.weeks[w].days[dayIndex].exercises[exerciseIndex] =
                      exercise;
                  }
  
                  // Calculate progressive load and percentage for each week
                  const { load, percentage } = calculate1RMProgressiveLoad(
                    maximumWeight,
                    maximumWeightPercentage,
                    maximumWeightIncreasePercentage,
                    w - weekIndex
                  );
  
                  exercise.load = load;
                  exercise.maximumWeightPercentage = percentage;
                }
              } else {
                // Simple 1RM calculation without progression
                const calculatedLoad = calculateLoadFrom1RM(
                  maximumWeight,
                  maximumWeightPercentage
                );
                if (calculatedLoad) {
                  targetExercise.load = calculatedLoad;
  
                  if (duplicateView && weekIndex === 0) {
                    draft.weeks.forEach((week, idx) => {
                      if (idx === 0) return;
                      const exercise =
                        week.days[dayIndex].exercises[exerciseIndex];
                      if (exercise) {
                        (exercise as any)[field] = newValue;
                        exercise.load = calculatedLoad;
                      }
                    });
                  }
                }
              }
            }
  
            // Handle RPE progression
            const progressionStr =
              field === "progression"
                ? newValue
                : targetExercise.progression;
            const intensityStr =
              field === "intensity" ? newValue : targetExercise.intensity;
  
            if (progressionStr) {
              const parsedProgression = parseProgression(progressionStr);
  
              if (parsedProgression) {
                const frequency = parsedProgression.frequency || 1;
  
                for (let w = weekIndex + 1; w < draft.weeks.length; w++) {
                  let exercise =
                    draft.weeks[w].days[dayIndex].exercises[exerciseIndex];
  
                  if (!exercise) {
                    // Initialize if it doesn't exist
                    exercise = { ...defaultExerciseObject };
                    draft.weeks[w].days[dayIndex].exercises[exerciseIndex] =
                      exercise;
                  }
  
                  const timesToApply = Math.floor(
                    (w - weekIndex) / frequency
                  );
  
                  if (timesToApply > 0) {
                    if (
                      parsedProgression.type === "RPE" &&
                      intensityStr
                    ) {
                      isProgressionApplied = true;
                      exercise.intensity = applyRpeProgression(
                        intensityStr,
                        parsedProgression.value,
                        w - weekIndex,
                        parsedProgression.type,
                        frequency
                      );
                    }
                  } else {
                    // Copy previous week's values
                    const prevExercise =
                      draft.weeks[w - 1].days[dayIndex].exercises[
                        exerciseIndex
                      ];
                    exercise.intensity = prevExercise.intensity;
                    exercise.load = prevExercise.load;
                  }
                }
              }
            }
          }
  
          // Handle duplication when no progression is applied
          if (
            duplicateView &&
            weekIndex === 0
          ) {
            draft.weeks.forEach((week, idx) => {
              if (idx === 0) return;
              let exercise = week.days[dayIndex].exercises[exerciseIndex];
              if (exercise) {
                if (
                  (!isProgressionApplied || field !== "intensity") &&
                  (!is1rmProgressionApplied || field !== "load" && field !== "maximumWeightPercentage")
                ) {
                  (exercise as any)[field] = newValue;
                }
              } else {
                // Initialize exercise if it doesn't exist
                exercise = { ...defaultExerciseObject, [field]: newValue };
                week.days[dayIndex].exercises[exerciseIndex] = exercise;
              }
            });
          }
          
  
          // Mark the state as modified
          dispatch(setIsModified(true));
        })
      );
    },
    [dispatch, duplicateView]
  );
  
  const handleAddExercise = (weekIndex: number, dayIndex: number) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram) return prevProgram;

      const updatedProgram = { ...prevProgram };
      const updatedWeeks = [...updatedProgram.weeks];
      const week = { ...updatedWeeks[weekIndex] };
      const updatedDays = [...week.days];
      const day = { ...updatedDays[dayIndex] };
      const updatedExercises = [...day.exercises];

      // Determine the next order number
      const nextOrder =
        updatedExercises.length > 0
          ? Math.max(...updatedExercises.map((ex) => ex.order || 0)) + 1
          : 1;

      // Create a new exercise object with the correct order
      const newExercise = {
        exerciseId: 0,
        substitueExerciseId: null,
        substitueExerciseName: "",
        order: nextOrder, // Set the correct order
        supersetGroup: "",
        exerciseName: "",
        type: "",
        workoutItemWeightUnit: "",
        sets: "1",
        reps: "",
        progression: "",
        maximumWeight: "",
        maximumWeightPercentage: "",
        maximumWeightIncreasePercentage: "",
        intensity: "",
        load: "",
        rest: "",
        notes: "",
        readOnlyCompletedLog: "",
      };

      updatedExercises.push(newExercise);

      day.exercises = updatedExercises;
      updatedDays[dayIndex] = day;
      week.days = updatedDays;
      updatedWeeks[weekIndex] = week;
      updatedProgram.weeks = updatedWeeks;

      // If duplicateView is enabled and adding to Week 1, add to other weeks as well
      if (duplicateView && weekIndex === 0) {
        updatedWeeks.forEach((wk, idx) => {
          if (idx === 0) return; // Skip Week 1
          const duplicatedWeek = { ...wk };
          const duplicatedDays = [...duplicatedWeek.days];
          if (duplicatedDays[dayIndex]) {
            const duplicatedDay = { ...duplicatedDays[dayIndex] };
            const duplicatedExercises = [...duplicatedDay.exercises];

            // Determine the next order for duplicated exercises
            const duplicatedNextOrder =
              duplicatedExercises.length > 0
                ? Math.max(...duplicatedExercises.map((ex) => ex.order || 0)) +
                1
                : 1;

            // Add the same exercise with updated order
            duplicatedExercises.push({
              ...newExercise,
              order: duplicatedNextOrder,
            });
            duplicatedDay.exercises = duplicatedExercises;
            duplicatedDays[dayIndex] = duplicatedDay;
            duplicatedWeek.days = duplicatedDays;
            updatedWeeks[idx] = duplicatedWeek;
          }
        });
        updatedProgram.weeks = updatedWeeks;
      }

      return updatedProgram;
    });

    dispatch(setIsModified(true));
  };

  const handleDeleteExercise = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => {
    setSelectedWorkoutProgram((prevProgram) =>
      produce(prevProgram, (draft) => {
        if (!prevProgram) return;
        if (!draft) return;

        // Validate indices to prevent runtime errors
        if (
          weekIndex < 0 ||
          weekIndex >= draft.weeks.length ||
          dayIndex < 0 ||
          dayIndex >= draft.weeks[weekIndex].days.length ||
          exerciseIndex < 0 ||
          exerciseIndex >=
          draft.weeks[weekIndex].days[dayIndex].exercises.length
        ) {
          console.error("Invalid indices provided to handleDeleteExercise");
          return;
        }

        // Navigate to the specific exercise
        const week = draft.weeks[weekIndex];
        const day = week.days[dayIndex];
        const updatedExercises = day.exercises;

        // Remove the exercise at the specified index
        updatedExercises.splice(exerciseIndex, 1);

        // Reassign order values to remaining exercises
        updatedExercises.forEach((exercise, idx) => {
          exercise.order = idx + 1; // Ensure order starts at 1
        });

        // Handle duplicateView if necessary
        if (duplicateView && weekIndex === 0) {
          draft.weeks.forEach((wk, idx) => {
            if (idx === 0) return; // Skip Week 1

            const duplicatedWeek = wk;
            const duplicatedDay = duplicatedWeek.days[dayIndex];
            if (
              duplicatedDay &&
              duplicatedDay.exercises.length > exerciseIndex
            ) {
              duplicatedDay.exercises.splice(exerciseIndex, 1);
              duplicatedDay.exercises.forEach((exercise, idx) => {
                exercise.order = idx + 1;
              });
            }
          });
        }

        // Mark the state as modified
        dispatch(setIsModified(true));
      })
    );
  };

  const handleCopyExercisesToNextDay = (weekIndex: number, dayIndex: number) => {
    setSelectedWorkoutProgram((prevProgram) => {
      if (!prevProgram) return prevProgram;

      // Make shallow copies at each level
      const updatedProgram = { ...prevProgram };
      const updatedWeeks = [...updatedProgram.weeks];
      const week = { ...updatedWeeks[weekIndex] };
      const updatedDays = [...week.days];

      // Get the exercises from the current day
      const day = { ...updatedDays[dayIndex] };
      const exercisesToCopy = [...day.exercises];

      // Make sure there is a day to copy to
      if (dayIndex + 1 < updatedDays.length) {
        const nextDay = { ...updatedDays[dayIndex + 1] };

        // Copy and overwrite exercises in the next day
        const nextDayExercises = exercisesToCopy.map((exercise, index) => ({
          ...exercise,
          order: index + 1, // Reset the order for the new day
        }));

        nextDay.exercises = nextDayExercises;
        updatedDays[dayIndex + 1] = nextDay;
        week.days = updatedDays;
        updatedWeeks[weekIndex] = week;
        updatedProgram.weeks = updatedWeeks;
      }

      // Apply the provided logic when duplicateView is enabled and weekIndex is 0
      if (duplicateView && weekIndex === 0) {
        updatedWeeks.forEach((wk, idx) => {
          if (idx === 0) return; // Skip Week 1

          const duplicatedWeek = { ...wk };
          const duplicatedDays = [...duplicatedWeek.days];

          // Check if there is a next day to copy to
          if (dayIndex + 1 < duplicatedDays.length) {
            const exercisesToCopy =
              updatedWeeks[0].days[dayIndex + 1]?.exercises || [];

            if (exercisesToCopy.length > 0) {
              // Create or overwrite the next day
              const duplicatedDay = { ...duplicatedDays[dayIndex + 1] };

              const duplicatedExercises = exercisesToCopy.map((exercise, index) => ({
                ...exercise,
                order: index + 1,
              }));

              duplicatedDay.exercises = duplicatedExercises;
              duplicatedDays[dayIndex + 1] = duplicatedDay;
              duplicatedWeek.days = duplicatedDays;
              updatedWeeks[idx] = duplicatedWeek;
            }
          }
        });
        updatedProgram.weeks = updatedWeeks;
      }

      return updatedProgram;
    });

    dispatch(setIsModified(true));
  };

  const fieldNames = [
    "order",
    "exerciseName",
    "maximumWeight",
    "maximumWeightPercentage",
    "maximumWeightIncreasePercentage",
    "sets",
    "reps",
    "intensity",
    "progression",
    "load",
    "supersetGroup",
    "rest",
    "notes",
    "readOnlyCompletedLog",
  ];

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  // Prepare max indices for navigation
  const maxIndices = useMemo(() => {
    const weeks = selectedWorkoutProgram?.weeks.length || 0;
    const daysPerWeek = selectedWorkoutProgram?.weeks.map(
      (week) => week.days.length
    ) || [0];
    const exercisesPerDay = selectedWorkoutProgram?.weeks.map((week) =>
      week.days.map((day) => day.exercises.length)
    ) || [[]];

    return {
      weeks,
      daysPerWeek,
      exercisesPerDay,
    };
  }, [selectedWorkoutProgram]);

  const StyledTableContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "10px",
  });

  const StyledHeaderRow = styled("div")({
    display: "flex",
    backgroundColor: "#e0e0e0",
    borderBottom: "2px solid #ccc",
  });

  // Update StyledHeaderCell styling
  const StyledHeaderCell = styled("div")<{
    width: number;
    isSelected?: boolean;
  }>(({ width, isSelected }) => ({
    width: `${width}%`,
    textAlign: "left",
    padding: "8px 6px", // Reduced padding
    fontWeight: 600,
    fontSize: "13px", // Reduced font size
    fontFamily: "Inter",
    color: Colors.gray[900],
    borderRight: `1px solid ${Colors.gray[200]}`,
    boxSizing: "border-box",
    height: "40px", // Reduced height
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }));

  // Update StyledRow styling
  const StyledRow = styled("div")<{ isEven: boolean }>(({ isEven }) => ({
    display: "flex",
    borderBottom: `1px solid ${Colors.gray[300]}`,
    backgroundColor: isEven ? "#ffffff" : "#f7fcff", // Alternating colors
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  }));

  // Update StyledCell styling
  const StyledCell = styled("div")<{
    width: number;
    isSelected?: boolean;
  }>(({ width, isSelected }) => ({
    width: `${width}%`,
    padding: "6px", // Reduced padding
    fontSize: "13px", // Reduced font size
    fontFamily: "Inter",
    color: Colors.gray[900],
    borderRight: `1px solid ${Colors.gray[200]}`,
    borderTop: isSelected ? "2px solid #1976d2" : "none",
    backgroundColor: isSelected ? "#d9edfc" : "transparent",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: "40px", // Reduced height
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }));

  const inputStyle: React.CSSProperties = {
    textAlign: "left",
  };

  const handleSelectedWorkoutProgramGroupItemChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setVersionNumber(Number(newValue));
    const matchingWorkoutProgram = findMatchingWorkoutProgram(
      workoutProgramGroupItems!!,
      workoutPrograms!!,
      Number(newValue)
    );

    if (matchingWorkoutProgram) {
      setSelectedWorkoutProgram(matchingWorkoutProgram);
    }
  };

  // States for Selected Cell and Editing Cell
  const [selectedCells, setSelectedCells] = useState<
    Array<{
      weekIndex: number;
      dayIndex: number;
      exerciseIndex: number;
      fieldName: string;
    }>
  >([]);

  const [currentCell, setCurrentCell] = useState<{
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
    fieldName: string;
  } | null>(null);

  const [editingCell, setEditingCell] = useState<{
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
    fieldName: string;
  } | null>(null);

  const [copiedValue, setCopiedValue] = useState<string>("");

  // Ref for the grid container to handle key events
  const gridRef = useRef<HTMLDivElement>(null);

  const handleBlurEditing = () => {
    setEditingCell(null); // Exit edit mode
    if (gridRef.current) {
      gridRef.current.focus(); // Refocus the grid container
    }
  };

  const isCellSelected = (cell: {
    weekIndex: number;
    dayIndex: number;
    exerciseIndex: number;
    fieldName: string;
  }) => {
    return selectedCells.some(
      (selectedCell) =>
        selectedCell.weekIndex === cell.weekIndex &&
        selectedCell.dayIndex === cell.dayIndex &&
        selectedCell.exerciseIndex === cell.exerciseIndex &&
        selectedCell.fieldName === cell.fieldName
    );
  };

  const isRowSelected = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number
  ) => {
    return selectedCells.some(
      (cell) =>
        cell.weekIndex === weekIndex &&
        cell.dayIndex === dayIndex &&
        cell.exerciseIndex === exerciseIndex
    );
  };

  const isColumnSelected = (
    fieldName: string,
    weekIndex: number,
    dayIndex: number
  ) => {
    const exercises =
      selectedWorkoutProgram?.weeks[weekIndex]?.days[dayIndex]?.exercises || [];
    if (exercises.length === 0) return false;
    return exercises.every((_, exerciseIndex) =>
      selectedCells.some(
        (cell) =>
          cell.weekIndex === weekIndex &&
          cell.dayIndex === dayIndex &&
          cell.exerciseIndex === exerciseIndex &&
          cell.fieldName === fieldName
      )
    );
  };

  // Handle Cell Click to set selection
  const handleCellClick = (
    weekIndex: number,
    dayIndex: number,
    exerciseIndex: number,
    fieldName: string
  ) => {
    setSelectedCells([{ weekIndex, dayIndex, exerciseIndex, fieldName }]);
    setCurrentCell({ weekIndex, dayIndex, exerciseIndex, fieldName });
    // If a cell is being edited, clicking another cell should stop editing
    if (editingCell) {
      setEditingCell(null);
    }
  };

  // Handle Header Cell Click to select entire column
  const handleHeaderCellClick = (
    fieldName: string,
    weekIndex: number,
    dayIndex: number
  ) => {
    // Use weekIndex directly since it's now 0-based
    const cells: any[] = [];
    const exercises =
      selectedWorkoutProgram?.weeks[weekIndex].days[dayIndex].exercises || [];
    exercises.forEach((_, exerciseIndex) => {
      cells.push({ weekIndex, dayIndex, exerciseIndex, fieldName });
    });
    setSelectedCells(cells);
    setCurrentCell(null);
  };

  // Handle Key Down Events for Navigation and Copy-Paste
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      // Ignore key events if an input or select is focused
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLSelectElement
      ) {
        return;
      }

      // Handle Copy (Ctrl+C or Cmd+C)
      if ((e.ctrlKey || e.metaKey) && (e.key === "c" || e.key === "C")) {
        e.preventDefault();
        if (selectedCells.length === 0) return;

        // Build the copied data
        const sortedCells = [...selectedCells].sort((a, b) => {
          // Sort by week, day, exercise, field
          if (a.weekIndex !== b.weekIndex) return a.weekIndex - b.weekIndex;
          if (a.dayIndex !== b.dayIndex) return a.dayIndex - b.dayIndex;
          if (a.exerciseIndex !== b.exerciseIndex)
            return a.exerciseIndex - b.exerciseIndex;
          return (
            fieldNames.indexOf(a.fieldName) - fieldNames.indexOf(b.fieldName)
          );
        });

        // Organize data into rows and columns
        const dataGrid: string[][] = [];
        let currentRowKey = "";
        let currentRow: string[] = [];

        sortedCells.forEach((cell) => {
          const rowKey = `${cell.weekIndex}-${cell.dayIndex}-${cell.exerciseIndex}`;
          if (rowKey !== currentRowKey) {
            if (currentRow.length > 0) {
              dataGrid.push(currentRow);
            }
            currentRow = [];
            currentRowKey = rowKey;
          }
          const exercise =
            selectedWorkoutProgram?.weeks[cell.weekIndex].days[cell.dayIndex]
              .exercises[cell.exerciseIndex];
          const value = String(
            exercise?.[cell.fieldName as keyof typeof exercise] ?? ""
          );
          currentRow.push(value);
        });

        if (currentRow.length > 0) {
          dataGrid.push(currentRow);
        }

        // Convert data grid to string
        const valueToCopy = dataGrid.map((row) => row.join("\t")).join("\n");
        setCopiedValue(valueToCopy);

        // Copy to system clipboard
        navigator.clipboard.writeText(valueToCopy);
        return;
      }

      // Handle Paste (Ctrl+V or Cmd+V)
      if ((e.ctrlKey || e.metaKey) && (e.key === "v" || e.key === "V")) {
        e.preventDefault();
        if (selectedCells.length === 0) return;

        navigator.clipboard.readText().then((clipText) => {
          if (!clipText) return;

          // Parse the clipboard data
          const rows = clipText.split("\n").map((row) => row.split("\t"));

          // Apply the values to the selected cells
          const sortedCells = [...selectedCells].sort((a, b) => {
            if (a.weekIndex !== b.weekIndex) return a.weekIndex - b.weekIndex;
            if (a.dayIndex !== b.dayIndex) return a.dayIndex - b.dayIndex;
            if (a.exerciseIndex !== b.exerciseIndex)
              return a.exerciseIndex - b.exerciseIndex;
            return (
              fieldNames.indexOf(a.fieldName) - fieldNames.indexOf(b.fieldName)
            );
          });

          let cellIndex = 0;
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            for (let j = 0; j < row.length; j++) {
              if (cellIndex >= sortedCells.length) break;
              const cell = sortedCells[cellIndex];
              const value = row[j];
              handleInputChangeValue(
                value,
                cell.weekIndex,
                cell.dayIndex,
                cell.exerciseIndex,
                cell.fieldName
              );
              cellIndex++;
            }
          }
        });
        return;
      }

      // Handle navigation
      if (!currentCell) return;
      const { weekIndex, dayIndex, exerciseIndex, fieldName } = currentCell;

      // Get visible fields based on current settings
      const visibleFields = fieldNames.filter(field => {
        if (field === "notes" && !showNotes) return false;
        if (field === "readOnlyCompletedLog" && !showLogs) return false;
        if (field === "progression" && !showRpeProgressionView) return false;
        if (field === "supersetGroup" && !showSupersets) return false;
        if ((field === "maximumWeight" || field === "maximumWeightPercentage" || field === "maximumWeightIncreasePercentage") && !show1RMProgression) return false;
        return true;
      });

      // Find the current field index in visible fields
      const currentFieldIndex = visibleFields.indexOf(fieldName);
      if (currentFieldIndex === -1) return;

      let newWeekIndex = weekIndex;
      let newDayIndex = dayIndex;
      let newExerciseIndex = exerciseIndex;
      let newFieldIndex = currentFieldIndex;

      switch (e.key) {
        case "ArrowRight":
          e.preventDefault();
          if (currentFieldIndex < visibleFields.length - 1) {
            newFieldIndex += 1;
          } else {
            // Move to next exercise
            if (
              exerciseIndex <
              (maxIndices.exercisesPerDay[weekIndex][dayIndex] || 0) - 1
            ) {
              newExerciseIndex += 1;
              newFieldIndex = 0;
            } else {
              // Move to next day
              if (dayIndex < (maxIndices.daysPerWeek[weekIndex] || 0) - 1) {
                newDayIndex += 1;
                newExerciseIndex = 0;
                newFieldIndex = 0;
              } else {
                // Move to next week
                if (weekIndex < (maxIndices.weeks || 0) - 1) {
                  newWeekIndex += 1;
                  newDayIndex = 0;
                  newExerciseIndex = 0;
                  newFieldIndex = 0;
                }
              }
            }
          }
          break;
        case "ArrowLeft":
          e.preventDefault();
          if (currentFieldIndex > 0) {
            newFieldIndex -= 1;
          } else {
            // Move to previous exercise
            if (exerciseIndex > 0) {
              newExerciseIndex -= 1;
              newFieldIndex = visibleFields.length - 1;
            } else {
              // Move to previous day
              if (dayIndex > 0) {
                newDayIndex -= 1;
                newExerciseIndex =
                  (maxIndices.exercisesPerDay[weekIndex][dayIndex - 1] || 0) - 1;
                newFieldIndex = visibleFields.length - 1;
              } else {
                // Move to previous week
                if (weekIndex > 0) {
                  newWeekIndex -= 1;
                  newDayIndex =
                    (maxIndices.daysPerWeek[weekIndex - 1] || 0) - 1;
                  newExerciseIndex =
                    (maxIndices.exercisesPerDay[weekIndex - 1][newDayIndex] ||
                      0) - 1;
                  newFieldIndex = visibleFields.length - 1;
                }
              }
            }
          }
          break;
        case "ArrowDown":
          e.preventDefault();
          if (
            exerciseIndex <
            (maxIndices.exercisesPerDay[weekIndex][dayIndex] || 0) - 1
          ) {
            newExerciseIndex += 1;
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          if (exerciseIndex > 0) {
            newExerciseIndex -= 1;
          }
          break;
        case "Tab":
          e.preventDefault();
          if (e.shiftKey) {
            // Handle Shift+Tab (similar to ArrowLeft)
            if (currentFieldIndex > 0) {
              newFieldIndex -= 1;
            } else {
              if (exerciseIndex > 0) {
                newExerciseIndex -= 1;
                newFieldIndex = visibleFields.length - 1;
              }
            }
          } else {
            // Handle Tab (similar to ArrowRight)
            if (currentFieldIndex < visibleFields.length - 1) {
              newFieldIndex += 1;
            } else {
              if (
                exerciseIndex <
                (maxIndices.exercisesPerDay[weekIndex][dayIndex] || 0) - 1
              ) {
                newExerciseIndex += 1;
                newFieldIndex = 0;
              }
            }
          }
          break;
        case "Enter":
          e.preventDefault();
          if (editingCell) {
            setEditingCell(null);
            // Ensure the cell remains selected after editing
            if (currentCell) {
              setSelectedCells([currentCell]);
            }
          } else {
            setEditingCell({ weekIndex, dayIndex, exerciseIndex, fieldName });
          }
          return;
        default:
          break;
      }

      // Validate new indices and update selection
      if (
        newWeekIndex >= 0 &&
        newWeekIndex < maxIndices.weeks &&
        newDayIndex >= 0 &&
        newDayIndex < (maxIndices.daysPerWeek[newWeekIndex] || 0) &&
        newExerciseIndex >= 0 &&
        newExerciseIndex <
        (maxIndices.exercisesPerDay[newWeekIndex][newDayIndex] || 0) &&
        newFieldIndex >= 0 &&
        newFieldIndex < visibleFields.length
      ) {
        const newFieldName = visibleFields[newFieldIndex];
        const newCell = {
          weekIndex: newWeekIndex,
          dayIndex: newDayIndex,
          exerciseIndex: newExerciseIndex,
          fieldName: newFieldName,
        };
        setSelectedCells([newCell]);
        setCurrentCell(newCell);
      }
    },
    [
      currentCell,
      editingCell,
      fieldNames,
      maxIndices,
      showNotes,
      showLogs,
      showRpeProgressionView,
      show1RMProgression,
      showSupersets,
    ]
  );

  // Attach keydown listener
  useEffect(() => {
    const gridElement = gridRef.current;
    if (!gridElement) return;

    const handleKeyDownEvent = (e: KeyboardEvent<HTMLDivElement>) => {
      handleKeyDown(e);
    };

    gridElement.addEventListener("keydown", handleKeyDownEvent as any);

    return () => {
      gridElement.removeEventListener("keydown", handleKeyDownEvent as any);
    };
  }, [handleKeyDown]);

  // Focus the selected input when editingCell changes
  useEffect(() => {
    if (!editingCell) return;
    const { weekIndex, dayIndex, exerciseIndex, fieldName } = editingCell;
    const fieldKey = getFieldKey(weekIndex, dayIndex, exerciseIndex, fieldName);
    const input = inputRefs.current[fieldKey];
    if (input) {
      input.focus();
      if (typeof input.select === "function") {
        input.select();
      }
    }
  }, [editingCell]);

  const handleSelectExercise = (exercise: any, weekIndex: number, dayIndex: number, exerciseIndex: number) => {
    setSelectedWorkoutProgram((prevProgram) =>
      produce(prevProgram, (draft) => {
        if (!prevProgram) return;
        if (!draft) return;

        // Only apply to all weeks if duplicateView is enabled AND we're editing week 1
        if (duplicateView && weekIndex === 0) {
          // Apply to all weeks
          draft.weeks.forEach((week) => {
            const targetExercise = week.days[dayIndex].exercises[exerciseIndex];
            if (targetExercise) {
              targetExercise.exerciseId = exercise.id;
              targetExercise.exerciseName = exercise.name;
              targetExercise.type = exercise.type;
              // Add any other exercise properties you want to copy
            }
          });
        } else {
          // Only update the specific week
          const targetExercise = draft.weeks[weekIndex].days[dayIndex].exercises[exerciseIndex];
          if (targetExercise) {
            targetExercise.exerciseId = exercise.id;
            targetExercise.exerciseName = exercise.name;
            targetExercise.type = exercise.type;
            // Add any other exercise properties you want to copy
          }
        }

        dispatch(setIsModified(true));
      })
    );
    setIsDrawerOpen(false);
  };

  // Add state for active week tab
  const [activeWeekTab, setActiveWeekTab] = useState<string>("1");

  const handleWeekTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveWeekTab(newValue);
  };

  // Add these styled components near your other styled components
  const StyledTabList = styled(TabList)(({ theme }) => ({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
      display: 'none', // Hide the tab indicator line
    },
    '& .MuiTabs-flexContainer': {
      gap: '8px', // Add spacing between tabs
    },
    marginTop: '16px',
    minHeight: "unset",
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: '13px',
    padding: '8px 16px',
    borderRadius: '4px',
    height: '32px',
    minHeight: '32px',
    border: `1px solid ${Colors.gray[300]}`,
    minWidth: '100px',
    fontFamily: 'Inter',
    color: Colors.gray[700],
    backgroundColor: 'white',
    '&.Mui-selected': {
      color: Colors.blue[700],
      backgroundColor: Colors.blue[200],
      '&:hover': {
        backgroundColor: Colors.blue[100],
      },
    },
    '&:hover': {
      backgroundColor: Colors.gray[100],
    },
  }));

  // Add this styled component with your other styled components
  const StyledSelect = styled(Select)(({ theme }) => ({
    height: '32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${Colors.gray[300]}`,
    '& .MuiSelect-select': {
      padding: '4px 8px',
      paddingRight: '32px !important',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      fontSize: '13px',
      fontFamily: 'Inter',
      color: Colors.gray[700],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: Colors.gray[100],
    },
    '&.Mui-focused': {
      backgroundColor: Colors.blue[200],
      color: Colors.blue[700],
      border: `1px solid ${Colors.blue[700]}`,
    },
  }));

  // Add this type definition
  interface AdditionalField {
    id: string;
    label: string;
    icon: React.ReactNode;
    tooltip: string;
  }

  // First, add this interface near your other interfaces
  interface MenuItemWithTooltip {
    value: string;
    label: string;
    icon: React.ReactNode;
    tooltip?: string;
    checked: boolean;
  }

  // Create a new component for tooltip menu items
  const TooltipMenuItem: FC<{
    item: MenuItemWithTooltip;
    onChange: (value: string) => void;  // Add onChange prop
  }> = ({ item, onChange }) => {
    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onChange(item.value);
    };

    const menuItemContent = (
      <MenuItem
        value={item.value}
        sx={{ fontSize: '13px' }}
        onClick={handleClick}
      >
        <Checkbox
          checked={item.checked}
          sx={{
            padding: '4px',
            marginRight: '8px',
            '& .MuiSvgIcon-root': { fontSize: 18 }
          }}
          onClick={(e) => e.stopPropagation()} // Prevent double-triggering
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {item.icon}
          {item.label}
        </Box>
      </MenuItem>
    );

    if (item.tooltip) {
      return (
        <Tooltip
          title={item.tooltip}
          placement="right"
          arrow
        >
          {menuItemContent}
        </Tooltip>
      );
    }

    return menuItemContent;
  };

  // First, add this helper function near the top of the file
  const isFieldVisible = (fieldName: string, showNotes: boolean, showLogs: boolean, showRpeProgressionView: boolean, showSupersets: boolean, show1RMProgression: boolean) => {
    if (fieldName === "notes" && !showNotes) return false;
    if (fieldName === "readOnlyCompletedLog" && !showLogs) return false;
    if (fieldName === "progression" && !showRpeProgressionView) return false;
    if (fieldName === "supersetGroup" && !showSupersets) return false;
    if ((fieldName === "maximumWeight" ||
      fieldName === "maximumWeightPercentage" ||
      fieldName === "maximumWeightIncreasePercentage") && !show1RMProgression) return false;
    return true;
  };

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {selectedWorkoutProgram &&
            selectedWorkoutProgram.workoutProgramType === "Template"
            ? "Template builder"
            : "Program builder"}
        </Typography>
      </Box>
      {selectedWorkoutProgram && (
        <>
          <UserInfoCard elevation={3}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              {/* Left side with user info */}
              <Box sx={{ display: 'flex' }}>
                {selectedWorkoutProgram.userId && (
                  <IdenticonAvatar
                    sizeValue={60}
                    seedValue={selectedWorkoutProgram.userId.toString()}
                    profileImageUrl={selectedWorkoutProgram.profileImageUrl || ""}
                  />
                )}
                <UserDetails spacing={0.5}>
                  {selectedWorkoutProgram.userId && (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: 600,
                          color: Colors.gray[900],
                        }}
                      >
                        {selectedWorkoutProgram.userName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Inter",
                          color: Colors.gray[600],
                        }}
                      >
                        {selectedWorkoutProgram.userEmail}
                      </Typography>
                    </>
                  )}
                  {/* Program Duration Info */}
                  <ProgramInfo>
                    <Typography variant="body2">
                      {selectedWorkoutProgram.daysPerWeek}{" "}
                      {selectedWorkoutProgram.daysPerWeek === 1 ? "day" : "days"}{" "}
                      per week for {selectedWorkoutProgram.weeks.length}{" "}
                      {selectedWorkoutProgram.weeks.length === 1 ? "week" : "weeks"}
                    </Typography>
                  </ProgramInfo>
                  {selectedWorkoutProgram.startDate && (
                    <ProgramInfo>
                      <Typography variant="body2">
                        {dayjs(selectedWorkoutProgram.startDate).format(
                          "MMM D, YYYY"
                        )}{" "}
                        -{" "}
                        {dayjs(selectedWorkoutProgram.endDate).format(
                          "MMM D, YYYY"
                        )}
                      </Typography>
                    </ProgramInfo>
                  )}
                </UserDetails>
              </Box>

              {/* Right side with Program details dropdown */}
              <StyledSelect
                multiple
                displayEmpty
                value={[
                  ...(showProgramDetails ? ['details'] : []),
                  ...(showInsights ? ['insights'] : []),
                ]}
                onChange={(event) => {
                  const values = event.target.value as string[];
                  setShowProgramDetails(values.includes('details'));
                  setShowInsights(values.includes('insights'));
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <DescriptionOutlinedIcon sx={{ fontSize: 18 }} />
                    <Box component="span">
                      Program details
                    </Box>
                  </Box>
                )}
                sx={{ minWidth: 140 }}
              >
                <MenuItem value="details" sx={{ fontSize: '13px' }}>
                  <Checkbox
                    checked={showProgramDetails}
                    sx={{
                      padding: '4px',
                      marginRight: '8px',
                      '& .MuiSvgIcon-root': { fontSize: 18 }
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DescriptionOutlinedIcon sx={{ fontSize: 18 }} />
                    Title and Notes
                  </Box>
                </MenuItem>
                {selectedWorkoutProgram?.workoutProgramType === "UserProgram" && (
                  <MenuItem value="insights" sx={{ fontSize: '13px' }}>
                    <Checkbox
                      checked={showInsights}
                      sx={{
                        padding: '4px',
                        marginRight: '8px',
                        '& .MuiSvgIcon-root': { fontSize: 18 }
                      }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <InsightsOutlinedIcon sx={{ fontSize: 18 }} />
                      Training insights
                    </Box>
                  </MenuItem>
                )}
              </StyledSelect>
            </Box>
          </UserInfoCard>
          {showProgramDetails && (
            <Stack spacing={3} sx={{ mb: 2 }}>
              <StyledTextField
                value={selectedWorkoutProgram.title}
                onChange={(e) => handleTitleChange(e.target.value)}
                variant="outlined"
                label="Title"
                fullWidth
                InputLabelProps={{
                  style: { fontWeight: 500 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: Colors.gray[300],
                    },
                    "&:hover fieldset": {
                      borderColor: Colors.blue[500],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: Colors.blue[700],
                    },
                  },
                }}
              />
              <StyledTextField
                value={selectedWorkoutProgram.notes}
                onChange={(e) => handleProgramNotesChange(e.target.value)}
                variant="outlined"
                label="Notes"
                fullWidth
                multiline
                rows={5}
                InputLabelProps={{
                  style: { fontWeight: 500 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: Colors.gray[300],
                    },
                    "&:hover fieldset": {
                      borderColor: Colors.blue[500],
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: Colors.blue[700],
                    },
                  },
                }}
              />
            </Stack>
          )}
          <>
            {showInsights && selectedWorkoutProgram.userId && (
              <Grid container spacing={2}>
                <ClientInsights
                  selectedUserId={selectedWorkoutProgram.userId}
                  showTitle
                  showFatigue
                  showRating
                  showVolume
                />
                <Grid item xs={12} md={12}>
                  <TrainingInsightsContent
                    preselectedUserId={selectedWorkoutProgram.userId}
                  />
                </Grid>
              </Grid>
            )}
            <Box sx={{ display: "flex", gap: 0.5, pb: 1.5, justifyContent: "flex-start", flexWrap: "wrap" }}>

              <TooltipToggleButton
                value="duplicateWeeks"
                selected={duplicateView}
                onChange={() => setDuplicateView(!duplicateView)}
                size="small"
                title="When enabled, this will duplicate the changes made on week 1 to other weeks."
              >
                <ContentCopyOutlinedIcon />
                <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                  Copy to all weeks
                </Box>
              </TooltipToggleButton>
              <StyledSelect
                multiple
                displayEmpty
                value={[
                  ...(showRpeProgressionView ? ['progression'] : []),
                  ...(showSupersets ? ['supersets'] : []),
                  ...(show1RMProgression ? ['show1RMProgression'] : []),
                  ...(showNotes ? ['notes'] : []),
                  ...(showLogs ? ['logs'] : []),
                ]}
                onChange={(event) => {
                  const values = event.target.value as string[];
                  setShowRpeProgressionView(values.includes('progression'));
                  setShowSupersets(values.includes('supersets'));
                  setShowNotes(values.includes('notes'));
                  setShowLogs(values.includes('logs'));
                  setShow1RMProgression(values.includes('show1RMProgression'));
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <SettingsOutlinedIcon sx={{ fontSize: 18 }} />
                    <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                      Additional fields
                    </Box>
                  </Box>
                )}
                sx={{ minWidth: 100 }}
              >
                <TooltipMenuItem
                  item={{
                    value: "show1RMProgression",
                    label: "1RM Progression",
                    icon: <ShowChartOutlinedIcon sx={{ fontSize: 18 }} />,
                    checked: show1RMProgression,
                    tooltip: "When enabled, you can define 1RM progression. Add a value to 1RM along with 1RM% (out of 100) to calculate the load for each week. Adding an increase % will increase the load by the percentage each week."
                  }}
                  onChange={(value) => setShow1RMProgression(!show1RMProgression)}
                />
                <TooltipMenuItem
                  item={{
                    value: "progression",
                    label: "RPE Progression",
                    icon: <ShowChartOutlinedIcon sx={{ fontSize: 18 }} />,
                    checked: showRpeProgressionView,
                    tooltip: "When enabled, you can define RPE progression to incrementally increase the intensity of exercises based on specific parameters.\n\nExamples:\n- 'rpe 1 e/w': Increase RPE by 1 every week.\n- 'rpe 0.5 e/2w': Increase RPE by 0.5 every 2 weeks."
                  }}
                  onChange={(value) => setShowRpeProgressionView(!showRpeProgressionView)}
                />
                <TooltipMenuItem
                  item={{
                    value: "supersets",
                    label: "Supersets",
                    icon: <MergeTypeOutlined sx={{ fontSize: 18 }} />,
                    checked: showSupersets,
                    tooltip: "When enabled, exercises with the same superset value will be grouped as a superset."
                  }}
                  onChange={(value) => setShowSupersets(!showSupersets)}
                />
                <TooltipMenuItem
                  item={{
                    value: "notes",
                    label: "Notes",
                    icon: <NotesOutlinedIcon sx={{ fontSize: 18 }} />,
                    checked: showNotes,
                    tooltip: "When enabled, notes for each exercise will be displayed."
                  }}
                  onChange={(value) => setShowNotes(!showNotes)}
                />
                {selectedWorkoutProgram?.workoutProgramType === "UserProgram" && (
                  <TooltipMenuItem
                    item={{
                      value: "logs",
                      label: "Completed logs",
                      icon: <AssignmentOutlinedIcon sx={{ fontSize: 18 }} />,
                      checked: showLogs,
                      tooltip: "When enabled, completed logs for each exercise will be displayed."
                    }}
                    onChange={(value) => setShowLogs(!showLogs)}
                  />
                )}
              </StyledSelect>
            </Box>

            <Box
              ref={gridRef}
              tabIndex={0} // Add this to make the div focusable
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "900px",
                background: "#fff",
                mb: 4,
                outline: 'none', // Remove the focus outline if you want
              }}
            >
              <TabContext value={versionNumber.toString()}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleSelectedWorkoutProgramGroupItemChange}
                    aria-label="Tabs"
                  >
                    {workoutProgramGroupItems?.map((workoutProgramGroupItem) => (
                      <Tab
                        key={workoutProgramGroupItem.id}
                        label={`Version ${workoutProgramGroupItem.versionNumber}`}
                        value={workoutProgramGroupItem.versionNumber.toString()}
                        sx={{
                          textTransform: "none",
                        }}
                      />
                    ))}
                  </TabList>
                </Box>
              </TabContext>

              {/* Add week tabs - only show if not in simplified view */}
              <TabContext value={activeWeekTab}>
                <Box sx={{
                  borderBottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  {/* Left side: Tabs or message */}
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1  // Take up available space
                  }}>
                    <StyledTabList
                      onChange={handleWeekTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="Week tabs"
                      sx={{
                        maxWidth: '70%',
                        flexGrow: 1,
                        ml: 2,
                      }}
                    >
                      {selectedWorkoutProgram?.weeks?.map((week, index) => (
                        <StyledTab
                          key={week.weekNumber}
                          label={`Week ${index + 1}`}
                          value={(index + 1).toString()}
                        />
                      ))}
                    </StyledTabList>
                  </Box>

                  {/* Right side: Action Buttons */}
                  <Box sx={{
                    display: "flex",
                    gap: 1,
                    mt: 2,
                    mr: 2,
                    flexShrink: 0 // Prevent buttons from shrinking
                  }}>
                    {selectedWorkoutProgram?.workoutProgramType === "Template" && (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "500",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                        onClick={handleUpdateDraftWorkoutProgram}
                        loading={savingProgram}
                        disabled={savingProgram || isLoadingCheckStripeIntegration}
                      >
                        Save template
                      </LoadingButton>
                    )}

                    {selectedWorkoutProgram?.workoutProgramType === "UserProgram" && (
                      <>
                        {selectedWorkoutProgram.status !== "Draft" ? (
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            sx={{
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "14px",
                              textTransform: "none",
                            }}
                            onClick={() => setOpenSaveVersionModal(true)}
                            loading={savingProgram}
                            disabled={savingProgram || isLoadingCheckStripeIntegration}
                          >
                            Create new version
                          </LoadingButton>
                        ) : (
                          <>
                            <LoadingButton
                              variant="outlined"
                              color="primary"
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: "500",
                                fontSize: "14px",
                                textTransform: "none",
                              }}
                              onClick={handleUpdateDraftWorkoutProgram}
                              loading={savingProgram}
                              disabled={savingProgram || isLoadingCheckStripeIntegration}
                            >
                              Save as draft
                            </LoadingButton>
                            <LoadingButton
                              variant="contained"
                              color="primary"
                              sx={{
                                fontFamily: "Inter",
                                fontWeight: "500",
                                fontSize: "14px",
                                textTransform: "none",
                              }}
                              onClick={() =>
                                hasStripeIntegration
                                  ? setOpenCompleteAndShareModal(true)
                                  : handleCompleteWorkoutProgramAndShare()
                              }
                              loading={savingProgram}
                              disabled={savingProgram || isLoadingCheckStripeIntegration}
                            >
                              Complete and share
                            </LoadingButton>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>

                {/* TabPanels */}
                {selectedWorkoutProgram?.weeks?.map((week, weekIndex) => (
                  <TabPanel
                    key={week.weekNumber}
                    value={(weekIndex + 1).toString()}
                    sx={{
                      p: 0,
                      height: '100%',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                        '&:hover': {
                          background: '#555',
                        },
                      },
                    }}
                  >
                    {(successMessage || errorMessage) && (
                      <Box sx={{ mb: 1 }}>
                        {successMessage && (
                          <Typography
                            sx={{
                              color: "green",
                              fontSize: "14px",
                              lineHeight: "24px",
                              letterSpacing: "0.4px",
                              textTransform: "none",
                              textDecoration: "none",
                              ml: "16px",
                            }}
                          >
                            {successMessage}
                          </Typography>
                        )}
                        {errorMessage && (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "14px",
                              lineHeight: "24px",
                              letterSpacing: "0.4px",
                              textTransform: "none",
                              textDecoration: "none",
                              ml: "16px",
                            }}
                          >
                            {errorMessage}
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Box>
                      {week.days.map((day, dayIndex) => (
                        <MealContainer key={day.dayNumber}>
                          <MealHeader>
                            <Typography variant="h6" sx={{ flex: 1 }}>
                              Day {day.dayNumber}
                            </Typography>
                          </MealHeader>

                          <StyledTableContainer>
                            <StyledHeaderRow>
                              {fieldNames.map((fieldName) => {
                                if (!isFieldVisible(fieldName, showNotes, showLogs, showRpeProgressionView, showSupersets, show1RMProgression)) {
                                  return null;
                                }

                                const width = getColumnWidths(showNotes, showLogs, showRpeProgressionView, showSupersets, show1RMProgression)[fieldName] || 100;
                                let headerLabel = fieldName;
                                if (fieldName === "order")
                                  headerLabel = "No.";
                                else if (fieldName === "exerciseName")
                                  headerLabel = "Exercise";
                                else if (fieldName === "sets")
                                  headerLabel = "Sets";
                                else if (fieldName === "reps")
                                  headerLabel = "Reps";
                                else if (fieldName === "load")
                                  headerLabel = "Weight";
                                else if (fieldName === "supersetGroup")
                                  headerLabel = "Supersets";
                                else if (fieldName === "intensity")
                                  headerLabel = "Intensity";
                                else if (fieldName === "progression")
                                  headerLabel = "RPE Progression";
                                else if (fieldName === "rest")
                                  headerLabel = "Rest";
                                else if (fieldName === "notes")
                                  headerLabel = "Notes";
                                else if (fieldName === "readOnlyCompletedLog")
                                  headerLabel = "Logs";
                                else if (fieldName === "maximumWeight")
                                  headerLabel = "1RM";
                                else if (fieldName === "maximumWeightPercentage")
                                  headerLabel = "%";
                                else if (fieldName === "maximumWeightIncreasePercentage")
                                  headerLabel = "Increase %";

                                return (
                                  <StyledHeaderCell
                                    key={fieldName}
                                    width={width}
                                    onClick={() =>
                                      handleHeaderCellClick(
                                        fieldName,
                                        weekIndex,
                                        dayIndex
                                      )
                                    }
                                    isSelected={isColumnSelected(
                                      fieldName,
                                      weekIndex,
                                      dayIndex
                                    )}
                                  >
                                    {headerLabel}
                                  </StyledHeaderCell>
                                );
                              })}
                            </StyledHeaderRow>
                            {day.exercises.map((exercise, exerciseIndex) => (
                              <StyledRow
                                key={`week-${weekIndex}_day-${dayIndex}_exercise-${exerciseIndex}`}
                                isEven={exerciseIndex % 2 === 0}
                              >
                                {fieldNames.map((fieldName) => {
                                  if (!isFieldVisible(fieldName, showNotes, showLogs, showRpeProgressionView, showSupersets, show1RMProgression)) {
                                    return null;
                                  }

                                  const fieldValue =
                                    exercise[
                                    fieldName as keyof typeof exercise
                                    ];
                                  const fieldKey = getFieldKey(
                                    weekIndex,
                                    dayIndex,
                                    exerciseIndex,
                                    fieldName
                                  );

                                  // Determine if this cell is selected or editing
                                  const isSelectedCell = isCellSelected({
                                    weekIndex: weekIndex,
                                    dayIndex,
                                    exerciseIndex,
                                    fieldName,
                                  });

                                  const isEditingCell =
                                    editingCell &&
                                    editingCell.weekIndex ===
                                    weekIndex &&
                                    editingCell.dayIndex === dayIndex &&
                                    editingCell.exerciseIndex ===
                                    exerciseIndex &&
                                    editingCell.fieldName === fieldName;

                                  const width = getColumnWidths(showNotes, showLogs, showRpeProgressionView, showSupersets, show1RMProgression)[fieldName] || 100;

                                  return (
                                    <StyledCell
                                      key={fieldName}
                                      width={width}
                                      isSelected={
                                        !!(isSelectedCell || isEditingCell)
                                      }
                                    >
                                      {fieldName === "exerciseName" ? (
                                        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                          <Autocomplete
                                            freeSolo
                                            sx={{
                                              width: "100%",
                                              mt: "-10px",
                                              "& .MuiAutocomplete-clearIndicator": {
                                                display: "none"  // Hide the clear button
                                              },
                                              "& .MuiInputBase-input": {
                                                fontSize: "13px",  // Make input text smaller
                                                padding: "4px 0",  // Reduce padding to match other inputs
                                              },
                                              "& .MuiInput-root": {
                                                fontSize: "13px"  // Ensure consistent font size
                                              }
                                            }}
                                            options={[]}
                                            value={exercise.exerciseName || ""}
                                            onChange={(_e, selectedExercise) => {
                                              if (typeof selectedExercise === "string") {
                                                handleInputChangeValue(
                                                  selectedExercise,
                                                  weekIndex,
                                                  dayIndex,
                                                  exerciseIndex,
                                                  "exerciseName"
                                                );
                                                handleInputChangeValue(
                                                  "0",
                                                  weekIndex,
                                                  dayIndex,
                                                  exerciseIndex,
                                                  "exerciseId"
                                                );
                                              }
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault();
                                                setIsDrawerOpen(true);
                                                setSelectedExerciseIndices({
                                                  weekIndex: weekIndex,
                                                  dayIndex,
                                                  exerciseIndex,
                                                });
                                                setInitialSearchTerm((e.target as HTMLInputElement).value || exercise.exerciseName || "");
                                              }
                                            }}
                                            onMouseLeave={(e) => {
                                              e.stopPropagation();
                                              handleInputChangeValue(
                                                (e.target as HTMLInputElement).value || exercise.exerciseName || "",
                                                weekIndex,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseName"
                                              );
                                            }}
                                            onBlur={(e) => {
                                              e.stopPropagation();
                                              handleInputChangeValue(
                                                (e.target as HTMLInputElement).value || exercise.exerciseName || "",
                                                weekIndex,
                                                dayIndex,
                                                exerciseIndex,
                                                "exerciseName"
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Enter exercise name"
                                                InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                    <Tooltip title="Search exercises">
                                                      <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          setIsDrawerOpen(true);
                                                          setSelectedExerciseIndices({
                                                            weekIndex: weekIndex,
                                                            dayIndex,
                                                            exerciseIndex,
                                                          });
                                                          setInitialSearchTerm(exercise.exerciseName || "");
                                                        }}
                                                        sx={{
                                                          ml: 1,
                                                          color: exercise.exerciseName ? Colors.blue[500] : Colors.gray[400],
                                                          "&:hover": {
                                                            color: Colors.blue[500],
                                                          },
                                                        }}
                                                      >
                                                        <SearchIcon
                                                          fontSize="small"
                                                          sx={{
                                                            transform: exercise.exerciseName ? "scale(1.1)" : "scale(1)",
                                                            transition: "transform 0.2s ease-in-out",
                                                          }}
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                  ),
                                                }}
                                              />
                                            )}
                                            disableClearable
                                          />
                                        </Box>
                                      ) : (
                                        <InputCell
                                          initialValue={String(
                                            fieldValue || ""
                                          )}
                                          onValueChange={(newValue) =>
                                            handleInputChangeValue(
                                              newValue,
                                              weekIndex,
                                              dayIndex,
                                              exerciseIndex,
                                              fieldName
                                            )
                                          }
                                          inputRef={(el) => {
                                            inputRefs.current[fieldKey] = el;
                                          }}
                                          style={{
                                            ...inputStyle,
                                          }}
                                          fieldNames={fieldNames}
                                          weekIndex={weekIndex}
                                          dayIndex={dayIndex}
                                          exerciseIndex={exerciseIndex}
                                          fieldName={fieldName}
                                          inputRefs={inputRefs}
                                          maxIndices={maxIndices}
                                          isSelected={
                                            !!(isSelectedCell || isEditingCell)
                                          }
                                          isEditing={!!isEditingCell}
                                          onClick={() =>
                                            handleCellClick(
                                              weekIndex,
                                              dayIndex,
                                              exerciseIndex,
                                              fieldName
                                            )
                                          }
                                          onBlurEditing={handleBlurEditing}
                                          onEnterEditMode={() =>
                                            setEditingCell({
                                              weekIndex: weekIndex,
                                              dayIndex,
                                              exerciseIndex,
                                              fieldName,
                                            })
                                          }
                                        />
                                      )}
                                    </StyledCell>
                                  );
                                })}
                                <StyledCell width={getColumnWidths(showNotes, showLogs, showRpeProgressionView, showSupersets, show1RMProgression)['actions']}>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteExercise(
                                        weekIndex,
                                        dayIndex,
                                        exerciseIndex
                                      )
                                    }
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </StyledCell>
                              </StyledRow>
                            ))}
                          </StyledTableContainer>

                          <Box sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => handleAddExercise(weekIndex, dayIndex)}
                              sx={{ textTransform: "none" }}
                              startIcon={<AddIcon />}
                            >
                              Add new exercise
                            </Button>
                            {dayIndex !== selectedWorkoutProgram?.weeks[weekIndex].days.length - 1 && (
                              <Button
                                variant="text"
                                color="info"
                                size="small"
                                sx={{
                                  textTransform: "none",
                                }}
                                onClick={() =>
                                  handleCopyExercisesToNextDay(
                                    weekIndex,
                                    dayIndex
                                  )
                                }
                                startIcon={
                                  <Icon className={`material-icons-outlined`}>
                                    content_copy
                                  </Icon>
                                }
                              >
                                Copy all to day {dayIndex + 2}
                              </Button>
                            )}
                          </Box>
                        </MealContainer>
                      ))}
                    </Box>
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </>
        </>
      )}
      <SaveNewVersionModal
        isOpenModal={openSaveVersionModal}
        handleCloseModal={() => setOpenSaveVersionModal(!openSaveVersionModal)}
        onSubmit={handleCreateNewVersion}
        isTemplate={selectedWorkoutProgram?.workoutProgramType === "Template"}
      />
      <SavingProgramModal
        isOpenModal={savingProgram}
        handleCloseModal={() => setSavingProgram(false)}
      />
      <CompleteAndShareModal
        isOpenModal={openCompleteAndShareModal}
        handleCloseModal={() =>
          setOpenCompleteAndShareModal(!openCompleteAndShareModal)
        }
        onSubmit={() => {
          handleCompleteWorkoutProgramAndShare();
        }}
        invoiceRequested={invoiceRequested}
        setInvoiceRequested={setInvoiceRequested}
        invoiceDueDate={invoiceDueDate}
        handleInvoiceRequestedDateChange={handleInvoiceRequestedDateChange}
      />
      <ModernProgramBuilderSettingsSidebar
        isDrawerOpen={isDrawerOpen}
        handleCloseDrawer={() => {
          setIsDrawerOpen(false);
          setSelectedExerciseIndices(null);
          setInitialSearchTerm("");
        }}
        onSelectExercise={handleSelectExercise}
        weekIndex={selectedExerciseIndices?.weekIndex ?? 0}
        dayIndex={selectedExerciseIndices?.dayIndex ?? 0}
        exerciseIndex={selectedExerciseIndices?.exerciseIndex ?? 0}
        initialSearchTerm={initialSearchTerm}
      />
    </Box>
  );
};

export { ModernProgramBuilder };
