import { Box } from "@mui/system";
import { FC } from "react";
import { WorkoutPrograms } from "./ui/WorkoutPrograms";

const AthletePrograms: FC = () => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "90vh",
      }}
    >
      <WorkoutPrograms />
    </Box>
  );
};

export { AthletePrograms };
