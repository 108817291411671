import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ActivityCard } from "./ActivityCard";
import { determinePerformanceLevel, getGradientForScore } from "./formatters";
import { ReadinessCard } from "./ReadinessCard";

interface ReadinessDetailsCardProps {
  readinessInsights: {
    overallScore: number;
    overallState: string;
    averageRestingHeartRate?: number;
    heartRateVariability: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    sleepDebt: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    sleepDuration: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    mentalRecovery: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
    physicalRecovery: {
      value: number | null;
      goal: number | null;
      unit: string | null;
      score: number | null;
      state: string | null;
    } | null;
  };
}

export const ReadinessDetailsCard: React.FC<ReadinessDetailsCardProps> = ({
  readinessInsights,
}) => {
  const overallScorePerformance = determinePerformanceLevel(
    readinessInsights?.overallScore ?? 0,
    1
  );

  const heartRateVariabilityPerformance = determinePerformanceLevel(
    readinessInsights.heartRateVariability?.value ?? 0,
    readinessInsights.heartRateVariability?.goal ?? 0
  );

  const mentalRecoveryPerformance = determinePerformanceLevel(
    readinessInsights.mentalRecovery?.value ?? 0,
    readinessInsights.mentalRecovery?.goal ?? 0
  );

  const physicalRecoveryPerformance = determinePerformanceLevel(
    readinessInsights.physicalRecovery?.value ?? 0,
    readinessInsights.physicalRecovery?.goal ?? 0
  );

  return (
    <Box sx={{ borderRadius: 2 }}>
      <Box sx={{ padding: "16px 24px" }}>
        <Typography variant="subtitle2" sx={{ color: "text.secondary", mt: 1 }}>
          Here&apos;s your daily readiness summary:
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ padding: "0 24px" }}>
        <Grid item xs={6}>
          <ActivityCard
            title="Overall Readiness Score"
            value={readinessInsights.overallScore}
            target={1}
            unit=""
            gradient={getGradientForScore(overallScorePerformance)}
            overrideText={readinessInsights.overallState}
          />
        </Grid>
        <Grid item xs={6}>
          <ReadinessCard
            averageRestingHeartRate={
              readinessInsights.averageRestingHeartRate ?? 0
            }
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="HR Variability"
            value={readinessInsights.heartRateVariability?.value ?? 0}
            target={readinessInsights.heartRateVariability?.goal ?? 0}
            gradient={getGradientForScore(heartRateVariabilityPerformance)}
            overrideText={readinessInsights.heartRateVariability?.state ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Sleep Debt"
            value={readinessInsights.sleepDebt?.value ?? 0}
            target={readinessInsights.sleepDebt?.goal ?? 0}
            unit={readinessInsights.sleepDebt?.unit ?? ""}
            gradient={getGradientForScore("poor")}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Mental Recovery"
            value={readinessInsights.mentalRecovery?.value ?? 0}
            target={readinessInsights.mentalRecovery?.goal ?? 0}
            unit={readinessInsights.mentalRecovery?.unit ?? ""}
            gradient={getGradientForScore(mentalRecoveryPerformance)}
          />
        </Grid>
        <Grid item xs={6}>
          <ActivityCard
            title="Physical Recovery"
            value={readinessInsights.physicalRecovery?.value ?? 0}
            target={readinessInsights.physicalRecovery?.goal ?? 0}
            unit={readinessInsights.physicalRecovery?.unit ?? ""}
            gradient={getGradientForScore(physicalRecoveryPerformance)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
