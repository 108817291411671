/* eslint-disable jsx-a11y/media-has-caption */
import { IconButton, Box, Typography, Button } from "@mui/material";
import { RealtimeTranscriber } from "assemblyai";
import { BaseOption, SelectInputField } from "components/Form/SelectInputField";
import { useState, useRef, FC, useEffect } from "react";
import RecordRTC from "recordrtc";
import { FiberManualRecord, Stop } from "@mui/icons-material";
import { Colors } from "shared/themes";
import AudioIndicator from "./AudioIndicator";

type AudioRecorderProps = {
  shortLivedToken: string;
  handleCreateWithLiveRecordedAudio: (transcription: string) => void;
  onRecordingStateChange?: (isRecording: boolean) => void;
};

const AudioRecorder: FC<AudioRecorderProps> = ({
  handleCreateWithLiveRecordedAudio,
  shortLivedToken,
  onRecordingStateChange,
}) => {
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
  const [hasMicPermission, setHasMicPermission] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recorder, setRecorder] = useState<RecordRTC | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioInputDevices, setAudioInputDevices] = useState<BaseOption[]>();
  const [selectedInputDevice, setSelectedInputDevice] = useState<string>();
  const [loadingRecordingModules, setLoadingRecordingModules] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const realtimeTranscriber = useRef<RealtimeTranscriber | null>(null);

  const [partialTranscript, setPartialTranscript] = useState<string>("");

  // Request Wake Lock
  const requestWakeLock = async () => {
    try {
      if ("wakeLock" in navigator) {
        const wakeLockSentinel = await navigator.wakeLock.request("screen");
        setWakeLock(wakeLockSentinel);
        wakeLockSentinel.addEventListener("release", () => {
          console.log("Screen Wake Lock released:", wakeLockSentinel.released);
        });
        console.log("Screen Wake Lock acquired:", wakeLockSentinel.released);
      } else {
        console.warn("Screen Wake Lock API not supported in this browser.");
      }
    } catch (err: any) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  // Release Wake Lock
  const releaseWakeLock = async () => {
    try {
      if (wakeLock !== null) {
        await wakeLock.release();
        setWakeLock(null);
        console.log("Screen Wake Lock released.");
      }
    } catch (err: any) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  // Request Microphone Permission
  const requestMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setHasMicPermission(true);
      setStream(stream);
    } catch (err) {
      console.error("Microphone permission denied", err);
      setHasMicPermission(false);
    }
  };

  // Check Microphone Permission on Mount
  useEffect(() => {
    const checkMicPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });
        if (permissionStatus.state === "granted") {
          setHasMicPermission(true);
        } else if (permissionStatus.state === "denied") {
          setHasMicPermission(false);
        }
      } catch (err) {
        console.error("Error checking microphone permission", err);
      }
    };

    checkMicPermission();
  }, []);

  // Enumerate Audio Input Devices
  useEffect(() => {
    if (hasMicPermission) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const audioInputDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        if (audioInputDevices.length === 0) {
          setHasMicPermission(false);
        }

        const audioInputDevicesNoDefault = audioInputDevices.filter(
          (device) => device.deviceId !== "default"
        );

        setAudioInputDevices(
          audioInputDevicesNoDefault?.map((device) => ({
            label: device.label,
            value: device.deviceId,
          })) ?? []
        );
      });
    }
  }, [hasMicPermission]);

  // Update Stream when Selected Input Device Changes
  useEffect(() => {
    if (selectedInputDevice) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedInputDevice
              ? { exact: selectedInputDevice }
              : undefined,
          },
        })
        .then((stream) => {
          setStream(stream);
        });
    }
  }, [selectedInputDevice]);

  // Timer Functions
  const startTimer = () => {
    setElapsedTime(0);
    timerRef.current = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    setElapsedTime(0);
  };

  // Start Transcription
  const startTranscription = async () => {
    if (!hasMicPermission || !stream) {
      return;
    }
    if (realtimeTranscriber.current) return;

    setLoadingRecordingModules(true);
    await requestWakeLock();

    realtimeTranscriber.current = new RealtimeTranscriber({
      token: shortLivedToken,
      sampleRate: 16_000,
    });

    realtimeTranscriber.current.on("transcript", (transcriptEvent) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { text, message_type } = transcriptEvent;
      if (message_type === "PartialTranscript") {
        setPartialTranscript(text);
      } else if (message_type === "FinalTranscript") {
        const cleanText = text.replace(/\n/g, " ").replace(/\s+/g, " ");
        handleCreateWithLiveRecordedAudio(cleanText);
        setPartialTranscript(""); // Clear partial transcript after final
      }
    });

    realtimeTranscriber.current.on("error", (event) => {
      console.error(event);
      realtimeTranscriber.current?.close();
      realtimeTranscriber.current = null;
    });

    realtimeTranscriber.current.on("close", (code, reason) => {
      console.log(`Connection closed: ${code} ${reason}`);
      realtimeTranscriber.current = null;
    });

    await realtimeTranscriber.current.connect();

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: selectedInputDevice
            ? { exact: selectedInputDevice }
            : undefined,
        },
      })
      .then((stream) => {
        setStream(stream);
        setLoadingRecordingModules(false);
        const recordRTC = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/webm;codecs=pcm",
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 1000,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 64000,
          ondataavailable: async (blob: Blob) => {
            if (!realtimeTranscriber.current) return;
            const buffer = await blob.arrayBuffer();
            realtimeTranscriber.current.sendAudio(buffer);
          },
        });
        setRecorder(recordRTC);
        recordRTC.startRecording();
      })
      .catch(async (err) => {
        console.error(err);
        await releaseWakeLock();
      });

    setIsRecording(true);
    onRecordingStateChange?.(true);
    startTimer();
  };

  // End Transcription
  const endTranscription = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsRecording(false);
    onRecordingStateChange?.(false);
    stopTimer();
    await releaseWakeLock();

    try {
      if (realtimeTranscriber.current) {
        await realtimeTranscriber.current.close();
        realtimeTranscriber.current = null;
      }

      if (recorder) {
        recorder.stopRecording();
      }
    } catch (error) {
      console.error("Error during transcription end:", error);
    } finally {
      setRecorder(null);
    }
  };

  // Handle Wake Lock on Visibility Change
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (wakeLock !== null && document.visibilityState === "visible") {
        await requestWakeLock();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [wakeLock]);

  // Handle Selected Input Device Change
  const handleSelectInputDevice = (e: any) => {
    setSelectedInputDevice(e.target.value as string);
  };

  const displayAudioIndicator = stream && selectedInputDevice;
  const isLoadingRecording = !isRecording && loadingRecordingModules;

  // Clean up Timer on Unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  // Format Elapsed Time
  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!hasMicPermission ? (
        <Button
          size="large"
          type="button"
          variant="outlined"
          fullWidth
          onClick={requestMicPermission}
          sx={{
            textTransform: "none",
            fontWeight: "600",
            mt: 1,
            mb: 1,
          }}
        >
          Enable Microphone
        </Button>
      ) : (
        <>
          {/* Controls Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
              width: "100%",
            }}
          >
            {/* Record Button and Timer */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: { xs: "100%", md: "auto" },
                order: { xs: 2, md: 1 },
              }}
            >
              {/* Show button on mobile, icon on desktop */}
              {isRecording ? (
                <Button
                  onClick={endTranscription}
                  disabled={loadingRecordingModules}
                  color="error"
                  variant="contained"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    textTransform: "none",
                    flexGrow: 1,
                  }}
                  startIcon={<Stop />}
                >
                  Stop recording
                </Button>
              ) : (
                <Button
                  onClick={startTranscription}
                  disabled={loadingRecordingModules || !selectedInputDevice}
                  color="primary"
                  variant="contained"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    textTransform: "none",
                    flexGrow: 1,
                  }}
                  startIcon={<FiberManualRecord />}
                >
                  Start recording
                </Button>
              )}

              {/* Show icon on desktop */}
              <IconButton
                onClick={isRecording ? endTranscription : startTranscription}
                disabled={loadingRecordingModules || !selectedInputDevice}
                color={isRecording ? "error" : "primary"}
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: 40,
                  height: 40,
                  flexShrink: 0,
                  "&.Mui-disabled": {
                    opacity: 0.5,
                  },
                }}
              >
                {isRecording ? <Stop /> : <FiberManualRecord />}
              </IconButton>

              {isRecording && (
                <Typography
                  variant="body2"
                  sx={{
                    color: Colors.red[500],
                    minWidth: "45px",
                    textAlign: "right",
                    flexShrink: 0,
                    fontSize: { xs: "12px", md: "14px" },
                  }}
                >
                  {formatTime(elapsedTime)}
                </Typography>
              )}
            </Box>

            {/* Microphone Selector and Audio Indicator */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "100%",
                order: { xs: 1, md: 2 },
              }}
            >
              <SelectInputField
                data={audioInputDevices ?? []}
                value={selectedInputDevice}
                onChange={handleSelectInputDevice}
                disabled={isRecording || loadingRecordingModules}
                placeholder="Select a microphone"
                sx={{
                  flexGrow: 1,
                  "& .MuiSelect-select": {
                    fontSize: { xs: "13px", md: "14px" },
                    py: { xs: 1, md: 1.5 },
                  },
                  width: "100%",
                }}
              />

              <AudioIndicator
                stream={stream}
                isLoadingRecording={isLoadingRecording}
              />
            </Box>
          </Box>

          {/* Preview Section */}
          {isRecording && (
            <Box
              sx={{
                width: "100%",
                mt: { xs: 1, md: 2 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  fontSize: { xs: "12px", md: "14px" },
                }}
              >
                Preview
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  p: { xs: 0.75, md: 1 },
                  borderRadius: 1,
                  border: `1px solid ${Colors.gray[300]}`,
                  backgroundColor: Colors.gray[50],
                  height: { xs: "48px", md: "56px" },
                  display: "flex",
                  alignItems: "flex-start",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: { xs: "4px", md: "8px" },
                    height: { xs: "4px", md: "8px" },
                  },
                  "&::-webkit-scrollbar-track": {
                    background: Colors.gray[100],
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: Colors.gray[400],
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: Colors.gray[500],
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: Colors.gray[1200],
                    fontStyle: "italic",
                    animation: partialTranscript
                      ? "none"
                      : "pulse 1.5s infinite ease-in-out",
                    lineHeight: 1.5,
                    fontSize: { xs: "12px", md: "14px" },
                  }}
                >
                  {partialTranscript || "Listening..."}
                </Typography>
              </Box>
            </Box>
          )}

          <Typography
            variant="body2"
            sx={{
              color: Colors.gray[1200],
              mt: 1,
              fontSize: { xs: "10px", md: "12px" },
              fontStyle: "italic",
            }}
          >
            Note: It may take 10-15 seconds for your processed audio to appear
            after you speak. Transcription updates will be displayed during
            pauses in your conversation.
          </Typography>

          <style>
            {`
              @keyframes pulse {
                0% { opacity: 1; }
                50% { opacity: 0.5; }
                100% { opacity: 1; }
              }
            `}
          </style>
        </>
      )}
    </Box>
  );
};

export default AudioRecorder;
