/* eslint-disable no-plusplus */
/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import {
  WorkoutProgramItem,
  ClientResponse,
  Lead,
  ClientInvoice,
} from "../api";

export type AnyType = any;

export const noop = () => {};

export const isDev = process.env.NODE_ENV === "development";

export const withDelay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const uuid = () => {
  const chars = "0123456789abcdef".split("");

  const uuids = [];
  const rnd = Math.random;

  let r;

  uuids[8] = uuids[13] = uuids[18] = uuids[23] = "-";
  uuids[14] = "4";

  for (let i = 0; i < 36; i++) {
    if (!uuids[i]) {
      r = 0 | (rnd() * 16);

      uuids[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuids.join("");
};

export const createCurrentUserRows = (array: WorkoutProgramItem[]) => {
  const rows = array?.map((item) => ({
    id: item.id,
    nameOfProgram: item.name,
    startDate: new Date(item.startDate).toLocaleDateString("en-Au", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    endDate: new Date(item.endDate).toLocaleDateString("en-Au", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    status: item.workoutProgramStatus,
    userId: item.userId,
    userFullName: item.userFullName,
    profileImageUrl: item.profileImageUrl,
    trainingSplit: `${item.daysPerWeek} days x ${item.weeksInProgram} weeks`,
  }));
  return rows;
};

export const createAllUsersRows = (array: WorkoutProgramItem[]) => {
  const rows = array?.map((item) => ({
    id: item.id,
    nameOfProgram: item.name,
    date: `${new Date(item.startDate).toLocaleDateString("en-Au", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })} - ${new Date(item.endDate).toLocaleDateString("en-Au", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })}`,
    createdDate: new Date(item.createdDate).toLocaleDateString("en-Au", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    duration: `${item.daysPerWeek} days x ${item.weeksInProgram} weeks`,
    workoutProgramStatus: item.workoutProgramStatus,
    userId: item.userId,
    userFullName: item.userFullName,
    profileImageUrl: item.profileImageUrl,
    trainingSplit: `${item.daysPerWeek} days x ${item.weeksInProgram} weeks`,
  }));
  return rows;
};

export const createUsersManagementRows = (array: ClientResponse[]) => {
  const rows = array?.map((item) => ({
    id: item.id,
    userFullName: `${item?.firstName} ${item?.lastName}`,
    email: item?.email,
    phoneNumber: item?.phoneNumber,
    status: item.userStatus,
    userCategory: item.userCategory,
    profileImageUrl: item.profileImageUrl,
    unreadCount: item.unreadCount,
  }));
  return rows;
};

export const createLeadsRows = (array: Lead[]) => {
  const rows = array?.map((item) => ({
    id: item.id,
    leadFullName: `${item?.firstName} ${item?.lastName}`,
    email: item?.email,
    leadStatus: item?.leadStatus,
  }));
  return rows;
};

export const createInvoicesRows = (array: ClientInvoice[]) => {
  const rows = array?.map((item) => ({
    id: item.id,
    amountDue: `$${Math.round(item.amountDue / 100).toFixed(2)}`,
    customerName: item.customerName,
    invoiceStatus: item.status,
    dueDate: dayjs(item.dueDate).format("DD/MM/YYYY"),
  }));
  return rows;
};

export function addTimezoneToUtcDate(date: string) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tz = dayjs.tz.guess();
  return dayjs.utc(date).tz(tz);
}

// eslint-disable-next-line
export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
